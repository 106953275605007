import { atom, selector, useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { api, ApiShop } from './Api';
import { RecoilKeys } from './RecoilKeys';
import { SpotIncludeBoxes, spotIncludeBoxes } from './Spot';
import axios from 'axios';

export const shopsState = atom<ApiShop[]>({
  key: RecoilKeys.SHOPS_STATE,
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        api
          .fetchShops()
          .then((shops) => {
            const sortedShops = shops.sort((a, b) => a.shopId.localeCompare(b.shopId));
            setSelf(sortedShops);
          })
          .catch((error) => {
            console.error('Failed to fetch shops:', error);
          });
      }
    },
  ],
});

type ShopIncludeSpots = ApiShop & { spots: SpotIncludeBoxes[] };
export const shopIncludeSpots = selector<ShopIncludeSpots>({
  key: RecoilKeys.SHOP_INCLUDE_SPOTS,
  get: ({ get }) => {
    const shop = get(shopSelected);
    const spots = get(spotIncludeBoxes);
    // 選択している店舗の配達場所を取得
    const relatedSpots = spots.filter((spot) => shop.spotId.includes(spot.spotId));
    return { ...shop, spots: relatedSpots };
  },
});

export const shopSelectedIdState = atom<string>({
  key: RecoilKeys.SHOP_SELECTED_ID_STATE,
  default: '0',
  effects: [
    ({ trigger, setSelf, onSet }) => {
      // localStorageに値を保持する
      if (trigger === 'get') {
        setSelf(localStorage.getItem('shopSelectedId') ?? '0');
      }

      onSet((newValue) => {
        localStorage.setItem('shopSelectedId', newValue);
      });
    },
  ],
});

export const shopSelected = selector<ApiShop>({
  key: RecoilKeys.SHOP_SELECTED,
  get: ({ get }) => {
    const shops = get(shopsState);
    const shopSelectedId = get(shopSelectedIdState);
    const shopSelected = shops.find((shop) => shop.shopId === shopSelectedId);
    // 選択しているshopがなければ、1つ目を返却
    if (!shopSelected) {
      return shops[0];
    }

    return shopSelected;
  },
});

const useUpsertShop = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (shop: ApiShop) => {
        const newShop = await api.UpsertShop(shop);

        set(shopsState, (currentShops) => {
          let isUpdated = false;

          const updateShops = currentShops.map((currentShop) => {
            if (currentShop.shopId === shop.shopId) {
              isUpdated = true;
              return shop;
            }
            return currentShop;
          });
          // 作成された場合はitemを追加
          if (isUpdated === false) {
            updateShops.push(newShop);
          }

          return updateShops;
        });
      },
    []
  );

const useAddImage = () =>
  useRecoilCallback(({ set }) => async (shopId: string, images: string[], file: File) => {
    const ext = file.name.split('.').pop();
    if (!ext) {
      console.error('not exist extension');
      return;
    }
    const contentType = file.type;

    const message = await api.AddShopImage(shopId, images, contentType, ext);

    // ファイルのアップロード
    const postInfo = message.presignedPost;
    const formData = new FormData();
    Object.keys(postInfo.fields).forEach((key) => {
      formData.append(key, postInfo.fields[key]);
    });
    formData.append('file', file);

    await axios.post(postInfo.url, formData);

    // アップロード後、ステートの更新を行う
    set(shopsState, (currentShops) => {
      return currentShops.map((shop) => {
        if (shop.shopId === shopId) {
          return { ...shop, image: message.image };
        }
        return shop;
      });
    });
  });

const useChangeImage = () =>
  useRecoilCallback(
    ({ set }) =>
      async (shopId: string, changeTargetUrl: string, images: string[], file: File) => {
        const ext = file.name.split('.').pop();
        if (!ext) {
          console.error('not exist extension');
          return;
        }
        const contentType = file.type;
        const message = await api.ChangeShopImage(
          shopId,
          changeTargetUrl,
          images,
          contentType,
          ext
        );

        // ファイルのアップロード
        const postInfo = message.presignedPost;
        const formData = new FormData();
        Object.keys(postInfo.fields).forEach((key) => {
          formData.append(key, postInfo.fields[key]);
        });
        formData.append('file', file);

        await axios.post(postInfo.url, formData);

        // アップロード後、ステートの更新を行う
        set(shopsState, (currentShops) => {
          return currentShops.map((shop) => {
            if (shop.shopId === shopId) {
              return { ...shop, image: message.image };
            }
            return shop;
          });
        });
      }
  );

const useDeleteImage = () =>
  useRecoilCallback(
    ({ set }) =>
      async (shopId: string, deleteImageUrl: string, images: string[]) => {
        const image = await api.DeleteShopImage(shopId, deleteImageUrl, images);

        // 削除後、ステートの更新を行う
        set(shopsState, (currentShops) => {
          return currentShops.map((shop) => {
            if (shop.shopId === shopId) {
              return { ...shop, image: image };
            }
            return shop;
          });
        });
      }
  );

const useSortImage = () =>
  useRecoilCallback(
    ({ set }) =>
      async (shopId: string, images: string[], sourceIndex: number, destinationIndex: number) => {
        const cloneImages = Array.from(images);
        const [removedImage] = cloneImages.splice(sourceIndex, 1);
        cloneImages.splice(destinationIndex, 0, removedImage);

        await api.UpdateShopImage(shopId, cloneImages);

        set(shopsState, (currentShops) => {
          return currentShops.map((shop) => {
            if (shop.shopId === shopId) {
              return { ...shop, image: cloneImages };
            }
            return shop;
          });
        });
      }
  );

export const shopSelectedBaseIdState = atom<string>({
  key: RecoilKeys.SHOP_SELECTED_BASEID_STATE,
  default: '',
});

export const dataShop = {
  useShopsValue: () => useRecoilValue(shopsState),
  useShopSelectedId: () => useRecoilState(shopSelectedIdState),
  useShopSelected: () => useRecoilValue(shopSelected),
  useShopIncludeSpots: () => useRecoilValue(shopIncludeSpots),
  useShopSelectedBaseId: () => useRecoilState(shopSelectedBaseIdState),
  useUpsertShop,
  useAddImage,
  useChangeImage,
  useDeleteImage,
  useSortImage,
};
