import moment, { Moment } from 'moment';
import { atomFamily, selector, useRecoilCallback, useRecoilValue } from 'recoil';
import { CONST } from '@common/global';
import { api, ApiDroneSlot } from './Api';
import { RecoilKeys } from './RecoilKeys';
import { shopSelectedBaseIdState } from './Shop';
import { cognitoUserState } from './CognitoUser';

const droneSlotsState = atomFamily<ApiDroneSlot[], string>({
  key: RecoilKeys.DRONESLOTS_STATE,
  default: [],
  effects: (baseId: string) => [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          if (baseId) {
            const droneSlots = await api.FetchDroneSlots(baseId);
            setSelf(droneSlots);
          }
        };
        initialize();
      }
    },
  ],
});

export type DroneSlot = {
  baseId: string;
  deliveryTimeBegin: number;
  beginTime: string;
  endTime: string;
  free: number;
  TTL?: number;
  removedBy?: string;
};

type DroneSlotsByDate = {
  [date: string]: DroneSlot[];
};

const droneSlotsByDate = selector<DroneSlotsByDate>({
  key: RecoilKeys.DRONESLOTS_BY_DATE,
  get: ({ get }) => {
    const shopSelectedBaseId = get(shopSelectedBaseIdState);
    const droneSlots = get(droneSlotsState(shopSelectedBaseId));

    return droneSlots.reduce((result: DroneSlotsByDate, currentValue) => {
      const beginMoment = moment.unix(currentValue.deliveryTimeBegin).utcOffset(+9);
      const endMoment = beginMoment
        .clone()
        .add(CONST.NEXT_DELIVERY_SLOT_INTERVAL_MINUTES, 'minute');
      const yyyymmddString = beginMoment.format('YYYY-MM-DD');

      if (!result[yyyymmddString]) {
        result[yyyymmddString] = [];
      }

      result[yyyymmddString].push({
        baseId: shopSelectedBaseId,
        deliveryTimeBegin: currentValue.deliveryTimeBegin,
        beginTime: beginMoment.format('HH:mm'),
        endTime: endMoment.format('HH:mm'),
        free: currentValue.free,
        TTL: currentValue.TTL,
        removedBy: currentValue.removedBy,
      });

      return result;
    }, {});
  },
});

const useUpsertDroneSlots = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (baseId: string, deliveryTimeBegin: number, difference: number) => {
        await api.UpsertDroneSlots(baseId, deliveryTimeBegin, difference);
        const updatedDroneSlots = await api.FetchDroneSlots(baseId);
        set(droneSlotsState(baseId), updatedDroneSlots);
      },
    []
  );

const useBatchUpsertDroneSlots = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (droneSlots: DroneSlot[]) => {
        const shopSelectedBaseId = await snapshot.getPromise(shopSelectedBaseIdState);
        await api.BatchUpsertDroneSlots(droneSlots);
        set(droneSlotsState(shopSelectedBaseId), (currentDroneSlots) => {
          return [...currentDroneSlots, ...droneSlots];
        });
      },
    []
  );

const useCreateDroneSlots = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (moment: Moment) => {
        const shopSelectedBaseId = await snapshot.getPromise(shopSelectedBaseIdState);
        // 配送枠テンプレートを作成
        // 開始時間
        const START_HOUR = 0;
        // 終了時間
        const END_HOUR = 24;
        // 何分ごとに作成するか
        const EVERY_MINUTES = CONST.NEXT_DELIVERY_SLOT_INTERVAL_MINUTES;
        // 空きの初期値
        const DEFAULT_FREE = 2;

        const droneSlotsTemplates: ApiDroneSlot[] = [];

        // いくつ配送枠を作成するか
        const droneSlotsCount = (END_HOUR * 60 - START_HOUR * 60) / EVERY_MINUTES;

        for (let index = 0; index < droneSlotsCount; index++) {
          const momentClone = moment.clone();
          droneSlotsTemplates.push({
            baseId: shopSelectedBaseId,
            deliveryTimeBegin: momentClone
              .add(START_HOUR, 'hour')
              .add(index * EVERY_MINUTES, 'minute')
              .unix(),
            free: DEFAULT_FREE,
          });
        }

        await api.BatchUpsertDroneSlots(droneSlotsTemplates);
        set(droneSlotsState(shopSelectedBaseId), (currentDroneSlots) => {
          return [...currentDroneSlots, ...droneSlotsTemplates];
        });
      },
    []
  );

const useBatchDeleteDroneSlots = () =>
  useRecoilCallback(({ set, snapshot }) => async (droneSlots: { deliveryTimeBegin: number }[]) => {
    const shopSelectedBaseId = await snapshot.getPromise(shopSelectedBaseIdState);
    const { userName } = await snapshot.getPromise(cognitoUserState);
    await api.BatchDeleteDroneSlots(shopSelectedBaseId, droneSlots);
    set(droneSlotsState(shopSelectedBaseId), (currentDroneSlots) => {
      const deliveryTimeBegins = droneSlots.map((roneSlot) => roneSlot.deliveryTimeBegin);
      return currentDroneSlots.map((roneSlot) => {
        if (deliveryTimeBegins.includes(roneSlot.deliveryTimeBegin)) {
          return { ...roneSlot, TTL: moment().unix(), removedBy: userName };
        }

        return roneSlot;
      });
    });
  });

const useDroneSlotsRefresh = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (baseId: string) => {
        const updateDroneSlot = await api.FetchDroneSlots(baseId);
        set(droneSlotsState(baseId), updateDroneSlot);
      },
    []
  );

export const dataDroneSlots = {
  useDroneSlots: (baseId: string) => useRecoilValue(droneSlotsState(baseId)),
  useDroneSlotsByDate: () => useRecoilValue(droneSlotsByDate),
  useUpsertDroneSlots,
  useBatchUpsertDroneSlots,
  useBatchDeleteDroneSlots,
  useCreateDroneSlots,
  useDroneSlotsRefresh,
};
