import axios from 'axios';
import { atom, selectorFamily, useRecoilCallback, useRecoilValue } from 'recoil';
import { api } from './Api';
import { RecoilKeys } from './RecoilKeys';

export type Maker = {
  makerId: string;
  makerName: string;
  image: string[];
  businessHours?: string;
  holiday?: string;
  postalCode?: string;
  address?: string;
  phone?: string;
  url?: string;
  latitude?: string;
  longitude?: string;
  description?: string;
};

const emptyMaker: Maker = {
  makerId: '',
  makerName: '----------',
  image: [],
  businessHours: '',
  holiday: '',
  postalCode: '',
  address: '',
  phone: '',
  url: '',
  latitude: '',
  longitude: '',
  description: '',
};

const makersState = atom<Maker[]>({
  key: RecoilKeys.MAKER_STATE,
  default: [],
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          const makers = await api.FetchMakers();
          setSelf(
            makers.map((maker) => {
              if (maker.image) {
                return maker;
              }
              return { ...maker, image: [] };
            })
          );
        };
        initialize();
      }
    },
  ],
});

const UseMakersStateAddEmpty = (): Maker[] => {
  const makers: Maker[] = [emptyMaker];
  return makers.concat(useRecoilValue(makersState));
};

export const shopItemMaker = selectorFamily<Maker | undefined, string>({
  key: RecoilKeys.SHOP_ITEM_MAKER,
  get:
    (makerId: string) =>
    ({ get }) => {
      return get(makersState).find((maker) => maker.makerId === makerId);
    },
});

const useUpsertMaker = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (maker: Maker) => {
        const newMaker = await api.UpsertMaker(maker);

        set(makersState, (currentMakers) => {
          let isUpdated = false;

          const updateMakers = currentMakers.map((currentMaker) => {
            if (currentMaker.makerId === maker.makerId) {
              isUpdated = true;
              return maker;
            }
            return currentMaker;
          });
          // 作成された場合はitemを追加
          if (isUpdated === false) {
            updateMakers.push(newMaker);
          }

          return updateMakers;
        });
      },
    []
  );

const useAddImage = () =>
  useRecoilCallback(({ set }) => async (makerId: string, images: string[], file: File) => {
    const ext = file.name.split('.').pop();
    if (!ext) {
      console.error('not exist extension');
      return;
    }
    const contentType = file.type;

    const message = await api.AddMakerImage(makerId, images, contentType, ext);

    // ファイルのアップロード
    const postInfo = message.presignedPost;
    const formData = new FormData();
    Object.keys(postInfo.fields).forEach((key) => {
      formData.append(key, postInfo.fields[key]);
    });
    formData.append('file', file);

    await axios.post(postInfo.url, formData);

    // アップロード後、ステートの更新を行う
    set(makersState, (currentMakers) => {
      return currentMakers.map((maker) => {
        if (maker.makerId === makerId) {
          return { ...maker, image: message.image };
        }
        return maker;
      });
    });
  });

const useChangeImage = () =>
  useRecoilCallback(
    ({ set }) =>
      async (makerId: string, changeTargetUrl: string, images: string[], file: File) => {
        const ext = file.name.split('.').pop();
        if (!ext) {
          console.error('not exist extension');
          return;
        }
        const contentType = file.type;
        const message = await api.ChangeMakerImage(
          makerId,
          changeTargetUrl,
          images,
          contentType,
          ext
        );

        // ファイルのアップロード
        const postInfo = message.presignedPost;
        const formData = new FormData();
        Object.keys(postInfo.fields).forEach((key) => {
          formData.append(key, postInfo.fields[key]);
        });
        formData.append('file', file);

        await axios.post(postInfo.url, formData);

        // アップロード後、ステートの更新を行う
        set(makersState, (currentMaker) => {
          return currentMaker.map((maker) => {
            if (maker.makerId === makerId) {
              return { ...maker, image: message.image };
            }
            return maker;
          });
        });
      }
  );

const useDeleteImage = () =>
  useRecoilCallback(
    ({ set }) =>
      async (makerId: string, deleteImageUrl: string, images: string[]) => {
        const image = await api.DeleteMakerImage(makerId, deleteImageUrl, images);

        // 削除後、ステートの更新を行う
        set(makersState, (currentMakers) => {
          return currentMakers.map((maker) => {
            if (maker.makerId === makerId) {
              return { ...maker, image: image };
            }
            return maker;
          });
        });
      }
  );

const useSortImage = () =>
  useRecoilCallback(
    ({ set }) =>
      async (makerId: string, images: string[], sourceIndex: number, destinationIndex: number) => {
        const cloneImages = Array.from(images);
        const [removedImage] = cloneImages.splice(sourceIndex, 1);
        cloneImages.splice(destinationIndex, 0, removedImage);

        await api.UpdateMakerImage(makerId, cloneImages);

        set(makersState, (currentMakers) => {
          return currentMakers.map((maker) => {
            if (maker.makerId === makerId) {
              return { ...maker, image: cloneImages };
            }
            return maker;
          });
        });
      }
  );

export const dataMaker = {
  useMaker: () => useRecoilValue(makersState),
  UseMakersStateAddEmpty,
  useUpsertMaker,
  useAddImage,
  useChangeImage,
  useDeleteImage,
  useSortImage,
  useShopItemMaker: (makerId: string) => useRecoilValue(shopItemMaker(makerId)),
};
