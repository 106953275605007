import { atomFamily, useRecoilCallback } from 'recoil';
import { api } from './Api';
import { RecoilKeys } from './RecoilKeys';
import { shopSelectedIdState } from './Shop';

type Offer = {
  itemId: string;
};

export const offersState = atomFamily<Offer[], string>({
  key: RecoilKeys.OFFER_STATE,
  default: [],
  effects: (shopId: string) => [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          setSelf(await api.FetchOffers(shopId));
        };
        initialize();
      }
    },
  ],
});

const useCreateOffer = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (itemId: string) => {
        const selectedShopId = await snapshot.getPromise(shopSelectedIdState);
        const offers = await snapshot.getPromise(offersState(selectedShopId));
        await api.CreateOffer(selectedShopId, itemId);
        set(offersState(selectedShopId), [...offers, { itemId: itemId }]);
      },
    []
  );

const useDeleteOffer = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (itemId: string) => {
        const selectedShopId = await snapshot.getPromise(shopSelectedIdState);
        const offers = await snapshot.getPromise(offersState(selectedShopId));
        await api.DeleteOffer(selectedShopId, itemId);
        const filterdOffers = offers.filter((offer) => offer.itemId !== itemId);
        set(offersState(selectedShopId), filterdOffers);
      },
    []
  );

export const dataOffer = {
  useCreateOffer,
  useDeleteOffer,
};
