import { dataStaff } from '../data/Staff';

export const ManagementStaffs = () => {
  const staffs = dataStaff.useStaffsState();

  return (
    <div className="pt-8 flex flex-col items-center gap-2">
      <div className="mb-2">登録されているスタッフが確認できます。</div>
      <div className="grid grid-cols-2 gap-2">
        {staffs.map((staff) => (
          <div key={staff.staffId} className="p-2 border">
            <div className="flex flex-col gap-2">
              <p>ユーザID: {staff.staffId}</p>
              <p>表示名: {staff.staffName}</p>
              <p>携帯電話番号: {staff.phoneNumber}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
