import { atom, selector, selectorFamily, useRecoilCallback, useRecoilValue } from 'recoil';
import { api } from './Api';
import { RecoilKeys } from './RecoilKeys';
import { shopSelectedIdState } from './Shop';
import moment from 'moment';
import { cognitoUserState } from './CognitoUser';

export type Category = {
  categoryId: string;
  categoryName: string;
  priority: number;
  visible: boolean;
  TTL?: number;
  removedBy?: string;
};

const categoriesState = atom<Category[]>({
  key: RecoilKeys.CATEGORY_STATE,
  default: [],
  effects: [
    ({ getPromise, setSelf, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          const selectedShopId = await getPromise(shopSelectedIdState);
          const categories = await api.FetchCategories(selectedShopId);
          setSelf(categories.sort((a, b) => b.priority - a.priority));
        };
        initialize();
      }
    },
  ],
});

const otherShopCategoriesState = selectorFamily<Category[], string>({
  key: RecoilKeys.OTHER_SHOP_CATEGORIES_STATE,
  get: (shopId: string) => async () => {
    const categories = await api.FetchCategories(shopId);
    return categories.sort((a, b) => b.priority - a.priority);
  },
});

const categoriesVisible = selector<Category[]>({
  key: RecoilKeys.CATEGORY_VISIBLE,
  get: ({ get }) => {
    const categories = get(categoriesState);
    console.info(categories);
    return categories.filter((category) => category.visible);
  },
});

const useCategoriesVisibleAddEmpty = (): Category[] => {
  return [{ categoryId: '', categoryName: '----------', priority: 0, visible: true }].concat(
    useRecoilValue(categoriesState)
  );
};

const useCategoryUpsert = () =>
  useRecoilCallback(({ snapshot, set }) => async (category: Category) => {
    const selectedShopId = await snapshot.getPromise(shopSelectedIdState);
    const newCategory = await api.UpsertCategory(selectedShopId, category);
    set(categoriesState, (currentCategories) => {
      let isUpdated = false;
      const updatedCategories = currentCategories.map((currentCategory) => {
        if (currentCategory.categoryId === category.categoryId) {
          isUpdated = true;
          return category;
        }
        return currentCategory;
      });
      // 作成された場合はitemを追加
      if (isUpdated === false) {
        updatedCategories.push(newCategory);
      }

      return updatedCategories.sort((a, b) => b.priority - a.priority);
    });
  });

const useCategoryDelete = () =>
  useRecoilCallback(({ snapshot, set }) => async (categoryId: string) => {
    const selectedShopId = await snapshot.getPromise(shopSelectedIdState);
    const { userName } = await snapshot.getPromise(cognitoUserState);

    await api.DeleteCategory(selectedShopId, categoryId);
    set(categoriesState, (currentCategories) => {
      return currentCategories.map((currentCategory) => {
        if (currentCategory.categoryId === categoryId) {
          return { ...currentCategory, TTL: moment().unix(), removedBy: userName };
        }
        return currentCategory;
      });
    });
  });

const useCategoryImport = () =>
  useRecoilCallback(({ snapshot, set }) => async ({ fromShopId }: { fromShopId: string }) => {
    const selectedShopId = await snapshot.getPromise(shopSelectedIdState);

    await api.ImportCategory({ fromShopId, toShopId: selectedShopId });
    set(categoriesState, await api.FetchCategories(selectedShopId));
  });

export const dataCategory = {
  useCategory: () => useRecoilValue(categoriesState),
  useOtherShopCategory: (shopId: string) => useRecoilValue(otherShopCategoriesState(shopId)),
  useCategoryVisible: () => useRecoilValue(categoriesVisible),
  useCategoriesVisibleAddEmpty,
  useCategoryUpsert,
  useCategoryDelete,
  useCategoryImport,
};
