import { atom, selector, useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { api, ApiBoxState, ApiSpot } from './Api';
import axios from 'axios';
import { shopSelected, shopsState } from './Shop';
import { boxState } from './Box';

const spotsState = atom<ApiSpot[]>({
  key: RecoilKeys.SPOTS_STATE,
  effects: [
    ({ setSelf, trigger, onSet }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          const spots = await api.fetchSpots();
          setSelf(
            spots
              .map((spot) => {
                if (spot.image) {
                  return spot;
                }
                return { ...spot, image: [] };
              })
              .sort((a, b) => a.spotId.localeCompare(b.spotId))
          );
        };
        initialize();
      }
    },
  ],
});

export const spotSelectedIdState = atom<string>({
  key: RecoilKeys.SPOT_SELECTED_ID_STATE,
  default: '',
});

export type SpotIncludeBoxes = ApiSpot & {
  boxes: ApiBoxState[];
};
export const spotIncludeBoxes = selector<SpotIncludeBoxes[]>({
  key: RecoilKeys.SPOTS_INCLUDE_BOXES,
  get: ({ get }) => {
    const spots = get(spotsState);
    const boxes = get(boxState);
    return spots.map((spot) => {
      const relatedBoxes = boxes
        .filter((box) => box.spotId === spot.spotId)
        .sort((a, b) => Number(a.boxId) - Number(b.boxId));
      console.info(relatedBoxes);
      return { ...spot, boxes: relatedBoxes };
    });
  },
});

const useUpsertSpot = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (spot: ApiSpot) => {
        const shopSelect = await snapshot.getPromise(shopSelected);
        const newSpot = await api.UpsertSpot(shopSelect.shopId, spot);

        set(shopsState, (currentShops) => {
          return currentShops.map((shop) => {
            if (shop.shopId === shopSelect.shopId) {
              return { ...shop, spotId: [...shop.spotId, newSpot.spotId] };
            }
            return shop;
          });
        });

        set(spotsState, (currentSpots) => {
          let isUpdated = false;

          const updateSpots = currentSpots.map((currentSpot) => {
            if (currentSpot.spotId === spot.spotId) {
              isUpdated = true;
              return spot;
            }
            return currentSpot;
          });
          // 作成された場合はitemを追加
          if (isUpdated === false) {
            updateSpots.push(newSpot);
          }

          return updateSpots;
        });
      },
    []
  );

const useDeleteSpot = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (spotId: string) => {
        const spots = await snapshot.getPromise(spotsState);
        const selectedShop = await snapshot.getPromise(shopSelected);

        await api.DeleteSpot(selectedShop.shopId, spotId);
        const filteredSpots = spots.filter((spot) => spot.spotId !== spotId);
        set(spotsState, filteredSpots);
        set(shopsState, (shops) => {
          return shops.map((shop) => {
            return { ...shop, spotId: shop.spotId.filter((id) => id !== spotId) };
          });
        });
      },
    []
  );

const useAddImage = () =>
  useRecoilCallback(({ set }) => async (spotId: string, images: string[], file: File) => {
    const ext = file.name.split('.').pop();
    if (!ext) {
      console.error('not exist extension');
      return;
    }
    const contentType = file.type;

    const message = await api.AddSpotImage(spotId, images, contentType, ext);

    // ファイルのアップロード
    const postInfo = message.presignedPost;
    const formData = new FormData();
    Object.keys(postInfo.fields).forEach((key) => {
      formData.append(key, postInfo.fields[key]);
    });
    formData.append('file', file);

    await axios.post(postInfo.url, formData);

    // アップロード後、ステートの更新を行う
    set(spotsState, (currentSpots) => {
      return currentSpots.map((spot) => {
        if (spot.spotId === spotId) {
          return { ...spot, image: message.image };
        }
        return spot;
      });
    });
  });

const useChangeImage = () =>
  useRecoilCallback(
    ({ set }) =>
      async (spotId: string, changeTargetUrl: string, images: string[], file: File) => {
        const ext = file.name.split('.').pop();
        if (!ext) {
          console.error('not exist extension');
          return;
        }
        const contentType = file.type;
        const message = await api.ChangeSpotImage(
          spotId,
          changeTargetUrl,
          images,
          contentType,
          ext
        );

        // ファイルのアップロード
        const postInfo = message.presignedPost;
        const formData = new FormData();
        Object.keys(postInfo.fields).forEach((key) => {
          formData.append(key, postInfo.fields[key]);
        });
        formData.append('file', file);

        await axios.post(postInfo.url, formData);

        // アップロード後、ステートの更新を行う
        set(spotsState, (currentSpot) => {
          return currentSpot.map((spot) => {
            if (spot.spotId === spotId) {
              return { ...spot, image: message.image };
            }
            return spot;
          });
        });
      }
  );

const useDeleteImage = () =>
  useRecoilCallback(
    ({ set }) =>
      async (spotId: string, deleteImageUrl: string, images: string[]) => {
        const image = await api.DeleteSpotImage(spotId, deleteImageUrl, images);

        // 削除後、ステートの更新を行う
        set(spotsState, (currentSpots) => {
          return currentSpots.map((spot) => {
            if (spot.spotId === spotId) {
              return { ...spot, image: image };
            }
            return spot;
          });
        });
      }
  );

const useSortImage = () =>
  useRecoilCallback(
    ({ set }) =>
      async (spotId: string, images: string[], sourceIndex: number, destinationIndex: number) => {
        const cloneImages = Array.from(images);
        const [removedImage] = cloneImages.splice(sourceIndex, 1);
        cloneImages.splice(destinationIndex, 0, removedImage);

        await api.UpdateSpotImage(spotId, cloneImages);

        set(spotsState, (currentSpots) => {
          return currentSpots.map((spot) => {
            if (spot.spotId === spotId) {
              return { ...spot, image: cloneImages };
            }
            return spot;
          });
        });
      }
  );

export const dataSpot = {
  useSpots: () => useRecoilValue(spotsState),
  useSpotSelectedId: () => useRecoilState(spotSelectedIdState),
  useUpsertSpot,
  useDeleteSpot,
  useAddImage,
  useChangeImage,
  useDeleteImage,
  useSortImage,
};
