import axios from 'axios';
import { useEffect } from 'react';
import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { api, ApiItem, ApiStock } from './Api';
import { shopItemMaker } from './Maker';
import { offersState } from './Offer';
import { RecoilKeys } from './RecoilKeys';
import { shopSelectedIdState } from './Shop';
import { stocksState } from './Stock';
import moment from 'moment';
import { cognitoUserState } from './CognitoUser';
import { CONST } from '@common/global';

export type Item = {
  itemId: string;
  allergen: string;
  bestByDate: string;
  brand: string;
  categoryId: string;
  copy: string;
  description: string;
  expiration: string;
  image: string[];
  makerId: string;
  name: string;
  price: number;
  priority: number;
  size: number;
  visible: boolean;
  weight: number;
  isOffer: boolean;
  stock?: ApiStock;
  limitedStock?: number;
  TTL?: number;
  removedBy?: string;
};

const convertItemToApiItem = (item: Item): ApiItem => {
  const { stock, isOffer, ...apiItem } = item;
  return apiItem as ApiItem;
};

// キャッシュ用
const itemsState = atom<Item[]>({
  key: RecoilKeys.ITEM_STATE,
  default: [],
});

// アイテム管理ステート
const managementItems = selector<Item[]>({
  key: RecoilKeys.MANAGEMENT_ITEMS,
  get: async ({ get }) => {
    let items: ApiItem[] | Item[] = get(itemsState);
    // キャッシュに値がなければAPIから取得
    if (get(itemsState).length === 0) {
      items = await api.FetchItems();
    }

    const selectedShopId = get(shopSelectedIdState);
    const offeredItemIdList = get(offersState(selectedShopId)).flatMap(
      (offerState) => offerState.itemId
    );
    const stocks = get(stocksState(selectedShopId));

    // 各種情報の付与
    return items.map((item) => {
      let isOffer = false;
      // おすすめされているか
      if (offeredItemIdList.includes(item.itemId)) {
        isOffer = true;
      }
      // 在庫の紐付け
      const stock = stocks.find((stock) => stock.itemId === item.itemId);

      return {
        ...item,
        isOffer: isOffer,
        image: item.image ?? [],
        priority: item.priority ? Number(item.priority) : 0,
        price: item.price ? Number(item.price) : 0,
        size: item.size ? Number(item.size) : CONST.DEFAULT_ITEM_SIZE,
        weight: item.weight ? Number(item.weight) : CONST.DEFAULT_ITEM_WEIGHT,
        stock: stock,
      };
    });
  },
});

// 特定商品の情報
const shopItemMasterSelector = selectorFamily<Item | undefined, string>({
  key: RecoilKeys.MANAGEMENT_ITEM,
  get:
    (itemId) =>
    ({ get }) => {
      const item = get(managementItems).find((item) => item.itemId === itemId);
      const makerName = item?.makerId ? get(shopItemMaker(item.makerId))?.makerName : undefined;
      return item ? { ...item, makerName } : undefined;
    },
});

export type VisibleCondition = 'all' | 'visible' | 'invisible';

type FilterCondition = {
  categoryId: string;
  visibleCondition: VisibleCondition;
  stockFrom?: number;
  stockTo?: number;
  keyword: string;
};

const itemsFiltered = selectorFamily({
  key: RecoilKeys.ITEMS_FILTERED,
  get:
    ({ categoryId, stockFrom, stockTo, keyword, visibleCondition }: FilterCondition) =>
    ({ get }) => {
      let filteredItems: Item[] = get(managementItems);

      // カテゴリ検索
      if (categoryId !== 'all') {
        filteredItems = filteredItems.filter(
          (managementItem) => managementItem.categoryId === categoryId
        );
      }

      // 表示状態による検索
      if (visibleCondition !== 'all') {
        if (visibleCondition === 'visible') {
          filteredItems = filteredItems.filter((managementItem) => managementItem.visible === true);
        }
        if (visibleCondition === 'invisible') {
          filteredItems = filteredItems.filter(
            (managementItem) => managementItem.visible === false
          );
        }
      }

      // 在庫下限指定
      if (stockFrom) {
        // 複数検索の場合、フィルタ対象を変える
        filteredItems = filteredItems.filter(
          (managementItem) => Number(managementItem.stock?.freeStockCount ?? 0) >= stockFrom
        );
      }

      // 在庫上限指定
      if (stockTo) {
        // 複数検索の場合、フィルタ対象を変える
        filteredItems = filteredItems.filter(
          (managementItem) => Number(managementItem.stock?.freeStockCount ?? 0) <= stockTo
        );
      }

      // キーワード検索
      if (keyword !== '') {
        // 複数検索の場合、フィルタ対象を変える
        filteredItems = filteredItems.filter((managementItem) => {
          for (const value of Object.values(managementItem)) {
            // 全てのプロパティが検索対象となる
            if (String(value).indexOf(keyword) !== -1) {
              return true;
            }
          }
          return false;
        });
      }

      return filteredItems;
    },
});

const useUpdateItem = () =>
  useRecoilCallback(
    ({ set }) =>
      async (item: Item) => {
        await api.UpdateItem(convertItemToApiItem(item));
        set(itemsState, (currentItems) => {
          return currentItems.map((currentItem) => {
            // itemIDが同じであれば更新
            if (currentItem.itemId === item.itemId) {
              return item;
            }
            return currentItem;
          });
        });
      },
    []
  );

const useCreateItem = () =>
  useRecoilCallback(({ set }) => async (item: Item) => {
    await api.CreateItem(convertItemToApiItem(item));
    set(itemsState, (currentItems) => {
      return currentItems.concat([item]);
    });
  });

const useDeleteItem = () =>
  useRecoilCallback(({ snapshot, set }) => async (itemId: string) => {
    const { userName } = await snapshot.getPromise(cognitoUserState);
    await api.DeleteItem(itemId);
    set(itemsState, (currentItems) => {
      return currentItems.map((currentItem) => {
        if (currentItem.itemId === itemId) {
          return { ...currentItem, TTL: moment().unix(), removedBy: userName };
        }
        return currentItem;
      });
    });
  });

const useAddImage = () =>
  useRecoilCallback(({ set }) => async (itemId: string, images: string[], file: File) => {
    const ext = file.name.split('.').pop();
    if (!ext) {
      console.error('not exist extension');
      return;
    }
    const contentType = file.type;

    const message = await api.AddItemImage(itemId, images, contentType, ext);

    // ファイルのアップロード
    const postInfo = message.presignedPost;
    const formData = new FormData();
    Object.keys(postInfo.fields).forEach((key) => {
      formData.append(key, postInfo.fields[key]);
    });
    formData.append('file', file);

    await axios.post(postInfo.url, formData);

    // アップロード後、ステートの更新を行う
    set(itemsState, (currentItems) => {
      return currentItems.map((item) => {
        if (item.itemId === itemId) {
          return { ...item, image: message.image };
        }
        return item;
      });
    });
  });

const useChangeImage = () =>
  useRecoilCallback(
    ({ set }) =>
      async (itemId: string, changeTargetUrl: string, images: string[], file: File) => {
        const ext = file.name.split('.').pop();
        if (!ext) {
          console.error('not exist extension');
          return;
        }
        const contentType = file.type;
        const message = await api.ChangeItemImage(
          itemId,
          changeTargetUrl,
          images,
          contentType,
          ext
        );

        // ファイルのアップロード
        const postInfo = message.presignedPost;
        const formData = new FormData();
        Object.keys(postInfo.fields).forEach((key) => {
          formData.append(key, postInfo.fields[key]);
        });
        formData.append('file', file);

        await axios.post(postInfo.url, formData);

        // アップロード後、ステートの更新を行う
        set(itemsState, (currentItems) => {
          return currentItems.map((item) => {
            if (item.itemId === itemId) {
              return { ...item, image: message.image };
            }
            return item;
          });
        });
      }
  );

const useDeleteImage = () =>
  useRecoilCallback(
    ({ set }) =>
      async (itemId: string, deleteImageUrl: string, images: string[]) => {
        const image = await api.DeleteItemImage(itemId, deleteImageUrl, images);

        // 削除後、ステートの更新を行う
        set(itemsState, (currentItems) => {
          return currentItems.map((item) => {
            if (item.itemId === itemId) {
              return { ...item, image: image };
            }
            return item;
          });
        });
      }
  );

const useSortImage = () =>
  useRecoilCallback(
    ({ set }) =>
      async (itemId: string, images: string[], sourceIndex: number, destinationIndex: number) => {
        const cloneImages = Array.from(images);
        const [removedImage] = cloneImages.splice(sourceIndex, 1);
        cloneImages.splice(destinationIndex, 0, removedImage);

        await api.UpdateItemImage(itemId, cloneImages);

        set(itemsState, (currentItems) => {
          return currentItems.map((item) => {
            if (item.itemId === itemId) {
              return { ...item, image: cloneImages };
            }
            return item;
          });
        });
      }
  );

export const dataItem = {
  useItems: () => {
    const items = useRecoilValue(managementItems);
    // キャッシュをセット
    const setItems = useSetRecoilState(itemsState);
    useEffect(() => {
      setItems(items);
    }, []);

    return items;
  },
  useItemsFiltered: (filterCondition: FilterCondition) =>
    useRecoilValue(itemsFiltered(filterCondition)),
  useCreateItem,
  useUpdateItem,
  useDeleteItem,
  useAddImage,
  useChangeImage,
  useDeleteImage,
  useSortImage,
  useShopItemMaster: (itemId: string) => useRecoilValue(shopItemMasterSelector(itemId)),
};
