import { atom, selector, useRecoilCallback, useRecoilValue } from 'recoil';
import { ApiStaff, api } from './Api';
import { RecoilKeys } from './RecoilKeys';
import { orderItemState } from './Order';
import { cognitoUserState } from './CognitoUser';

export const staffsState = atom<ApiStaff[]>({
  key: RecoilKeys.STAFFS_STATE,
  default: [],
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          setSelf(api.FetchStaffs());
        };
        initialize();
      }
    },
  ],
});

const staffState = selector<ApiStaff | undefined>({
  key: RecoilKeys.STAFF_STATE,
  get: ({ get }) => {
    const user = get(cognitoUserState);
    const staffs = get(staffsState);
    return staffs.find((staff) => staff.staffId === user.userName);
  },
});

const isNotRegistStaff = selector<boolean>({
  key: RecoilKeys.IS_NOT_REGIST_STAFF,
  get: async ({ get }) => {
    const staff = get(staffState);
    return !staff;
  },
});

export type Staff = {
  staffName: string;
  phoneNumber: string;
};

const useUpsertStaff = () =>
  useRecoilCallback(({ set, snapshot }) => async (staff: Staff) => {
    const user = await snapshot.getPromise(cognitoUserState);
    await api.UpsertStaff(staff);
    set(staffsState, (currentStaffs) => {
      const isUpdate = currentStaffs.some((currentStaff) => currentStaff.staffId === user.userName);
      if (isUpdate) {
        return currentStaffs.map((currentStaff) => {
          if (currentStaff.staffId === user.userName) {
            return {
              staffId: user.userName,
              ...staff,
            };
          }
          return currentStaff;
        });
      }

      return [
        ...currentStaffs,
        {
          staffId: user.userName,
          ...staff,
        },
      ];
    });
    // 注文のスタッフ名を更新
    set(orderItemState, (prev) => {
      return prev.map((order) => {
        if (order.shopUserInfo?.userId === user.userName) {
          return {
            ...order,
            shopUserInfo: {
              ...order.shopUserInfo,
              staffName: staff.staffName,
            },
          };
        }
        return order;
      });
    });
  });

export const dataStaff = {
  useStaffState: () => useRecoilValue(staffState),
  useStaffsState: () => useRecoilValue(staffsState),
  useIsNotRegistStaff: () => useRecoilValue(isNotRegistStaff),
  useUpsertStaff,
};
