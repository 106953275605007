// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type DialogOkProps = {
  readonly open: boolean;
  readonly title?: string;
  readonly message?: string;
  readonly callbackOk: () => void;
};
export const DialogOk = ({ open, title, message, callbackOk }: DialogOkProps) => {
  return (
    <Dialog open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {message && (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="contained" onClick={callbackOk} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type DialogOkCancelProps = {
  readonly open: boolean;
  readonly title?: string;
  readonly message?: string;
  readonly callbackOk: () => void;
  readonly callbackCancel: () => void;
  readonly textOk?: string;
  readonly textCancel?: string;
  readonly isAutoFocusCancel?: boolean;
};
export const DialogOkCancel = ({
  open,
  title,
  message,
  callbackOk,
  callbackCancel,
  textOk,
  textCancel,
  isAutoFocusCancel,
}: DialogOkCancelProps) => {
  return (
    <Dialog open={open} onClose={callbackCancel}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {message && (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant="contained" onClick={callbackOk} autoFocus={!isAutoFocusCancel}>
          {textOk || 'OK'}
        </Button>
        <Button variant="outlined" onClick={callbackCancel} autoFocus={isAutoFocusCancel}>
          {textCancel || 'キャンセル'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
