import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_CONSOLELOG !== 'true') {
  console.log = () => {};
} else {
  console.log('development mode.');
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

const configuration = {
  onUpdate: (registration: ServiceWorkerRegistration) => {
    if (registration && registration.waiting) {
      // eslint-disable-next-line no-alert
      window.alert('アプリが更新されました。 ページを再読込します。');
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  },
};

serviceWorkerRegistration.register(configuration);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
