import { atom, selector, useRecoilCallback, useRecoilValue } from 'recoil';
import { api, ApiBoxState } from './Api';
import { RecoilKeys } from './RecoilKeys';
import { CONST } from '@common/global';
import { shopIncludeSpots } from './Shop';
import { cognitoUserState } from './CognitoUser';
import moment from 'moment';

export const boxState = atom<ApiBoxState[]>({
  key: RecoilKeys.BOX_STATE,
  effects: [
    ({ setSelf, onSet, trigger }) => {
      if (trigger === 'get') {
        const initialize = () =>
          api.getBoxState().then((boxStates) => {
            setSelf(boxStates);
          });

        initialize();
        setInterval(initialize, CONST.TIME_LIMITATION_CHECK_INTERVAL);
      }
    },
  ],
});

const boxesInShopState = selector({
  key: RecoilKeys.BOXES_IN_SHOP_STATE,
  get: ({ get }) => {
    const shop = get(shopIncludeSpots);
    const boxes = get(boxState);
    return boxes
      .filter((box) => shop.spotId.includes(box.spotId))
      .map((box) => {
        const spot = shop.spots.find((spot) => spot.spotId === box.spotId);

        return {
          ...box,
          ...spot,
        };
      });
  },
});

const useCreateBox = () =>
  useRecoilCallback(({ set }) => async (spotId: string) => {
    const result = await api.CreateBox(spotId);
    set(boxState, (currentState) => {
      return currentState.concat(result);
    });
  });

const useSetEmpty = () =>
  useRecoilCallback(({ set }) => async (boxId: string) => {
    await api.SetEmpty(boxId);
    set(boxState, (currentState) => {
      const updatedState = currentState.map((box) => {
        if (box.boxId === boxId) {
          return { ...box, state: 'empty' };
        }
        return box;
      });
      return updatedState;
    });
  });

const useDeleteBox = () =>
  useRecoilCallback(({ set, snapshot }) => async (boxId: string) => {
    const { userName } = await snapshot.getPromise(cognitoUserState);

    await api.DeleteBox(boxId);
    set(boxState, (currentState) => {
      return currentState.map((box) => {
        if (box.boxId === boxId) {
          return { ...box, TTL: moment().unix(), removedBy: userName };
        }
        return box;
      });
    });
  });

export const dataBox = {
  useBoxState: () => useRecoilValue(boxState),
  useBoxesInShopState: () => useRecoilValue(boxesInShopState),
  useCreateBox,
  useSetEmpty,
  useDeleteBox,
};
