import { atom, selector, selectorFamily, useRecoilCallback, useRecoilValue } from 'recoil';
import moment from 'moment';
import { RecoilKeys } from './RecoilKeys';
import { api } from './Api';
import { shopSelected } from './Shop';
import { shopSelectedIdState } from './Shop';
import { cognitoUserState } from './CognitoUser';

export type AppMessage = {
  messageId: string;
  message: string;
  TTL?: number;
  removedBy?: string;
};

const AppMessagesState = selector<AppMessage[]>({
  key: RecoilKeys.APP_MESSAGES_STATE,
  get: ({ get }) => {
    const shop = get(shopSelected);
    return api.FetchAppMessages(shop.shopId);
  },
});

const messagesState = atom<AppMessage[]>({
  key: RecoilKeys.APP_MESSAGES_MESSAGES,
  default: [],
  effects: [
    ({ getPromise, setSelf, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          const selectedShopId = await getPromise(shopSelectedIdState);
          const messages = await api.FetchAppMessages(selectedShopId);
          setSelf(messages);
        };
        initialize();
      }
    },
  ],
});

const messageState = selectorFamily<string, string>({
  key: RecoilKeys.APP_MESSAGES_MESSAGE,
  get:
    (messageId) =>
    ({ get }) => {
      const message = get(AppMessagesState).find((item) => item.messageId === messageId);
      return message ? message.message : '';
    },
});

const useAppMessageDelete = () =>
  useRecoilCallback(({ snapshot, set }) => async (messageId: string) => {
    const selectedShopId = await snapshot.getPromise(shopSelectedIdState);
    const { userName } = await snapshot.getPromise(cognitoUserState);

    await api.DeleteAppMessage(selectedShopId, messageId);
    set(messagesState, (currentCategories) => {
      return currentCategories.map((currentMessage) => {
        if (currentMessage.messageId === messageId) {
          return { ...currentMessage, TTL: moment().unix(), removedBy: userName };
        }
        return currentMessage;
      });
    });
  });

const useAppMessageUpsert = () =>
  useRecoilCallback(({ snapshot, set }) => async (appMessage: AppMessage) => {
    const selectedShopId = await snapshot.getPromise(shopSelectedIdState);
    const newAppMessage = await api.UpsertAppMessage(selectedShopId, appMessage);
    console.log('-----appMessage-----');
    console.log(appMessage);
    set(messagesState, (currentMessageState) => {
      let isUpdated = false;
      const updatedAppMessages = currentMessageState.map((currentMessage) => {
        if (currentMessage.messageId === appMessage.messageId) {
          isUpdated = true;
          return appMessage;
        }
        return currentMessage;
      });
      // 作成された場合はitemを追加
      if (isUpdated === false) {
        updatedAppMessages.push(newAppMessage);
      }

      return updatedAppMessages;
    });
  });

// export
export const dataAppMessages = {
  useAppMessages: () => useRecoilValue(messagesState),
  useAppMessage: (messageId: string) => useRecoilValue(messageState(messageId)),
  useAppMessageDelete,
  useAppMessageUpsert,
};
