import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// MUI
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
// MUI Icon
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import FactoryRoundedIcon from '@mui/icons-material/FactoryRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import TramRoundedIcon from '@mui/icons-material/TramRounded';
import SmartphoneRoundedIcon from '@mui/icons-material/SmartphoneRounded';
import InboxRoundedIcon from '@mui/icons-material/InboxRounded';
import InfoIcon from '@mui/icons-material/Info';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import StoreRoundedIcon from '@mui/icons-material/StoreRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import ContactPhoneRoundedIcon from '@mui/icons-material/ContactPhoneRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import DeliveryDiningRoundedIcon from '@mui/icons-material/DeliveryDiningRounded';
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import AccessibilityRoundedIcon from '@mui/icons-material/AccessibilityRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import AppSettingsAltRoundedIcon from '@mui/icons-material/AppSettingsAltRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

import { DialogOkCancel } from '@common/components/Dialog';

//Version
import { MyVersion } from './MyVersion';
import { Collapse } from '@mui/material';
import { dataCognitoUser } from '../data/CognitoUser';

/* eslint-disable complexity */
export function Menu() {
  const navigate = useNavigate();
  const isDeveloper = dataCognitoUser.useIsDeveloper();
  const isAdministrator = dataCognitoUser.useIsAdministrator();

  const [openSignOutDialog, setOpenSignOutDialog] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const [openDelivery, setOpenDelivery] = useState(false);
  const [openShop, setOpenShop] = useState(false);
  const [openCalc, setOpenCalc] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [openStaff, setOpenStaff] = useState(false);
  const [openUserApp, setUserApp] = useState(false);
  const [openAdministrator, setOpenAdministrator] = useState(false);

  const signOut = () => {
    setOpenSignOutDialog(false);
    Auth.signOut(); // ★なぜかサインイン画面に遷移しなくなった
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <List>
        <ListItem>
          <ListItemButton
            onClick={() => {
              setOpenItem(!openItem);
            }}
          >
            <ListItemIcon>
              {openItem ? (
                <ArrowDropUpRoundedIcon fontSize="large" />
              ) : (
                <ArrowDropDownRoundedIcon fontSize="large" />
              )}
            </ListItemIcon>
            <BusinessCenterRoundedIcon fontSize="large" color="primary" className="mr-2" />
            <ListItemText primary="商品" />
          </ListItemButton>
        </ListItem>
        <Collapse in={openItem} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/management/items')}>
                <ListItemIcon>
                  <WarehouseRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="商品管理" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/management/makers')}>
                <ListItemIcon>
                  <FactoryRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="メーカー管理" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/management/categories')}>
                <ListItemIcon>
                  <ListAltRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="カテゴリー管理" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <Divider />

        <ListItem>
          <ListItemButton
            onClick={() => {
              setOpenDelivery(!openDelivery);
            }}
          >
            <ListItemIcon>
              {openDelivery ? (
                <ArrowDropUpRoundedIcon fontSize="large" />
              ) : (
                <ArrowDropDownRoundedIcon fontSize="large" />
              )}
            </ListItemIcon>
            <DeliveryDiningRoundedIcon fontSize="large" color="primary" className="mr-2" />
            <ListItemText primary="配送" />
          </ListItemButton>
        </ListItem>
        <Collapse in={openDelivery} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/management/capacities')}>
                <ListItemIcon>
                  <EventRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="配送枠管理" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/management/drone-slots')}>
                <ListItemIcon>
                  <TramRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="配送ロボット枠管理" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/management/user-capacities')}>
                <ListItemIcon>
                  <SmartphoneRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="ユーザアプリ上の配送枠表示" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/management/spots')}>
                <ListItemIcon>
                  <InboxRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="配送先管理" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <Divider />

        <ListItem>
          <ListItemButton
            onClick={() => {
              setOpenShop(!openShop);
            }}
          >
            <ListItemIcon>
              {openShop ? (
                <ArrowDropUpRoundedIcon fontSize="large" />
              ) : (
                <ArrowDropDownRoundedIcon fontSize="large" />
              )}
            </ListItemIcon>
            <StoreRoundedIcon fontSize="large" color="primary" className="mr-2" />
            <ListItemText primary="店舗" />
          </ListItemButton>
        </ListItem>
        <Collapse in={openShop} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/management/shops')}>
                <ListItemIcon>
                  <AddBusinessRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="店舗情報管理" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <Divider />

        <ListItem>
          <ListItemButton
            onClick={() => {
              setUserApp(!openUserApp);
            }}
          >
            <ListItemIcon>
              {openUserApp ? (
                <ArrowDropUpRoundedIcon fontSize="large" />
              ) : (
                <ArrowDropDownRoundedIcon fontSize="large" />
              )}
            </ListItemIcon>
            <AppSettingsAltRoundedIcon fontSize="large" color="primary" className="mr-2" />
            <ListItemText primary="ユーザアプリ" />
          </ListItemButton>
        </ListItem>
        <Collapse in={openUserApp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/management/appMessages')}>
                <ListItemIcon>
                  <AppSettingsAltRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="ユーザアプリ設定" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <Divider />

        <ListItem>
          <ListItemButton
            onClick={() => {
              setOpenCalc(!openCalc);
            }}
          >
            <ListItemIcon>
              {openCalc ? (
                <ArrowDropUpRoundedIcon fontSize="large" />
              ) : (
                <ArrowDropDownRoundedIcon fontSize="large" />
              )}
            </ListItemIcon>
            <FolderOpenRoundedIcon fontSize="large" color="primary" className="mr-2" />
            <ListItemText primary="集計" />
          </ListItemButton>
        </ListItem>
        <Collapse in={openCalc} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/aggregate')}>
                <ListItemIcon>
                  <CalculateRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="集計出力" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/order/failed')}>
                <ListItemIcon>
                  <ViewListRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="決済エラー注文一覧" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <Divider />

        {isDeveloper && (
          <ListItem>
            <ListItemButton
              onClick={() => {
                setOpenUser(!openUser);
              }}
            >
              <ListItemIcon>
                {openUser ? (
                  <ArrowDropUpRoundedIcon fontSize="large" />
                ) : (
                  <ArrowDropDownRoundedIcon fontSize="large" />
                )}
              </ListItemIcon>
              <SupportAgentRoundedIcon fontSize="large" color="primary" className="mr-2" />
              <ListItemText primary="お客様" />
            </ListItemButton>
          </ListItem>
        )}
        <Collapse in={openUser} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/management/users')}>
                <ListItemIcon>
                  <GroupRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="ユーザ管理" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <Divider />

        <ListItem>
          <ListItemButton
            onClick={() => {
              setOpenStaff(!openStaff);
            }}
          >
            <ListItemIcon>
              {openStaff ? (
                <ArrowDropUpRoundedIcon fontSize="large" />
              ) : (
                <ArrowDropDownRoundedIcon fontSize="large" />
              )}
            </ListItemIcon>
            <AccessibilityRoundedIcon fontSize="large" color="primary" className="mr-2" />
            <ListItemText primary="スタッフ" />
          </ListItemButton>
        </ListItem>
        <Collapse in={openStaff} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/management/staffs')}>
                <ListItemIcon>
                  <PeopleOutlineRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="スタッフ一覧" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <Divider />
        {isAdministrator && (
          <ListItem>
            <ListItemButton
              onClick={() => {
                setOpenAdministrator(!openAdministrator);
              }}
            >
              <ListItemIcon>
                {openAdministrator ? (
                  <ArrowDropUpRoundedIcon fontSize="large" />
                ) : (
                  <ArrowDropDownRoundedIcon fontSize="large" />
                )}
              </ListItemIcon>
              <AdminPanelSettingsRoundedIcon fontSize="large" color="primary" className="mr-2" />
              <ListItemText primary="管理者" />
            </ListItemButton>
          </ListItem>
        )}

        <Collapse in={openAdministrator} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ ml: 12 }}>
              <ListItemButton onClick={() => navigate('/management/AdminMail')}>
                <ListItemIcon>
                  <EmailRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="メール配信" />
                <NavigateNextRoundedIcon sx={{ mr: 2 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <Divider />

        <ListItem>
          <ListItemButton onClick={() => navigate('/staff')}>
            <ListItemIcon>
              <ContactPhoneRoundedIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="スタッフ情報" />
            <NavigateNextRoundedIcon />
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton onClick={() => navigate('/account')}>
            <ListItemIcon>
              <AccountBoxRoundedIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="アカウント情報" />
            <NavigateNextRoundedIcon />
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton onClick={() => setOpenSignOutDialog(true)}>
            <ListItemIcon>
              <LogoutRoundedIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="ログアウト" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <DialogOkCancel
          open={openSignOutDialog}
          title="ログアウトしますか？"
          message="（★開発中）ログアウト後にログイン画面が表示されない場合は、アプリを終了して開きなおしてください。"
          callbackOk={signOut}
          callbackCancel={() => setOpenSignOutDialog(false)}
        />
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <InfoIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary={MyVersion.Version} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
