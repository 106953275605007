import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDownRounded';
import { ChangeEvent, useState, useEffect, useMemo } from 'react';
import { dataCategory } from '../data/Category';
import { dataItem, Item, VisibleCondition } from '../data/Item';
import { dataOffer } from '../data/Offer';
import { DialogOkCancel, DialogOk } from '@common/components/Dialog';
import Resizer from 'react-image-file-resizer';
import _ from 'lodash';
import { dataMaker } from '../data/Maker';
import { dataStock } from '../data/Stock';
import { dataCognitoUser } from '../data/CognitoUser';
import {
  parseNumberString,
  undefinedToEmptyNumber,
  undefinedToEmptyString,
} from '../utils/Formatter';
import moment from 'moment';
import { CONST } from '@common/global';

export const ManagementItems = () => {
  // TODO: どこかのタイミングでリフレッシュさせたほうがいいかもしれない
  const categories = dataCategory.useCategoryVisible();
  const [visibleCondition, setVisibleCondition] = useState<VisibleCondition>('all');
  const [category, setCategory] = useState('all');
  const [stockFrom, setStockFrom] = useState<number | undefined>(undefined);
  const [stockTo, setStockTo] = useState<number | undefined>(undefined);
  const [keyword, setKeyword] = useState<string>('');
  const [isOpenSettingDialog, setIsOpenSettingDialog] = useState(false);

  return (
    <div className="pt-8 flex flex-col items-center gap-2">
      <FormControl className="self-start">
        <StockRefreshButton />
      </FormControl>
      <div className="mb-2">
        商品の在庫増減、商品情報の登録、変更、削除、おすすめの設定が行えます。
        <br />
        画像の変更を行う場合は対象商品画像をクリックし、ダイアログから変更を行なってください。
      </div>

      <div className="w-[800px] mb-4 p-2 border responsive-width">
        <div className="pl-4 text-lg">絞り込み検索</div>
        <div className="flex flex-col gap-2 px-2 mt-2">
          <FormControl className="flex-auto min-w-[150px]">
            <InputLabel id="item-label">端末表示</InputLabel>
            <Select
              label="端末表示"
              labelId="item-label"
              value={visibleCondition}
              onChange={(e) => setVisibleCondition(e.target.value as VisibleCondition)}
            >
              <MenuItem value="all">全て</MenuItem>
              <MenuItem value="visible">表示のみ</MenuItem>
              <MenuItem value="invisible">非表示のみ</MenuItem>
            </Select>
          </FormControl>
          <div className="flex flex-col gap-2 px-2 mt-2">
            <FormControl className="flex-auto min-w-[150px]">
              <InputLabel id="category-label">カテゴリー</InputLabel>
              <Select
                label="カテゴリー"
                labelId="category-label"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <MenuItem value="all">全て</MenuItem>
                {categories.map((category) => {
                  return (
                    <MenuItem key={category.categoryId} value={category.categoryId}>
                      {category.categoryName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div className="flex flex-auto gap-2 min-w-[150px]">
              <TextField
                label="在庫数from"
                type="number"
                className="flex-auto"
                onChange={(e) => setStockFrom(Number(e.target.value))}
              />
              <span className="inline-block my-auto">〜</span>
              <TextField
                label="在庫数to"
                type="number"
                className="flex-auto"
                onChange={(e) => setStockTo(Number(e.target.value))}
              />
            </div>
          </div>
          <div className="flex flex-auto gap-2 min-w-[150px]">
            <TextField
              label="キーワード検索"
              className="flex-auto min-w-[150px]"
              onChange={_.debounce((e) => setKeyword(e.target.value), 200)}
            />
          </div>
        </div>
      </div>
      <SettingItemDialog
        isOpen={isOpenSettingDialog}
        closeCallback={() => setIsOpenSettingDialog(false)}
      />
      <div className="text-center mb-4">
        <Button variant="contained" onClick={() => setIsOpenSettingDialog(true)}>
          商品情報の新規登録
        </Button>
      </div>
      <SettingItems
        categoryId={category}
        stockFrom={stockFrom}
        stockTo={stockTo}
        keyword={keyword}
        visibleCondition={visibleCondition}
      />
    </div>
  );
};

const SettingItems = ({
  categoryId,
  stockFrom,
  stockTo,
  keyword,
  visibleCondition,
}: {
  categoryId: string;
  stockFrom: number | undefined;
  stockTo: number | undefined;
  keyword: string;
  visibleCondition: VisibleCondition;
}) => {
  // MEMO: itemsの取得結果をキャッシュに保存しておく
  dataItem.useItems();
  // MEMO: キャッシュから絞り込みを行う
  const filteredItems = dataItem.useItemsFiltered({
    categoryId,
    stockFrom,
    stockTo,
    keyword,
    visibleCondition,
  });
  return (
    <>
      {filteredItems.map((item) =>
        item.TTL && item.removedBy ? (
          <DeletedItem key={item.itemId} item={item} ttl={item.TTL} />
        ) : (
          <SettingItem key={item.itemId} item={item} />
        )
      )}
    </>
  );
};

const SettingItem = ({ item }: { item: Item }) => {
  const isDeveloper = dataCognitoUser.useIsDeveloper();
  const deleteItem = dataItem.useDeleteItem();
  const createOffer = dataOffer.useCreateOffer();
  const deleteOffer = dataOffer.useDeleteOffer();
  const [isDisable, setIsDisable] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [openImageUploadDialog, setOpenImageUploadDialog] = useState(false);
  const [isOpenSettingDialog, setIsOpenSettingDialog] = useState(false);
  const [isOpenStockSettingDialog, setIsOpenStockSettingDialog] = useState(false);

  return (
    <div key={item.itemId} className="flex flex-row w-full min-h-40 items-center rounded-md shadow">
      <DialogOkCancel
        open={openConfirmDialog}
        title={''}
        message={item.name + 'をおすすめ解除しますか？'}
        callbackOk={async () => {
          await deleteOffer(item.itemId);
          setOpenConfirmDialog(false);
        }}
        callbackCancel={() => setOpenConfirmDialog(false)}
      />
      {isDeveloper && (
        <DialogOkCancel
          open={openConfirmDeleteDialog}
          title={''}
          message={item.name + 'の登録を削除しますか？'}
          callbackOk={async () => {
            await deleteItem(item.itemId);
            setOpenConfirmDeleteDialog(false);
          }}
          callbackCancel={() => setOpenConfirmDeleteDialog(false)}
        />
      )}
      <SettingItemDialog
        isOpen={isOpenSettingDialog}
        item={item}
        closeCallback={() => setIsOpenSettingDialog(false)}
      />
      <SettingStockDialog
        isOpen={isOpenStockSettingDialog}
        item={item}
        closeCallback={() => setIsOpenStockSettingDialog(false)}
      />
      <ImageUploadDialog
        isOpen={openImageUploadDialog}
        item={item}
        closeCallback={() => setOpenImageUploadDialog(false)}
      />
      <div className="w-1/4 h-full cursor-pointer flex-grow-0 flex-shrink-0 align-middle justify-center">
        {!item.image?.[0] ? (
          <Button
            onClick={() => setOpenImageUploadDialog(true)}
            variant="contained"
            className="h-auto"
          >
            画像を設定
          </Button>
        ) : (
          <img
            alt="商品画像"
            src={item.image[0]}
            className="w-full h-full object-cover rounded-l-md"
            onClick={() => setOpenImageUploadDialog(true)}
          />
        )}
      </div>
      <div className="pl-4 pt-2 pb-0 h-auto grow flex flex-col">
        <div className="grow">
          <div className="text-base line-clamp-2"> ID：{item.itemId} </div>
          <div className="text-base line-clamp-2"> 名称：{item.name} </div>
          <div className="text-base line-clamp-2">
            {item.visible ? <span>表示</span> : <span className="text-gray-400">非表示</span>}
          </div>
          <div className="flex flex-wrap gap-2">
            <div className="text-base line-clamp-2">
              在庫数：{item.stock?.freeStockCount ?? '0'}個
            </div>
            <div className="text-base line-clamp-2 text-gray-300 ">
              <span>予約済在庫：{item.stock?.reservedStockCount ?? '0'}個</span>
            </div>
            <div className="text-base line-clamp-2 text-gray-300 ">
              <span>販売済在庫：{item.stock?.soldStockCount ?? '0'}個</span>
            </div>
            <div className="line-clamp-2 text-gray-300 ">
              <span>総在庫：{item.stock?.totalStockCount ?? '0'}個</span>
            </div>
          </div>
        </div>
        <div className="py-4 flex flex-wrap gap-2 justify-center md:justify-start">
          <Button
            variant="contained"
            color="primary"
            className="w-40 mb-2 md:mb-0"
            onClick={() => setIsOpenSettingDialog(true)}
          >
            商品情報を編集
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="w-40 mb-2 md:mb-0"
            onClick={() => setIsOpenStockSettingDialog(true)}
          >
            在庫数を変更
          </Button>
          {item.isOffer ? (
            <Button
              variant="contained"
              color="secondary"
              className="w-40 mb-2 md:mb-0"
              disabled={isDisable}
              onClick={() => setOpenConfirmDialog(true)}
            >
              おすすめを解除
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className="w-40 mb-2 md:mb-0"
              disabled={isDisable}
              onClick={async () => {
                setIsDisable(true);
                await createOffer(item.itemId);
                setIsDisable(false);
              }}
            >
              おすすめを設定
            </Button>
          )}
          {isDeveloper && (
            <Button
              variant="contained"
              color="primary"
              className="w-40 mb-2 md:mb-0"
              onClick={() => setOpenConfirmDeleteDialog(true)}
            >
              商品登録を削除
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const DeletedItem = ({ item, ttl }: { item: Item; ttl: number }) => {
  const isDeveloper = dataCognitoUser.useIsDeveloper();

  return (
    <div key={item.itemId} className="flex flex-row w-full h-40 items-center rounded-md shadow">
      <div className="w-1/4 h-full cursor-pointer flex align-middle justify-center">
        {!item.image?.[0] ? (
          <Button variant="contained" className="h-auto" disabled>
            画像を設定
          </Button>
        ) : (
          <img
            alt="商品画像"
            src={item.image[0]}
            className="w-full h-full object-cover rounded-l-md"
          />
        )}
      </div>
      <div className="pl-4 pt-2 pb-0 h-auto grow flex flex-col">
        <div className="grow">
          <div className="text-base font-bold line-clamp-2">削除実行済み</div>
          <div className="text-base line-clamp-2">
            削除予定時間：
            {moment.unix(ttl).utcOffset(+9).format('YYYY-MM-DD H:mm:ss')}
            {' 〜 '}
            {moment.unix(ttl).utcOffset(+10).format('YYYY-MM-DD H:mm:ss')}
          </div>
          <div className="text-base line-clamp-2"> ID：{item.itemId} </div>
          <div className="text-base line-clamp-2"> 名称：{item.name} </div>
        </div>
        <div className="py-4 flex gap-2">
          <Button variant="contained" color="primary" className="w-40" disabled>
            商品情報を編集
          </Button>
          <Button variant="contained" color="primary" className="w-40" disabled>
            在庫数を変更
          </Button>
          {item.isOffer ? (
            <Button variant="contained" color="secondary" className="w-40" disabled>
              おすすめを解除
            </Button>
          ) : (
            <Button variant="contained" color="primary" className="w-40" disabled>
              おすすめを設定
            </Button>
          )}
          {isDeveloper && (
            <Button variant="contained" color="primary" className="w-30" disabled>
              商品登録を削除
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const ImageUploadDialog = ({
  isOpen,
  item,
  closeCallback,
}: {
  isOpen: boolean;
  item: Item;
  closeCallback: () => void;
}) => {
  const [isExecution, setIsExecution] = useState(false);
  const addImage = dataItem.useAddImage();
  const changeImage = dataItem.useChangeImage();
  const sortImage = dataItem.useSortImage();

  // ファイルをリサイズする関数
  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        608,
        608,
        'JPEG',
        80,
        0,
        (value) => {
          resolve(value as File);
        },
        'file'
      );
    });

  const onAddImageCallback = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsExecution(true);
    const files = e.target.files;
    if (!files) return;
    const resizedFile = (await resizeFile(files[0])) as File;
    await addImage(item.itemId, item.image, resizedFile);
    setIsExecution(false);
  };

  const onChangeImageCallback = async (
    e: ChangeEvent<HTMLInputElement>,
    changeTargetUrl: string
  ) => {
    setIsExecution(true);
    const files = e.target.files;
    if (!files) return;
    const resizedFile = (await resizeFile(files[0])) as File;
    await changeImage(item.itemId, changeTargetUrl, item.image, resizedFile);
    setIsExecution(false);
  };

  const sortCallback = async (currentIndex: number, destinationIndex: number) => {
    setIsExecution(true);
    await sortImage(item.itemId, item.image, currentIndex, destinationIndex);
    setIsExecution(false);
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>商品画像設定</DialogTitle>
      <DialogContent>
        <div className="mb-4 ml-4">
          画像は複数枚設定可能です。
          <br />
          ※1枚目に設定している画像が基本的に表示されます。
          <br />
          ユーザーに表示されるメインの画像を変更したい場合は
          <br />
          対象画像の上部の選択肢から「1枚目に変更」に設定を行なってください。
        </div>
        <div className="overflow-x-auto flex gap-4">
          {item.image.map((imageUrl, index) => (
            <ImageSetting
              key={imageUrl}
              item={item}
              imageUrl={imageUrl}
              index={index}
              isExecution={isExecution}
              onChangeImageCallback={onChangeImageCallback}
              sortCallback={sortCallback}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="mt-auto" component="label" disabled={isExecution}>
          画像を追加
          <input hidden accept="image/*" type="file" onChange={(e) => onAddImageCallback(e)} />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          onClick={closeCallback}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ImageSetting = ({
  item,
  index,
  imageUrl,
  isExecution,
  onChangeImageCallback,
  sortCallback,
}: {
  item: Item;
  index: number;
  imageUrl: string;
  isExecution: boolean;
  onChangeImageCallback: (e: ChangeEvent<HTMLInputElement>, changeTargetUrl: string) => void;
  sortCallback: (currentIndex: number, destinationIndex: number) => void;
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const deleteImage = dataItem.useDeleteImage();

  return (
    <div key={imageUrl} className="mb-4 border p-2">
      <DialogOkCancel
        open={openConfirmDialog}
        title={''}
        message={index + 1 + '枚目を削除しますか？'}
        callbackOk={async () => {
          await deleteImage(item.itemId, imageUrl, item.image);
          setOpenConfirmDialog(false);
        }}
        callbackCancel={() => setOpenConfirmDialog(false)}
      />
      <Typography>{index + 1}枚目</Typography>
      <SortImageSelectBox
        item={item}
        currentIndex={index}
        isExecution={isExecution}
        sortCallback={sortCallback}
      />
      <div className="w-48 h-48 flex align-middle justify-center">
        <img alt="商品画像" src={imageUrl} className="object-cover rounded-l-md my-auto" />
      </div>
      <Button variant="contained" component="label" disabled={isExecution}>
        変更
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={(e) => onChangeImageCallback(e, imageUrl)}
        />
      </Button>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginLeft: 1 }}
        disabled={isExecution}
        onClick={(e) => setOpenConfirmDialog(true)}
      >
        削除
      </Button>
    </div>
  );
};

const SortImageSelectBox = ({
  item,
  currentIndex,
  isExecution,
  sortCallback,
}: {
  item: Item;
  currentIndex: number;
  isExecution: boolean;
  sortCallback: (currentIndex: number, destinationIndex: number) => void;
}) => {
  const [sortValue, setSortValue] = useState('');

  const onChangeOrderCallBack = async (e: SelectChangeEvent<string>, currentIndex: number) => {
    setSortValue(e.target.value);
    await sortCallback(currentIndex, Number(e.target.value));
    setSortValue('');
  };

  return (
    <FormControl variant="filled" className="w-full" sx={{ marginY: 1 }} disabled={isExecution}>
      <InputLabel id="selectLabel">順序を変更</InputLabel>
      <Select
        labelId="selectLabel"
        value={sortValue}
        onChange={(e) => onChangeOrderCallBack(e, currentIndex)}
      >
        {item.image.map((image, index) =>
          currentIndex === index ? (
            <MenuItem key={'menuItem' + index} value={index} disabled>
              {index + 1} 枚目に変更
            </MenuItem>
          ) : (
            <MenuItem key={'menuItem' + index} value={index}>
              {index + 1} 枚目に変更
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

const checkItemValidity = (item: Item): string => {
  let errorMessage = '';
  item.itemId = item.itemId.trimStart();
  item.name = item.name.trimStart();

  if (item.itemId.length === 0) {
    errorMessage = 'IDが空です。IDは、JANコードで設定する必要があります。';
  } else if (item.categoryId.length === 0) {
    errorMessage = 'カテゴリーが選択されていません。';
  } else if (item.name.length === 0) {
    errorMessage = '商品名が設定されていません。';
  } else if (item.price === 0) {
    errorMessage = '商品価格が正しく設定されていません。';
  } else if (item.size > CONST.MAX_CART_SIZE) {
    errorMessage = '商品サイズが最大値より大きいです。';
  } else if (item.weight > CONST.MAX_CART_WEIGHT) {
    errorMessage = '商品重量が最大値より大きいです。';
  }

  return errorMessage;
};

const SettingItemDialog = ({
  isOpen,
  item,
  closeCallback,
}: {
  isOpen: boolean;
  item?: Item;
  closeCallback: () => void;
}) => {
  const [openSettingErrorDialogMessage, setSettingErrorDialogMessage] = useState('');
  const [isExecution, setIsExecution] = useState(false);
  const [openSettingErrorDialog, setSettingErrorDialog] = useState(false);
  const createItem = dataItem.useCreateItem();
  const updateItem = dataItem.useUpdateItem();

  const defaultItem = useMemo(
    () => ({
      itemId: '',
      allergen: '',
      bestByDate: '',
      brand: '',
      categoryId: '',
      copy: '',
      description: '',
      expiration: '',
      image: [],
      makerId: '',
      name: '',
      price: 0,
      priority: 0,
      size: CONST.DEFAULT_ITEM_SIZE,
      visible: true,
      weight: CONST.DEFAULT_ITEM_WEIGHT,
      isOffer: false,
      limitedStock: undefined,
    }),
    []
  );

  const formatItem = (item: Item) => ({
    itemId: item.itemId,
    image: item.image,
    isOffer: item.isOffer,
    visible: item.visible,
    categoryId: undefinedToEmptyString(item.categoryId),
    makerId: undefinedToEmptyString(item.makerId),
    priority: undefinedToEmptyNumber(item.priority),
    name: undefinedToEmptyString(item.name),
    description: undefinedToEmptyString(item.description),
    copy: undefinedToEmptyString(item.copy),
    price: undefinedToEmptyNumber(item.price),
    brand: undefinedToEmptyString(item.brand),
    bestByDate: undefinedToEmptyString(item.bestByDate),
    expiration: undefinedToEmptyString(item.expiration),
    allergen: undefinedToEmptyString(item.allergen),
    weight: undefinedToEmptyNumber(item.weight),
    size: undefinedToEmptyNumber(item.size),
    limitedStock: undefinedToEmptyNumber(item.limitedStock),
  });

  const [itemData, setItemData] = useState(() => formatItem(item || defaultItem));
  useEffect(() => {
    setItemData(formatItem(item || defaultItem));
  }, [item, defaultItem]);
  const categories = dataCategory.useCategoriesVisibleAddEmpty();
  const makers = dataMaker.UseMakersStateAddEmpty();
  const buttonClickCallback = async () => {
    const errorMessage = checkItemValidity(itemData);
    if (errorMessage.length !== 0) {
      setSettingErrorDialogMessage(errorMessage);
      setSettingErrorDialog(true);
      return;
    }

    setIsExecution(true);

    if (item) {
      await updateItem(itemData);
    }

    if (!item) {
      try {
        await createItem(itemData);
        setItemData(formatItem(defaultItem));
      } catch (e) {
        if (e instanceof Error) {
          setSettingErrorDialogMessage(e.message);
          setSettingErrorDialog(true);
          setIsExecution(false);
          return;
        }
      }
    }

    closeCallback();
    setIsExecution(false);
  };

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>商品設定</DialogTitle>
      <DialogContent>
        <div className="mb-4 ml-4">
          商品情報の設定を編集できます。
          <br />
          情報を編集し、{item ? '更新' : '新規登録'}ボタンをクリックしてください。
        </div>
        <div className="mx-auto overflow-y-auto pt-4 flex flex-col gap-4">
          <FormControlLabel
            className="pl-4"
            control={
              <Checkbox
                checked={itemData.visible}
                onChange={(e) => {
                  setItemData({ ...itemData, visible: e.target.checked });
                }}
              />
            }
            label="表示する"
          />
          {item ? (
            ''
          ) : (
            <TextField
              label="ID"
              className="w-full"
              value={itemData.itemId}
              onChange={(e) => setItemData({ ...itemData, itemId: e.target.value })}
            />
          )}
          <FormControl className="w-1/2">
            <InputLabel id="category-label">カテゴリー</InputLabel>
            <Select
              label="カテゴリー"
              labelId="category-label"
              value={itemData.categoryId}
              onChange={(e) => setItemData({ ...itemData, categoryId: e.target.value })}
            >
              {categories.map((category) => {
                return (
                  <MenuItem key={category.categoryId} value={category.categoryId}>
                    {category.categoryName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className="w-1/2">
            <InputLabel id="maker-label">メーカー</InputLabel>
            <Select
              label="メーカー"
              labelId="maker-label"
              value={itemData.makerId}
              onChange={(e) => setItemData({ ...itemData, makerId: e.target.value })}
            >
              {makers.map((maker) => {
                return (
                  <MenuItem key={maker.makerId} value={maker.makerId}>
                    {maker.makerName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            label="表示順"
            className="w-full"
            value={itemData.priority}
            onChange={(e) =>
              setItemData({
                ...itemData,
                priority: Number(parseNumberString(e.target.value, 'integer')),
              })
            }
          />
          <TextField
            label="商品名"
            className="w-full"
            value={itemData.name}
            onChange={(e) => setItemData({ ...itemData, name: e.target.value })}
          />
          <TextField
            label="商品説明"
            className="w-full"
            value={itemData.description}
            multiline
            rows={8}
            onChange={(e) => setItemData({ ...itemData, description: e.target.value })}
          />
          <TextField
            label="キャッチコピー"
            className="w-full"
            value={itemData.copy}
            multiline
            rows={8}
            onChange={(e) => setItemData({ ...itemData, copy: e.target.value })}
          />
          <TextField
            label="商品価格"
            className="w-full"
            value={itemData.price}
            onChange={(e) => {
              setItemData({
                ...itemData,
                price: Number(parseNumberString(e.target.value, 'minus')),
              });
            }}
          />
          <TextField
            label="ブランド"
            className="w-full"
            value={itemData.brand}
            onChange={(e) => setItemData({ ...itemData, brand: e.target.value })}
          />
          <TextField
            label="賞味期限"
            className="w-full"
            value={itemData.bestByDate}
            onChange={(e) => setItemData({ ...itemData, bestByDate: e.target.value })}
          />
          <TextField
            label="消費期限"
            className="w-full"
            value={itemData.expiration}
            onChange={(e) => setItemData({ ...itemData, expiration: e.target.value })}
          />
          <TextField
            label="特定原材料"
            className="w-full"
            value={itemData.allergen}
            onChange={(e) => setItemData({ ...itemData, allergen: e.target.value })}
          />
          <TextField
            label={`重量(${CONST.MAX_CART_WEIGHT}が最大})`}
            className="w-full"
            value={itemData.weight}
            onChange={(e) =>
              setItemData({
                ...itemData,
                weight: Number(parseNumberString(e.target.value, 'integer')),
              })
            }
          />
          <TextField
            label={`サイズ(${CONST.MAX_CART_SIZE}が最大)`}
            className="w-full"
            value={itemData.size}
            onChange={(e) =>
              setItemData({
                ...itemData,
                size: Number(parseNumberString(e.target.value, 'integer')),
              })
            }
          />
          <TextField
            label="在庫僅少表示個数"
            className="w-full"
            value={itemData.limitedStock}
            onChange={(e) =>
              setItemData({
                ...itemData,
                limitedStock: Number(parseNumberString(e.target.value, 'integer')),
              })
            }
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="mt-auto"
          disabled={isExecution}
          onClick={buttonClickCallback}
        >
          {item ? '更新' : '新規登録'}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          disabled={isExecution}
          onClick={() => {
            closeCallback();
            // MEMO: キャンセル時に記述をクリアする
            if (item) {
              setItemData(formatItem(item || defaultItem));
              return;
            }
            setItemData(formatItem(defaultItem));
          }}
        >
          キャンセル
        </Button>
      </DialogActions>
      <DialogOk
        open={openSettingErrorDialog}
        title={'エラー 正しく設定されていない項目があります。'}
        message={openSettingErrorDialogMessage}
        callbackOk={async () => {
          setSettingErrorDialog(false);
        }}
      />
    </Dialog>
  );
};

const SettingStockDialog = ({
  isOpen,
  item,
  closeCallback,
}: {
  isOpen: boolean;
  item: Item;
  closeCallback: () => void;
}) => {
  const [isExecution, setIsExecution] = useState(false);
  const [difference, setDifference] = useState<number>(0);
  const upsertStock = dataStock.useUpsertStock();
  const buttonClickCallback = async () => {
    setIsExecution(true);
    await upsertStock(item.itemId, difference);
    setIsExecution(false);
  };
  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>在庫数設定</DialogTitle>
      <DialogContent>
        <div className="mb-4 ml-4">
          現在の在庫数を増減できます。
          <br />
          増減在庫数を編集し、更新ボタンをクリックしてください。
        </div>
        <div className="mx-auto overflow-y-auto pt-4 flex flex-col gap-4">
          <p>商品名： {item.name}</p>
          <p>現在の在庫数： {item.stock?.freeStockCount ?? 0}個</p>
          <p>現在の予約済在庫： {item.stock?.reservedStockCount ?? 0}個</p>
          <p>現在の販売済在庫： {item.stock?.soldStockCount ?? 0}個</p>
          <p>現在の総在庫： {item.stock?.totalStockCount ?? 0}個</p>
          <TextField
            label="増減在庫数"
            type="text"
            inputMode={'numeric'}
            className="w-full"
            value={difference}
            onChange={(e) => setDifference(Number(parseNumberString(e.target.value, 'minus')))}
            InputProps={{
              endAdornment: (
                <>
                  <IconButton
                    sx={{ padding: 1 }}
                    onClick={() => {
                      setDifference((prev) => prev + 1);
                    }}
                  >
                    <ArrowCircleUpIcon />
                  </IconButton>
                  <IconButton
                    sx={{ padding: 1 }}
                    onClick={() => {
                      setDifference((prev) => prev - 1);
                    }}
                  >
                    <ArrowCircleDownIcon />
                  </IconButton>
                </>
              ),
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="mt-auto"
          disabled={isExecution}
          onClick={buttonClickCallback}
        >
          更新
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          disabled={isExecution}
          onClick={() => {
            closeCallback();
            // MEMO: キャンセル時に記述をクリアする
            setDifference(0);
          }}
        >
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StockRefreshButton = () => {
  const stockRefresh = dataStock.useStockRefresh();

  return (
    <div className="flex justify-end my-2">
      <Button
        variant="contained"
        onClick={() => stockRefresh()}
        startIcon={<RefreshIcon />}
        style={{ backgroundColor: 'white', color: 'black' }}
      >
        在庫を最新の情報に更新
      </Button>
    </div>
  );
};
