import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { dataMaker, Maker } from '../data/Maker';
import { DialogOkCancel, DialogOk } from '@common/components/Dialog';
import { undefinedToEmptyString } from '../utils/Formatter';

export const ManagementMakers = () => {
  const makers = dataMaker.useMaker();
  const [isOpenSettingDialog, setIsOpenSettingDialog] = useState(false);

  return (
    <div className="pt-8 flex flex-col items-center gap-2">
      <div className="mb-2">
        メーカー情報の設定、メーカー画像の変更が行えます。
        <br />
        画像の変更を行う場合は対象メーカー画像をクリックし、ダイアログから変更を行なってください。
      </div>
      <SettingMakerDialog
        isOpen={isOpenSettingDialog}
        closeCallback={() => setIsOpenSettingDialog(false)}
      />
      <div className="text-center mb-4">
        <Button variant="contained" onClick={() => setIsOpenSettingDialog(true)}>
          メーカーを新規作成
        </Button>
      </div>
      {makers.map((maker) => (
        <SettingMaker key={maker.makerId} maker={maker} />
      ))}
    </div>
  );
};

const SettingMaker = ({ maker }: { maker: Maker }) => {
  const [isOpenSettingDialog, setIsOpenSettingDialog] = useState(false);
  const [isOpenImageUploadDialog, setIsOpenImageUploadDialog] = useState(false);

  return (
    <div key={maker.makerId} className="flex flex-row w-full h-28 items-center rounded-md shadow">
      <SettingMakerDialog
        isOpen={isOpenSettingDialog}
        maker={maker}
        closeCallback={() => setIsOpenSettingDialog(false)}
      />
      <ImageUploadDialog
        isOpen={isOpenImageUploadDialog}
        maker={maker}
        closeCallback={() => setIsOpenImageUploadDialog(false)}
      />
      <div className="w-1/4 h-full cursor-pointer">
        {!maker.image?.[0] ? (
          <Button
            onClick={() => setIsOpenImageUploadDialog(true)}
            variant="contained"
            className="h-auto"
          >
            画像を設定
          </Button>
        ) : (
          <img
            alt="商品画像"
            src={maker.image[0]}
            className="w-full h-full object-cover rounded-l-md"
            onClick={() => setIsOpenImageUploadDialog(true)}
          />
        )}
      </div>
      <div className="pl-4 pt-2 pb-0 h-full grow flex flex-col">
        <div className="grow">
          <div className="text-base line-clamp-2"> ID：{maker.makerId} </div>
          <div className="text-base line-clamp-2"> 名称：{maker.makerName} </div>
        </div>
        <div className="pb-4">
          <Button
            variant="contained"
            color="primary"
            className="w-80"
            onClick={() => setIsOpenSettingDialog(true)}
          >
            メーカー情報を変更
          </Button>
        </div>
      </div>
    </div>
  );
};

const checkMakerValidity = (maker: Maker): string => {
  let errorMessage = '';
  if (maker.makerName.length === 0) {
    errorMessage = 'メーカー名が空です。メーカー名は必ず設定する必要があります。';
  } else if (maker.makerName.length !== maker.makerName.trimStart().length) {
    errorMessage = 'メーカー名の先頭にスペースが含まれています。';
  } else if (maker.description === undefined || maker.description?.trimStart().length === 0) {
    errorMessage = '説明が空です。説明は必ず設定する必要があります。';
  }

  return errorMessage;
};

const SettingMakerDialog = ({
  isOpen,
  maker,
  closeCallback,
}: {
  isOpen: boolean;
  maker?: Maker;
  closeCallback: () => void;
}) => {
  const [openSettingErrorDialogMessage, setSettingErrorDialogMessage] = useState('');
  const [openSettingErrorDialog, setSettingErrorDialog] = useState(false);
  const [isExecution, setIsExecution] = useState(false);
  const upsertMaker = dataMaker.useUpsertMaker();
  const defaultMaker: Maker = {
    makerId: '',
    makerName: '',
    businessHours: '',
    holiday: '',
    postalCode: '',
    address: '',
    phone: '',
    url: '',
    latitude: '',
    longitude: '',
    description: '',
    image: [],
  };
  const [makerData, setMakerData] = useState<Maker>(
    maker
      ? {
          ...maker,
          businessHours: undefinedToEmptyString(maker.businessHours),
          holiday: undefinedToEmptyString(maker.holiday),
          postalCode: undefinedToEmptyString(maker.postalCode),
          address: undefinedToEmptyString(maker.address),
          phone: undefinedToEmptyString(maker.phone),
          url: undefinedToEmptyString(maker.url),
          latitude: undefinedToEmptyString(maker.latitude),
          longitude: undefinedToEmptyString(maker.longitude),
          description: undefinedToEmptyString(maker.description),
        }
      : defaultMaker
  );

  const buttonClickCallback = async () => {
    const errorMessage = checkMakerValidity(makerData);
    if (errorMessage.length !== 0) {
      setSettingErrorDialogMessage(errorMessage);
      setSettingErrorDialog(true);
      return;
    }

    setIsExecution(true);
    await upsertMaker(makerData);

    if (!maker) {
      setMakerData(defaultMaker);
    }

    closeCallback();
    setIsExecution(false);
  };

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>メーカー設定</DialogTitle>
      <DialogContent>
        <div className="mb-4 ml-4">
          メーカー情報の設定が行えます。
          <br />
          情報を編集し、{maker ? '更新' : '新規作成'}ボタンをクリックしてください。
        </div>
        <div className="overflow-y-auto pt-4 flex flex-col gap-4">
          <TextField
            label="メーカー名"
            className="w-full"
            value={makerData.makerName}
            onChange={(e) => setMakerData({ ...makerData, makerName: e.target.value })}
          />
          <TextField
            label="郵便番号"
            className="w-full"
            value={makerData.postalCode}
            onChange={(e) => setMakerData({ ...makerData, postalCode: e.target.value })}
          />
          <TextField
            label="住所"
            className="w-full"
            value={makerData.address}
            onChange={(e) => setMakerData({ ...makerData, address: e.target.value })}
          />
          <TextField
            label="営業時間"
            className="w-full"
            value={makerData.businessHours}
            onChange={(e) => setMakerData({ ...makerData, businessHours: e.target.value })}
          />
          <TextField
            label="休日"
            className="w-full"
            value={makerData.holiday}
            onChange={(e) => setMakerData({ ...makerData, holiday: e.target.value })}
          />
          <TextField
            label="説明"
            className="w-full"
            multiline
            rows={8}
            value={makerData.description}
            onChange={(e) => setMakerData({ ...makerData, description: e.target.value })}
          />
          <TextField
            label="緯度"
            className="w-full"
            value={makerData.latitude}
            onChange={(e) => setMakerData({ ...makerData, latitude: e.target.value })}
          />
          <TextField
            label="経度"
            className="w-full"
            value={makerData.longitude}
            onChange={(e) => setMakerData({ ...makerData, longitude: e.target.value })}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="mt-auto"
          disabled={isExecution}
          onClick={buttonClickCallback}
        >
          {maker ? '更新' : '新規作成'}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          onClick={() => {
            closeCallback();
            // MEMO: キャンセル時に記述をクリアする
            if (maker) {
              setMakerData(maker);
              return;
            }
            setMakerData(defaultMaker);
          }}
        >
          キャンセル
        </Button>
      </DialogActions>
      <DialogOk
        open={openSettingErrorDialog}
        title={'エラー 正しく設定されていない項目があります。'}
        message={openSettingErrorDialogMessage}
        callbackOk={async () => {
          setSettingErrorDialog(false);
        }}
      />
    </Dialog>
  );
};

const ImageUploadDialog = ({
  isOpen,
  maker,
  closeCallback,
}: {
  isOpen: boolean;
  maker: Maker;
  closeCallback: () => void;
}) => {
  const [isExecution, setIsExecution] = useState(false);
  const addImage = dataMaker.useAddImage();
  const changeImage = dataMaker.useChangeImage();
  const sortImage = dataMaker.useSortImage();

  // ファイルをリサイズする関数
  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        608,
        608,
        'JPEG',
        80,
        0,
        (value) => {
          resolve(value as File);
        },
        'file'
      );
    });

  const onAddImageCallback = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsExecution(true);
    const files = e.target.files;
    if (!files) return;
    const resizedFile = (await resizeFile(files[0])) as File;
    await addImage(maker.makerId, maker.image, resizedFile);
    setIsExecution(false);
  };

  const onChangeImageCallback = async (
    e: ChangeEvent<HTMLInputElement>,
    changeTargetUrl: string
  ) => {
    setIsExecution(true);
    const files = e.target.files;
    if (!files) return;
    const resizedFile = (await resizeFile(files[0])) as File;
    await changeImage(maker.makerId, changeTargetUrl, maker.image, resizedFile);
    setIsExecution(false);
  };

  const sortCallback = async (currentIndex: number, destinationIndex: number) => {
    setIsExecution(true);
    await sortImage(maker.makerId, maker.image, currentIndex, destinationIndex);
    setIsExecution(false);
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>商品画像設定</DialogTitle>
      <DialogContent>
        <div className="mb-4 ml-4">
          画像は複数枚設定可能です。
          <br />
          ※1枚目に設定している画像が基本的に表示されます。
          <br />
          ユーザーに表示されるメインの画像を変更したい場合は
          <br />
          対象画像の上部の選択肢から「1枚目に変更」に設定を行なってください。
        </div>
        <div className="overflow-x-auto flex gap-4">
          {maker.image.map((imageUrl, index) => (
            <ImageSetting
              key={imageUrl}
              maker={maker}
              imageUrl={imageUrl}
              index={index}
              isExecution={isExecution}
              onChangeImageCallback={onChangeImageCallback}
              sortCallback={sortCallback}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="mt-auto" component="label" disabled={isExecution}>
          画像を追加
          <input hidden accept="image/*" type="file" onChange={(e) => onAddImageCallback(e)} />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          onClick={closeCallback}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ImageSetting = ({
  maker,
  index,
  imageUrl,
  isExecution,
  onChangeImageCallback,
  sortCallback,
}: {
  maker: Maker;
  index: number;
  imageUrl: string;
  isExecution: boolean;
  onChangeImageCallback: (e: ChangeEvent<HTMLInputElement>, changeTargetUrl: string) => void;
  sortCallback: (currentIndex: number, destinationIndex: number) => void;
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const deleteImage = dataMaker.useDeleteImage();

  return (
    <div key={imageUrl} className="mb-4 border p-2">
      <DialogOkCancel
        open={openConfirmDialog}
        title={''}
        message={index + 1 + '枚目を削除しますか？'}
        callbackOk={async () => {
          await deleteImage(maker.makerId, imageUrl, maker.image);
          setOpenConfirmDialog(false);
        }}
        callbackCancel={() => setOpenConfirmDialog(false)}
      />
      <Typography>{index + 1}枚目</Typography>
      <SortImageSelectBox
        maker={maker}
        currentIndex={index}
        isExecution={isExecution}
        sortCallback={sortCallback}
      />
      <div className="w-48 h-48 flex align-middle justify-center">
        <img alt="商品画像" src={imageUrl} className="object-cover rounded-l-md my-auto" />
      </div>
      <Button variant="contained" component="label" disabled={isExecution}>
        変更
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={(e) => onChangeImageCallback(e, imageUrl)}
        />
      </Button>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginLeft: 1 }}
        disabled={isExecution}
        onClick={(e) => setOpenConfirmDialog(true)}
      >
        削除
      </Button>
    </div>
  );
};

const SortImageSelectBox = ({
  maker,
  currentIndex,
  isExecution,
  sortCallback,
}: {
  maker: Maker;
  currentIndex: number;
  isExecution: boolean;
  sortCallback: (currentIndex: number, destinationIndex: number) => void;
}) => {
  const [sortValue, setSortValue] = useState('');

  const onChangeOrderCallBack = async (e: SelectChangeEvent<string>, currentIndex: number) => {
    setSortValue(e.target.value);
    await sortCallback(currentIndex, Number(e.target.value));
    setSortValue('');
  };

  return (
    <FormControl variant="filled" className="w-full" sx={{ marginY: 1 }} disabled={isExecution}>
      <InputLabel id="selectLabel">順序を変更</InputLabel>
      <Select
        labelId="selectLabel"
        value={sortValue}
        onChange={(e) => onChangeOrderCallBack(e, currentIndex)}
      >
        {maker.image.map((image, index) =>
          currentIndex === index ? (
            <MenuItem key={'menuItem' + index} value={index} disabled>
              {index + 1} 枚目に変更
            </MenuItem>
          ) : (
            <MenuItem key={'menuItem' + index} value={index}>
              {index + 1} 枚目に変更
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};
