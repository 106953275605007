import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDownRounded';
import { Capacity, dataCapacity, TimeSlot } from '../data/Capacity';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { DialogOkCancel } from '@common/components/Dialog';
import { dataShop } from '../data/Shop';
import { dataSpot } from '../data/Spot';

export const ManagementCapacities = () => {
  const [spotSelectedId] = dataSpot.useSpotSelectedId();

  return (
    <div className="mt-2">
      <div className="py-4 text-center">
        ２０日間以内の配送枠を日単位で追加・削除できます。
        <br />
        管理を行う配達場所を選択した上で編集を行なってください。
        <br />
        ※すでに追加されている日程は追加できません。
      </div>
      <SelectSpot />

      {spotSelectedId !== '' ? <ManagementCapacitiesForm /> : <></>}
    </div>
  );
};

const SelectSpot = () => {
  const shopIncludeSpots = dataShop.useShopIncludeSpots();
  const [spotSelectedId, setSpotSelectedId] = dataSpot.useSpotSelectedId();
  const capacityRefresh = dataCapacity.useCapacityRefresh();

  return (
    <div className="flex justify-center gap-2">
      {shopIncludeSpots.spots.map((spot) => {
        return (
          <Button
            key={spot.spotId}
            variant="contained"
            disabled={spot.spotId === spotSelectedId}
            onClick={async () => {
              setSpotSelectedId(spot.spotId);
              await capacityRefresh(spot.spotId);
            }}
          >
            <p className="text-xl">
              {spot.spotName}
              {spot.spotId === spotSelectedId ? ': 選択中' : ''}
            </p>
          </Button>
        );
      })}
    </div>
  );
};

const ManagementCapacitiesForm = () => {
  const capacitiesByDate = dataCapacity.useCapacityByDate();
  const batchDeleteCapacity = dataCapacity.useBatchDeleteCapacity();
  const upsertCapacity = dataCapacity.useUpsertCapacity();
  const createCapacities = dataCapacity.useCreateCapacities();
  const capacityRefresh = dataCapacity.useCapacityRefresh();
  const [spotSelectedId] = dataSpot.useSpotSelectedId();
  const [value, setValue] = useState<Moment | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeSlot1, setTimeSlot1] = useState(false); // 11:30~14:00
  const [timeSlot2, setTimeSlot2] = useState(false); // 17:00~19:30

  const isDeleteCapacities = (capacities: Capacity[]) => {
    return capacities.every((capacity) => {
      // MEMO: 配送枠はuiによりttlが設定されている場合のみ削除されたものとみなす
      if (capacity.TTL && capacity.removedBy) return true;
      return false;
    });
  };

  const handleRefreshClick = async () => {
    setIsDisabled(true);
    await capacityRefresh(spotSelectedId);
    setIsDisabled(false);
  };

  return (
    <>
      <div className="py-4 flex justify-center">
        <div className="flex items-center space-x-4">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="日付を選択"
              format="YYYY-MM-DD"
              views={['year', 'month', 'day']}
              minDate={moment()}
              maxDate={moment().add(19, 'day')}
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </LocalizationProvider>
          <FormGroup sx={{ ml: 2 }}>
            <FormControlLabel
              control={
                <Checkbox checked={timeSlot1} onChange={(e) => setTimeSlot1(e.target.checked)} />
              }
              label="11:30~14:00"
            />
            <FormControlLabel
              control={
                <Checkbox checked={timeSlot2} onChange={(e) => setTimeSlot2(e.target.checked)} />
              }
              label="17:00~19:30"
            />
          </FormGroup>
          <Button
            variant="contained"
            sx={{ marginLeft: 1 }}
            disabled={
              isDisabled ||
              !value ||
              Object.keys(capacitiesByDate).includes(value?.format('YYYY-MM-DD') ?? '') ||
              (!timeSlot1 && !timeSlot2)
            }
            onClick={async () => {
              if (!value) return;
              setIsDisabled(true);
              const timeSlots: TimeSlot[] = [];
              if (timeSlot1) timeSlots.push({ startHour: 11.5, endHour: 14 });
              if (timeSlot2) timeSlots.push({ startHour: 17, endHour: 19.5 });

              value && (await createCapacities(value, timeSlots));
              setIsDisabled(false);
            }}
          >
            配送枠を追加
          </Button>
          <Button
            variant="contained"
            sx={{ marginLeft: 1 }}
            disabled={isDisabled}
            onClick={handleRefreshClick}
          >
            配送枠の表示を更新
          </Button>
        </div>
      </div>
      <div className="mx-4">
        {Object.keys(capacitiesByDate).map((date) => {
          const capacities = capacitiesByDate[date];
          return isDeleteCapacities(capacities) ? (
            <DeletedCapacitiesByDate key={date} date={date} ttl={capacities[0].TTL ?? 0} />
          ) : (
            <ManagementCapacitiesByDate
              key={date}
              date={date}
              capacities={capacities}
              isDisabled={isDisabled}
              deleteCallback={async (capacities: Capacity[]) => {
                setIsDisabled(true);
                await batchDeleteCapacity(capacities);
                setIsDisabled(false);
              }}
              upsertCallback={async (
                spotId: string,
                deliveryTimeBegin: number,
                difference: number
              ) => {
                setIsDisabled(true);
                await upsertCapacity(spotId, deliveryTimeBegin, difference);
                setIsDisabled(false);
              }}
            />
          );
        })}
      </div>
    </>
  );
};

const convertCapacitiesToBeginTimeFreeMap = (capacities: Capacity[]) => {
  const data: Record<string, number> = {};
  capacities.forEach((capacity) => {
    data[capacity.beginTime] = capacity.free;
  });
  return data;
};

const getEpochTimeInJST = (dateString: string, timeString: string): number => {
  // JSTの日付と時間からDateオブジェクトを作成（ローカルタイムゾーンとして扱われる）
  const dateTimeString = `${dateString} ${timeString}`;
  const date = new Date(dateTimeString);

  // JSTからUTCへの変換（9時間引く）配送枠はJSTで設定されている？？ので引かない
  const utcDate = new Date(date.getTime()); // - 9 * 60 * 60 * 1000);

  // UTCのエポックタイムを取得
  return utcDate.getTime() / 1000;
};

const ManagementCapacitiesByDate = ({
  date,
  capacities,
  isDisabled,
  deleteCallback,
  upsertCallback,
}: {
  date: string;
  capacities: Capacity[];
  isDisabled: boolean;
  deleteCallback: (capacities: Capacity[]) => void;
  upsertCallback: (spotId: string, deliveryTimeBegin: number, difference: number) => void;
}) => {
  useEffect(() => {
    setCapacitiesState(capacities);
    setBeginTimeFreeMap(convertCapacitiesToBeginTimeFreeMap(capacities));
  }, [capacities]);
  const [capacitiesState, setCapacitiesState] = useState(capacities);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedCell, setSelectedCell] = useState({ beginTime: '', free: 0 });

  const handleCellClick = (cellKey: string) => {
    const cellValue = beginTimeFreeMap[cellKey] || 0;
    setSelectedCell({ beginTime: cellKey, free: cellValue });
    setDialogVisible(true);
  };
  const handleUpdateClick = async () => {
    await handleUpdate(selectedCell.free);
  };
  const handleUpdate = async (newFreeValue: number) => {
    setIsUpdating(true);
    const deliveryTimeBegin = getEpochTimeInJST(date, selectedCell.beginTime);
    const oldValue = beginTimeFreeMap[selectedCell.beginTime] || 0;
    await upsertCallback(capacities[0].spotId, deliveryTimeBegin, newFreeValue - oldValue);
    setDialogVisible(false);
    setIsUpdating(false);
  };
  const handleDecrease = () => {
    setSelectedCell({ ...selectedCell, free: Math.max(0, selectedCell.free - 1) });
  };

  const handleIncrease = () => {
    setSelectedCell({ ...selectedCell, free: selectedCell.free + 1 });
  };
  const [rows] = useState<number[]>(new Array(24).fill(0).map((_, index) => index));
  const [columns] = useState<number[]>(new Array(6).fill(0).map((_, index) => index * 10));
  const [beginTimeFreeMap, setBeginTimeFreeMap] = useState<Record<string, number>>(
    convertCapacitiesToBeginTimeFreeMap(capacities)
  );

  const style = {
    table: {
      width: '100%',
      borderCollapse: 'collapse' as const,
      textAlign: 'center' as const,
      border: '1px solid black',
    },
    header: {
      padding: '8px',
      border: '1px solid black',
      backgroundColor: 'gray',
    },
    text: {
      padding: '8px',
      border: '1px solid black',
    },
  };

  return (
    <>
      {dialogVisible && (
        <Dialog open={dialogVisible} onClose={() => setDialogVisible(false)}>
          <DialogTitle>配送枠の更新</DialogTitle>
          <DialogContent>
            <DialogContentText>{`「${selectedCell.beginTime}」に設定する値を入力してください。`}</DialogContentText>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                autoFocus
                margin="dense"
                id="free"
                label="配送枠数"
                type="text"
                fullWidth
                variant="standard"
                value={selectedCell.free}
                onChange={(e) => {
                  const newValue = parseInt(e.target.value, 10);
                  if (newValue >= 0) {
                    setSelectedCell({ ...selectedCell, free: newValue });
                  }
                }}
                style={{ margin: '0 10px' }}
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton
                        sx={{ padding: 1 }}
                        onClick={() => {
                          handleIncrease();
                        }}
                      >
                        <ArrowCircleUpIcon />
                      </IconButton>
                      <IconButton
                        sx={{ padding: 1 }}
                        onClick={() => {
                          handleDecrease();
                        }}
                      >
                        <ArrowCircleDownIcon />
                      </IconButton>
                    </>
                  ),
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogVisible(false)} disabled={isUpdating}>
              キャンセル
            </Button>
            <Button onClick={handleUpdateClick} disabled={isUpdating}>
              更新
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <DialogOkCancel
        open={openConfirmDialog}
        title={''}
        message={date + 'のすべての配送枠を削除しますか？'}
        callbackOk={async () => {
          await deleteCallback(capacitiesState);
          setOpenConfirmDialog(false);
        }}
        callbackCancel={() => setOpenConfirmDialog(false)}
      />
      <Accordion className="border my-2" expanded={isExpand}>
        <AccordionSummary expandIcon={<ExpandMoreIcon onClick={() => setIsExpand(!isExpand)} />}>
          <p className="text-xl my-auto">{date}</p>
          <Button
            variant="contained"
            sx={{
              marginLeft: 2,
              backgroundColor: 'white',
              color: 'black',
              '&:hover': { background: 'gray' },
            }}
            disabled={isDisabled}
            onClick={() => setOpenConfirmDialog(true)}
          >
            削除
          </Button>

          <p className="ml-auto my-auto" onClick={() => setIsExpand(!isExpand)}>
            配送枠の詳細を表示
          </p>
        </AccordionSummary>
        <AccordionDetails>
          <div className="pb-4">
            配送枠の追加、各配送枠の空き数を変更できます。セルを選択し、新しい値を設定してください。変更後サーバへは即時反映されます。
          </div>
          <table style={style.table}>
            <thead>
              <tr>
                <th style={style.header} colSpan={1}>
                  時
                </th>
                <th style={style.header} colSpan={11}>
                  分
                </th>
              </tr>
              <tr>
                <th style={style.header}></th>
                {[...Array(6)].map((_, i) => (
                  <th style={style.header} key={i}>
                    {(i * 10).toString().padStart(2, '0')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((rowIndex) => (
                <tr key={rowIndex}>
                  <td style={style.header}>{rowIndex}</td>
                  {columns.map((columnIndex) => {
                    const cellKey = `${rowIndex.toString().padStart(2, '0')}:${columnIndex
                      .toString()
                      .padStart(2, '0')}`;
                    const cellValue = beginTimeFreeMap[cellKey] || '';
                    const isGrayColumn = [10, 30, 50].includes(columnIndex);
                    const cellStyle = isGrayColumn
                      ? { ...style.header, backgroundColor: '#D3D3D3' }
                      : style.text;

                    return (
                      <td key={cellKey} style={cellStyle} onClick={() => handleCellClick(cellKey)}>
                        {cellValue}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const DeletedCapacitiesByDate = ({ date, ttl }: { date: string; ttl: number }) => {
  return (
    <>
      <Accordion className="border my-2" sx={{ backgroundColor: 'lightgray' }} expanded={false}>
        <AccordionSummary>
          <p className="text-xl my-auto">{date}（削除実行済み）</p>
          <p className="ml-auto my-auto">
            削除予定時間：
            {moment.unix(ttl).utcOffset(+9).format('YYYY-MM-DD H:mm:ss')}
            {' 〜 '}
            {moment.unix(ttl).utcOffset(+10).format('YYYY-MM-DD H:mm:ss')}
          </p>
        </AccordionSummary>
      </Accordion>
    </>
  );
};
