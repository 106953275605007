import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { DialogOkCancel, DialogOk } from '@common/components/Dialog';
import { undefinedToEmptyString } from '../utils/Formatter';
import { dataShop } from '../data/Shop';
import { ApiShop } from '../data/Api';

export const ManagementShops = () => {
  const shops = dataShop.useShopsValue();
  const [isOpenSettingDialog, setIsOpenSettingDialog] = useState(false);

  return (
    <div className="pt-8 flex flex-col items-center gap-2">
      <div className="mb-2">
        店舗情報の設定、店舗画像の変更が行えます。
        <br />
        画像の変更を行う場合は対象店舗画像をクリックし、ダイアログから変更を行なってください。
      </div>
      <SettingShopDialog
        isOpen={isOpenSettingDialog}
        closeCallback={() => setIsOpenSettingDialog(false)}
      />
      <div className="text-center mb-4">
        <Button variant="contained" onClick={() => setIsOpenSettingDialog(true)}>
          店舗を新規作成
        </Button>
      </div>
      {shops.map((shop) => (
        <SettingShop key={shop.shopId} shop={shop} />
      ))}
    </div>
  );
};

const SettingShop = ({ shop }: { shop: ApiShop }) => {
  const [isOpenSettingDialog, setIsOpenSettingDialog] = useState(false);
  const [isOpenImageUploadDialog, setIsOpenImageUploadDialog] = useState(false);

  return (
    <div key={shop.shopId} className="flex flex-row w-full h-48 items-center rounded-md shadow">
      <SettingShopDialog
        isOpen={isOpenSettingDialog}
        shop={shop}
        closeCallback={() => setIsOpenSettingDialog(false)}
      />
      <ImageUploadDialog
        isOpen={isOpenImageUploadDialog}
        shop={shop}
        closeCallback={() => setIsOpenImageUploadDialog(false)}
      />
      <div className="w-1/4 h-full cursor-pointer">
        {!shop.image?.[0] ? (
          <Button
            onClick={() => setIsOpenImageUploadDialog(true)}
            variant="contained"
            className="h-auto"
          >
            画像を設定
          </Button>
        ) : (
          <img
            alt="店舗画像"
            src={shop.image[0]}
            className="w-full h-full object-cover rounded-l-md"
            onClick={() => setIsOpenImageUploadDialog(true)}
          />
        )}
      </div>
      <div className="pl-4 pt-2 pb-0 h-full grow flex flex-col">
        <div className="grow">
          <div className="text-base line-clamp-2"> ID：{shop.shopId} </div>
          <div className="text-base line-clamp-2"> 名称：{shop.shopName} </div>
          <div className="text-base line-clamp-2">
            {' '}
            場所: {`${shop.latitude}, ${shop.longitude}`}{' '}
          </div>
          <div className="text-base line-clamp-2">配送先：{shop.spotId.join(', ')}</div>
          <div className="text-base line-clamp-2">
            配送手段:
            {shop.bases?.map((base, index) => (
              <span key={base.baseId}>
                {base.name} ({base.baseId}){index < shop.bases.length - 1 ? ', ' : ''}
              </span>
            ))}
          </div>
        </div>
        <div className="pb-4">
          <Button
            variant="contained"
            color="primary"
            className="w-80"
            onClick={() => setIsOpenSettingDialog(true)}
          >
            店舗情報を変更
          </Button>
        </div>
      </div>
    </div>
  );
};

const checkShopValidity = (shop: ApiShop): string => {
  let errorMessage = '';
  if (shop.shopName.length === 0) {
    errorMessage = '店舗名が空です。店舗名は必ず設定する必要があります。';
  } else if (shop.shopName.length !== shop.shopName.trimStart().length) {
    errorMessage = 'メーカー名の先頭にスペースが含まれています。';
  }

  return errorMessage;
};

const SettingShopDialog = ({
  isOpen,
  shop,
  closeCallback,
}: {
  isOpen: boolean;
  shop?: ApiShop;
  closeCallback: () => void;
}) => {
  const [openSettingErrorDialogMessage, setSettingErrorDialogMessage] = useState('');
  const [openSettingErrorDialog, setSettingErrorDialog] = useState(false);
  const [isExecution, setIsExecution] = useState(false);
  const upsertShop = dataShop.useUpsertShop();
  const defaultShop: ApiShop = {
    shopId: '',
    shopName: '',
    latitude: '',
    longitude: '',
    image: [],
    spotId: [],
    bases: [],
  };
  const [shopData, setShopData] = useState<ApiShop>(
    shop
      ? {
          ...shop,
          shopName: undefinedToEmptyString(shop.shopName),
        }
      : defaultShop
  );

  const handleSpotIdChange = (index: number, value: string) => {
    const newSpotIds = [...shopData.spotId];
    newSpotIds[index] = value;
    setShopData({ ...shopData, spotId: newSpotIds });
  };

  const addSpotId = () => {
    setShopData({ ...shopData, spotId: [...shopData.spotId, ''] });
  };

  const removeSpotId = (index: number) => {
    const newSpotIds = [...shopData.spotId];
    newSpotIds.splice(index, 1);
    setShopData({ ...shopData, spotId: newSpotIds });
  };

  const handleBasesChange = (index: number, field: 'name' | 'baseId', value: string) => {
    const updatedBases = shopData.bases.map((base, i) =>
      i === index ? { ...base, [field]: value } : base
    );
    setShopData({ ...shopData, bases: updatedBases });
  };

  const addBase = () => {
    setShopData({
      ...shopData,
      bases: [...shopData.bases, { name: '', baseId: '' }],
    });
  };

  const removeBase = (index: number) => {
    const newBases = [...shopData.bases];
    newBases.splice(index, 1);
    setShopData({
      ...shopData,
      bases: newBases,
    });
  };

  const buttonClickCallback = async () => {
    const errorMessage = checkShopValidity(shopData);
    if (errorMessage.length !== 0) {
      setSettingErrorDialogMessage(errorMessage);
      setSettingErrorDialog(true);
      return;
    }

    setIsExecution(true);
    await upsertShop(shopData);

    if (!shop) {
      setShopData(defaultShop);
    }

    closeCallback();
    setIsExecution(false);
  };

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>店舗設定</DialogTitle>
      <DialogContent>
        <div className="mb-4 ml-4">
          店舗情報の設定が行えます。
          <br />
          情報を編集し、{shop ? '更新' : '新規作成'}ボタンをクリックしてください。
        </div>
        <div className="overflow-y-auto pt-4 flex flex-col gap-4">
          <TextField
            label="店舗名"
            className="w-full"
            value={shopData.shopName}
            onChange={(e) => setShopData({ ...shopData, shopName: e.target.value })}
          />
          <TextField
            label="緯度"
            className="w-full"
            value={shopData.latitude}
            onChange={(e) => setShopData({ ...shopData, latitude: e.target.value })}
          />
          <TextField
            label="経度"
            className="w-full"
            value={shopData.longitude}
            onChange={(e) => setShopData({ ...shopData, longitude: e.target.value })}
          />
          <div className="mb-4">
            {shopData.spotId.map((id, index) => (
              <div key={index} className="flex items-center gap-2 mt-2">
                <TextField
                  label={`配送先 spotId [${index}]`}
                  value={id}
                  onChange={(e) => handleSpotIdChange(index, e.target.value)}
                />
                <Button onClick={() => removeSpotId(index)} variant="contained" color="secondary">
                  削除
                </Button>
              </div>
            ))}
            <Button
              onClick={addSpotId}
              variant="contained"
              sx={{ marginTop: 2, textTransform: 'none' }}
            >
              配送先 spotIdを追加
            </Button>
          </div>
          {shopData.bases.map((base, index) => (
            <div key={index} className="flex gap-2 items-center">
              <TextField
                label={`配送手段名 [${index}]`}
                className="flex-grow"
                value={base.name}
                onChange={(e) => handleBasesChange(index, 'name', e.target.value)}
              />
              <TextField
                label={`配送baseId [${index}]`}
                className="flex-grow"
                value={base.baseId}
                onChange={(e) => handleBasesChange(index, 'baseId', e.target.value)}
              />
              <Button onClick={() => removeBase(index)} variant="contained" color="secondary">
                削除
              </Button>
            </div>
          ))}
          <Button onClick={addBase} variant="contained">
            配送手段を追加
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="mt-auto"
          disabled={isExecution}
          onClick={buttonClickCallback}
        >
          {shop ? '更新' : '新規作成'}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          onClick={() => {
            closeCallback();
            // MEMO: キャンセル時に記述をクリアする
            if (shop) {
              setShopData(shop);
              return;
            }
            setShopData(defaultShop);
          }}
        >
          キャンセル
        </Button>
      </DialogActions>
      <DialogOk
        open={openSettingErrorDialog}
        title={'エラー 正しく設定されていない項目があります。'}
        message={openSettingErrorDialogMessage}
        callbackOk={async () => {
          setSettingErrorDialog(false);
        }}
      />
    </Dialog>
  );
};

const ImageUploadDialog = ({
  isOpen,
  shop,
  closeCallback,
}: {
  isOpen: boolean;
  shop: ApiShop;
  closeCallback: () => void;
}) => {
  const [isExecution, setIsExecution] = useState(false);
  const addImage = dataShop.useAddImage();
  const changeImage = dataShop.useChangeImage();
  const sortImage = dataShop.useSortImage();

  // ファイルをリサイズする関数
  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        608,
        608,
        'JPEG',
        80,
        0,
        (value) => {
          resolve(value as File);
        },
        'file'
      );
    });

  const onAddImageCallback = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsExecution(true);
    const files = e.target.files;
    if (!files) return;
    const resizedFile = (await resizeFile(files[0])) as File;
    await addImage(shop.shopId, shop.image, resizedFile);
    setIsExecution(false);
  };

  const onChangeImageCallback = async (
    e: ChangeEvent<HTMLInputElement>,
    changeTargetUrl: string
  ) => {
    setIsExecution(true);
    const files = e.target.files;
    if (!files) return;
    const resizedFile = (await resizeFile(files[0])) as File;
    await changeImage(shop.shopId, changeTargetUrl, shop.image, resizedFile);
    setIsExecution(false);
  };

  const sortCallback = async (currentIndex: number, destinationIndex: number) => {
    setIsExecution(true);
    await sortImage(shop.shopId, shop.image, currentIndex, destinationIndex);
    setIsExecution(false);
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>店舗画像設定</DialogTitle>
      <DialogContent>
        <div className="mb-4 ml-4">
          画像は複数枚設定可能です。
          <br />
          ※1枚目に設定している画像が基本的に表示されます。
          <br />
          ユーザーに表示されるメインの画像を変更したい場合は
          <br />
          対象画像の上部の選択肢から「1枚目に変更」に設定を行なってください。
        </div>
        <div className="overflow-x-auto flex gap-4">
          {shop.image.map((imageUrl, index) => (
            <ImageSetting
              key={imageUrl}
              shop={shop}
              imageUrl={imageUrl}
              index={index}
              isExecution={isExecution}
              onChangeImageCallback={onChangeImageCallback}
              sortCallback={sortCallback}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="mt-auto" component="label" disabled={isExecution}>
          画像を追加
          <input hidden accept="image/*" type="file" onChange={(e) => onAddImageCallback(e)} />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          onClick={closeCallback}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ImageSetting = ({
  shop,
  index,
  imageUrl,
  isExecution,
  onChangeImageCallback,
  sortCallback,
}: {
  shop: ApiShop;
  index: number;
  imageUrl: string;
  isExecution: boolean;
  onChangeImageCallback: (e: ChangeEvent<HTMLInputElement>, changeTargetUrl: string) => void;
  sortCallback: (currentIndex: number, destinationIndex: number) => void;
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const deleteImage = dataShop.useDeleteImage();

  return (
    <div key={imageUrl} className="mb-4 border p-2">
      <DialogOkCancel
        open={openConfirmDialog}
        title={''}
        message={index + 1 + '枚目を削除しますか？'}
        callbackOk={async () => {
          await deleteImage(shop.shopId, imageUrl, shop.image);
          setOpenConfirmDialog(false);
        }}
        callbackCancel={() => setOpenConfirmDialog(false)}
      />
      <Typography>{index + 1}枚目</Typography>
      <SortImageSelectBox
        shop={shop}
        currentIndex={index}
        isExecution={isExecution}
        sortCallback={sortCallback}
      />
      <div className="w-48 h-48 flex align-middle justify-center">
        <img alt="商品画像" src={imageUrl} className="object-cover rounded-l-md my-auto" />
      </div>
      <Button variant="contained" component="label" disabled={isExecution}>
        変更
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={(e) => onChangeImageCallback(e, imageUrl)}
        />
      </Button>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginLeft: 1 }}
        disabled={isExecution}
        onClick={(e) => setOpenConfirmDialog(true)}
      >
        削除
      </Button>
    </div>
  );
};

const SortImageSelectBox = ({
  shop,
  currentIndex,
  isExecution,
  sortCallback,
}: {
  shop: ApiShop;
  currentIndex: number;
  isExecution: boolean;
  sortCallback: (currentIndex: number, destinationIndex: number) => void;
}) => {
  const [sortValue, setSortValue] = useState('');

  const onChangeOrderCallBack = async (e: SelectChangeEvent<string>, currentIndex: number) => {
    setSortValue(e.target.value);
    await sortCallback(currentIndex, Number(e.target.value));
    setSortValue('');
  };

  return (
    <FormControl variant="filled" className="w-full" sx={{ marginY: 1 }} disabled={isExecution}>
      <InputLabel id="selectLabel">順序を変更</InputLabel>
      <Select
        labelId="selectLabel"
        value={sortValue}
        onChange={(e) => onChangeOrderCallBack(e, currentIndex)}
      >
        {shop.image.map((image, index) =>
          currentIndex === index ? (
            <MenuItem key={'menuItem' + index} value={index} disabled>
              {index + 1} 枚目に変更
            </MenuItem>
          ) : (
            <MenuItem key={'menuItem' + index} value={index}>
              {index + 1} 枚目に変更
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};
