import { atom, selectorFamily, useRecoilCallback, useRecoilValue } from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { ApiUser, api } from './Api';

const usersState = atom<ApiUser[]>({
  key: RecoilKeys.USERS_STATE,
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          setSelf(await api.FetchUsers());
        };
        initialize();
      }
    },
  ],
});

const useEnableUser = () =>
  useRecoilCallback(({ set }) => async (username: string) => {
    await api.EnableUser(username);

    set(usersState, (currentUsers) => {
      return [
        ...currentUsers.map((user) => {
          if (user.Username === username) {
            return { ...user, Enabled: true };
          }
          return user;
        }),
      ];
    });
  });

const useDisableUser = () =>
  useRecoilCallback(({ set }) => async (username: string) => {
    await api.DisableUser(username);

    set(usersState, (currentUsers) => {
      return [
        ...currentUsers.map((user) => {
          if (user.Username === username) {
            return { ...user, Enabled: false };
          }
          return user;
        }),
      ];
    });
  });

const useDeleteUser = () =>
  useRecoilCallback(({ set }) => async (username: string) => {
    await api.DeleteUser(username);
    set(usersState, (currentUsers) => {
      return [...currentUsers.filter((user) => user.Username !== username)];
    });
  });

const usersFiltered = selectorFamily({
  key: RecoilKeys.USERS_FILTERED,
  get:
    (condition: string) =>
    ({ get }) => {
      let filteredUsers: ApiUser[] = get(usersState);

      // キーワード検索
      if (condition !== '') {
        filteredUsers = filteredUsers.filter((user) => {
          if (user.Username.indexOf(condition) !== -1) {
            return true;
          }

          for (const value of Object.values(user.Attributes)) {
            // メールアドレスのみ検索対象としています
            if (value.Name === 'email') {
              if (value.Value.indexOf(condition) !== -1) {
                return true;
              }
            }
          }

          return false;
        });
      }

      return filteredUsers;
    },
});

export const dataUser = {
  useUsers: () => useRecoilValue(usersState),
  useUsersFiltered: (keyword: string) => useRecoilValue(usersFiltered(keyword)),
  useEnableUser,
  useDisableUser,
  useDeleteUser,
};
