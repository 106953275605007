import { Button } from '@mui/material';
import { OrderItemState, dataOrder } from '../data/Order';
import { DialogOrderInfo } from './OrderInfoDialog';
import { useState } from 'react';

export const OrderFailed = () => {
  const failedOrders = dataOrder.useOrderItemsFailed();
  const [infoDialog, setInfoDialog] = useState(false);
  const [orderDetail, setOrderDetail] = useState<OrderItemState>({} as OrderItemState);
  const clickInfo = (order: OrderItemState) => {
    setOrderDetail(order);
    setInfoDialog(true);
  };

  return (
    <div className="p-2 py-4 flex flex-col">
      <OrderSwitchButton />
      <DialogOrderInfo
        open={infoDialog}
        order={orderDetail}
        callbackOnClose={() => setInfoDialog(false)}
      />
      {failedOrders.map((failedOrder) => {
        return (
          <div
            key={failedOrder.orderId}
            className="grid w-full h-20 items-center rounded-md shadow px-4 grid-cols-4"
          >
            <p>
              注文ID:{' '}
              <span className="cursor-pointer underline" onClick={() => clickInfo(failedOrder)}>
                {failedOrder.orderId}
              </span>
            </p>
            <p>エラー内容: {failedOrder.paymentError}</p>
            <p>注文時間: {failedOrder.timePaying.toLocaleString()}</p>
            <p>
              注文者: {failedOrder.userInfo.givenName} {failedOrder.userInfo.surName}
            </p>
          </div>
        );
      })}
    </div>
  );
};

const OrderSwitchButton = () => {
  const [isFiltered, setIsFiltered] = dataOrder.useIsFilteredOrderByLastModified();

  return (
    <div className="flex justify-end my-2">
      <Button variant="contained" onClick={() => setIsFiltered(!isFiltered)}>
        {isFiltered ? '過去の注文をすべて表示' : '5日前までの注文のみ表示'}
      </Button>
    </div>
  );
};
