import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { OrderItemState } from '../data/Order';
import CloseIcon from '@mui/icons-material/Close';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import PersonIcon from '@mui/icons-material/Person';

export const OrderCustomerDetailDialog = ({
  open,
  order,
  callbackOnClose,
}: {
  open: boolean;
  order: OrderItemState;
  callbackOnClose: () => void;
}) => {
  const userInfo = order.userInfo;
  console.log('dialigdetail', order);
  return (
    <Dialog open={open} onClose={callbackOnClose}>
      <Box className="flex">
        <DialogTitle>注文者詳細</DialogTitle>
        <Box className="ml-auto pt-1">
          <IconButton size="large" onClick={callbackOnClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <DialogContent>
        <DialogContentText>
          <span>注文者名: {`${userInfo.surName ?? ''} ${userInfo.givenName ?? ''}`}</span>
          <span>{userInfo.isVendingMachine ? <FoodBankIcon /> : <PersonIcon />}</span>
        </DialogContentText>
        {userInfo.isVendingMachine === true && (
          <DialogContentText>携帯電話番号: {order.phoneNumber}</DialogContentText>
        )}
        {userInfo.isVendingMachine === false && (
          <>
            <DialogContentText>
              携帯電話番号:{' '}
              <a href={`tel:${userInfo.phone}`} className="border-b border-blue-500 text-blue-500">
                {userInfo.phone}
              </a>
            </DialogContentText>
            <DialogContentText>メールアドレス: {userInfo.email}</DialogContentText>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
