import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Capacity, dataUserCapacity } from '../data/UserCapacity';
import { useState, useEffect } from 'react';
import { dataShop } from '../data/Shop';
import { dataSpot } from '../data/Spot';

export const ManagementUserCapacities = () => {
  const [spotSelectedId] = dataSpot.useSpotSelectedId();

  return (
    <div className="mt-2">
      <div className="py-4 text-center">
        ユーザアプリ上で表示される配送枠を確認できます。
        <br />
        ※最小値(配送枠, 配送ロボット配送枠) = ユーザアプリ上で表示される配送枠
      </div>
      <SelectSpot />
      {spotSelectedId !== '' ? <ManagementUserCapacitiesForm /> : <></>}
    </div>
  );
};

const SelectSpot = () => {
  const shopIncludeSpots = dataShop.useShopIncludeSpots();
  const [spotSelectedId, setSpotSelectedId] = dataSpot.useSpotSelectedId();
  const userCapacityRefresh = dataUserCapacity.useUserCapacityRefresh();

  return (
    <div className="flex justify-center gap-2">
      {shopIncludeSpots.spots.map((spot) => {
        return (
          <Button
            key={spot.spotId}
            variant="contained"
            disabled={spot.spotId === spotSelectedId}
            onClick={async () => {
              setSpotSelectedId(spot.spotId);
              userCapacityRefresh(spot.spotId);
            }}
          >
            <p className="text-xl">
              {spot.spotName}
              {spot.spotId === spotSelectedId ? ': 選択中' : ''}
            </p>
          </Button>
        );
      })}
    </div>
  );
};

const ManagementUserCapacitiesForm = () => {
  const userCapacitiesByDate = dataUserCapacity.useUserCapacityByDate();
  const userCapacityRefresh = dataUserCapacity.useUserCapacityRefresh();
  const [spotSelectedId] = dataSpot.useSpotSelectedId();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleRefreshClick = async () => {
    setIsDisabled(true);
    await userCapacityRefresh(spotSelectedId);
    setIsDisabled(false);
  };

  return (
    <>
      <div className="py-4 flex justify-center">
        <Button
          variant="contained"
          sx={{ marginLeft: 1 }}
          disabled={isDisabled}
          onClick={handleRefreshClick}
        >
          ユーザアプリ上で表示される配送枠の表示を更新
        </Button>
      </div>
      <div className="mx-4">
        {Object.keys(userCapacitiesByDate).map((date) => {
          const capacities = userCapacitiesByDate[date];
          return <ManagementUserCapacitiesByDate key={date} date={date} capacities={capacities} />;
        })}
      </div>
    </>
  );
};

const convertCapacitiesToBeginTimeFreeMap = (capacities: Capacity[]) => {
  const data: Record<string, number> = {};
  capacities.forEach((capacity) => {
    data[capacity.beginTime] = capacity.free;
  });
  return data;
};

const ManagementUserCapacitiesByDate = ({
  date,
  capacities,
}: {
  date: string;
  capacities: Capacity[];
}) => {
  useEffect(() => {
    setBeginTimeFreeMap(convertCapacitiesToBeginTimeFreeMap(capacities));
  }, [capacities]);
  const [isExpand, setIsExpand] = useState(false);
  const [rows] = useState<number[]>(new Array(24).fill(0).map((_, index) => index));
  const [columns] = useState<number[]>(new Array(6).fill(0).map((_, index) => index * 10));
  const [beginTimeFreeMap, setBeginTimeFreeMap] = useState<Record<string, number>>(
    convertCapacitiesToBeginTimeFreeMap(capacities)
  );

  const style = {
    table: {
      width: '100%',
      borderCollapse: 'collapse' as const,
      textAlign: 'center' as const,
      border: '1px solid black',
    },
    header: {
      padding: '8px',
      border: '1px solid black',
      backgroundColor: 'gray',
    },
    text: {
      padding: '8px',
      border: '1px solid black',
    },
  };

  return (
    <>
      <Accordion className="border my-2" expanded={isExpand}>
        <AccordionSummary expandIcon={<ExpandMoreIcon onClick={() => setIsExpand(!isExpand)} />}>
          <p className="text-xl my-auto">{date}</p>
          <p className="ml-auto my-auto" onClick={() => setIsExpand(!isExpand)}>
            ユーザアプリ上で表示される配送枠の詳細を表示
          </p>
        </AccordionSummary>
        <AccordionDetails>
          <div className="pb-4">ユーザアプリ上で表示される配送枠</div>
          <table style={style.table}>
            <thead>
              <tr>
                <th style={style.header} colSpan={1}>
                  時
                </th>
                <th style={style.header} colSpan={11}>
                  分
                </th>
              </tr>
              <tr>
                <th style={style.header}></th>
                {[...Array(6)].map((_, i) => (
                  <th style={style.header} key={i}>
                    {(i * 10).toString().padStart(2, '0')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((rowIndex) => (
                <tr key={rowIndex}>
                  <td style={style.header}>{rowIndex}</td>
                  {columns.map((columnIndex) => {
                    const cellKey = `${rowIndex.toString().padStart(2, '0')}:${columnIndex
                      .toString()
                      .padStart(2, '0')}`;
                    const cellValue = beginTimeFreeMap[cellKey] || '';
                    const isGrayColumn = [10, 30, 50].includes(columnIndex);
                    const cellStyle = isGrayColumn
                      ? { ...style.header, backgroundColor: '#D3D3D3' }
                      : { ...style.header, backgroundColor: '#F2F3F3' };

                    return (
                      <td key={cellKey} style={cellStyle}>
                        {cellValue}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
