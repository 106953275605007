import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { dataShop } from '../data/Shop';
import { ApiSpot, DroneBase } from '../data/Api';
import { dataSpot, SpotIncludeBoxes } from '../data/Spot';
import Resizer from 'react-image-file-resizer';
import { DialogOkCancel } from '../utils/Dialog';
import { dataBox } from '../data/Box';
import { dataCognitoUser } from '../data/CognitoUser';
import moment from 'moment';

export const ManagementSpots = () => {
  const { spots } = dataShop.useShopIncludeSpots();
  const [isOpenSettingDialog, setIsOpenSettingDialog] = useState(false);

  return (
    <div className="pt-8 flex flex-col items-center gap-2">
      <div className="mb-2">
        配送先情報の設定、配送先画像の変更が行えます。
        <br />
        画像の変更を行う場合は対象配送先の画像をクリックし、ダイアログから変更を行なってください。
      </div>
      <SettingSpotDialog
        isOpen={isOpenSettingDialog}
        closeCallback={() => setIsOpenSettingDialog(false)}
      />
      <div className="text-center mb-4">
        <Button variant="contained" onClick={() => setIsOpenSettingDialog(true)}>
          配送先を新規作成
        </Button>
      </div>
      {spots.map((spot) => (
        <SettingSpot key={spot.spotId} spot={spot} />
      ))}
    </div>
  );
};

const SettingSpot = ({ spot }: { spot: SpotIncludeBoxes }) => {
  const [isOpenSettingDialog, setIsOpenSettingDialog] = useState(false);
  const [isOpenImageUploadDialog, setIsOpenImageUploadDialog] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [isOpenSettingBoxDialog, setIsOpenSettingBoxDialog] = useState(false);
  const isDeveloper = dataCognitoUser.useIsDeveloper();
  const deleteSpot = dataSpot.useDeleteSpot();

  return (
    <div key={spot.spotId} className="flex flex-row w-full h-32 items-center rounded-md shadow">
      <SettingSpotDialog
        isOpen={isOpenSettingDialog}
        spot={spot}
        closeCallback={() => setIsOpenSettingDialog(false)}
      />
      {isDeveloper && (
        <>
          <SettingBoxDialog
            isOpen={isOpenSettingBoxDialog}
            /* 不要なboxesが追加されないようにコピーを渡す */
            spotIncludeBoxes={JSON.parse(JSON.stringify(spot))}
            closeCallback={() => setIsOpenSettingBoxDialog(false)}
          />
          <DialogOkCancel
            open={isOpenConfirmDialog}
            title={''}
            message={spot.spotName + 'を削除しますか？'}
            callbackOk={async () => {
              await deleteSpot(spot.spotId);
              setIsOpenConfirmDialog(false);
            }}
            callbackCancel={() => setIsOpenConfirmDialog(false)}
          />
        </>
      )}
      <ImageUploadDialog
        isOpen={isOpenImageUploadDialog}
        spot={spot}
        closeCallback={() => setIsOpenImageUploadDialog(false)}
      />
      <div className="w-1/4 h-full cursor-pointer">
        {!spot.image?.[0] ? (
          <Button
            onClick={() => setIsOpenImageUploadDialog(true)}
            variant="contained"
            className="h-full w-full"
          >
            画像を設定
          </Button>
        ) : (
          <img
            alt="商品画像"
            src={spot.image[0]}
            className="w-full h-full object-cover rounded-l-md"
            onClick={() => setIsOpenImageUploadDialog(true)}
          />
        )}
      </div>
      <div className="pl-4 pt-2 pb-0 h-full grow flex flex-col">
        <div className="grow">
          <div className="text-base line-clamp-2"> ID：{spot.spotId} </div>
          <div className="text-base line-clamp-2"> 名称：{spot.spotName} </div>
          <div className="text-base line-clamp-2"> BOX数：{spot.boxes.length} </div>
        </div>
        <div className="pb-4 flex gap-2">
          <Button
            variant="contained"
            color="primary"
            className="w-80"
            onClick={() => setIsOpenSettingDialog(true)}
          >
            配送先情報を変更
          </Button>
          {isDeveloper && (
            <>
              <Button
                variant="contained"
                color="primary"
                className="w-80"
                onClick={() => setIsOpenSettingBoxDialog(true)}
              >
                BOX情報を編集
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className="w-80"
                onClick={() => setIsOpenConfirmDialog(true)}
              >
                配送先を削除
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const SettingBoxDialog = ({
  isOpen,
  spotIncludeBoxes,
  closeCallback,
}: {
  isOpen: boolean;
  spotIncludeBoxes: SpotIncludeBoxes;
  closeCallback: () => void;
}) => {
  const createBox = dataBox.useCreateBox();
  const deleteBox = dataBox.useDeleteBox();
  const setEmpty = dataBox.useSetEmpty();
  const [isExecution, setIsExecution] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [boxIdState, setBoxIdState] = useState('');
  const cellStyle = 'py-2 px-4 border';

  const buttonCreateClickCallback = async () => {
    setIsExecution(true);
    await createBox(spotIncludeBoxes.spotId);
    setIsExecution(false);
  };

  const buttonSetEmptyClickCallback = async (boxId: string) => {
    setIsExecution(true);
    await setEmpty(boxId);
    setIsExecution(false);
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <DialogOkCancel
        open={isOpenConfirm}
        title={''}
        message={`boxId:${boxIdState}を削除しますか？`}
        callbackOk={async () => {
          setIsExecution(true);
          await deleteBox(boxIdState);
          setIsExecution(false);
          setIsOpenConfirm(false);
        }}
        callbackCancel={() => setIsOpenConfirm(false)}
      />
      <DialogTitle className="text-center">BOX設定</DialogTitle>
      <DialogContent>
        <div className="mb-4 text-center">
          {spotIncludeBoxes.spotName}のBOX情報の設定が行えます。
          <br />
          状態を空にしたい場合や、新規BOXの作成を行いたい場合はそれぞれクリックしてください。
        </div>
        <table className="mx-auto">
          <thead>
            <tr className="text-center">
              <th className={cellStyle}>boxId</th>
              <th className={cellStyle}>オーダーId</th>
              <th className={cellStyle}>状態</th>
              <th className={cellStyle}>空に更新</th>
              <th className={cellStyle}>削除</th>
            </tr>
          </thead>
          <tbody>
            {spotIncludeBoxes.boxes.map((box) =>
              box.TTL && box.removedBy ? (
                <tr key={box.boxId} className="text-center bg-gray-300">
                  <td className={cellStyle}>{box.boxId}</td>
                  <td className={cellStyle} colSpan={4}>
                    {moment.unix(box.TTL).utcOffset(+9).format('YYYY/MM/DD HH:mm:ss')}
                    {' 〜 '}
                    {moment.unix(box.TTL).utcOffset(+10).format('YYYY/MM/DD HH:mm:ss')}
                    に削除予定
                  </td>
                </tr>
              ) : (
                <tr key={box.boxId} className="text-center">
                  <td className={cellStyle}>{box.boxId}</td>
                  <td className={cellStyle}>{box.orderId}</td>
                  <td className={cellStyle}>{box.state}</td>
                  <td className={cellStyle}>
                    <Button
                      variant="contained"
                      disabled={isExecution}
                      onClick={() => {
                        buttonSetEmptyClickCallback(box.boxId);
                      }}
                    >
                      更新
                    </Button>
                  </td>
                  <td className={cellStyle}>
                    <Button
                      variant="contained"
                      disabled={isExecution}
                      onClick={() => {
                        setBoxIdState(box.boxId);
                        setIsOpenConfirm(true);
                      }}
                    >
                      削除
                    </Button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="mt-auto"
          disabled={isExecution}
          onClick={buttonCreateClickCallback}
        >
          新規BOXを作成
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          onClick={() => {
            closeCallback();
          }}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SettingSpotDialog = ({
  isOpen,
  spot,
  closeCallback,
}: {
  isOpen: boolean;
  spot?: ApiSpot;
  closeCallback: () => void;
}) => {
  const [isExecution, setIsExecution] = useState(false);
  const upsertSpot = dataSpot.useUpsertSpot();
  const defaultSpot = {
    spotId: '',
    spotName: '',
    latitude: '',
    longitude: '',
    image: [],
    bases: [],
  };
  const [spotData, setSpotData] = useState<ApiSpot>(
    spot
      ? {
          spotId: spot.spotId,
          spotName: spot.spotName,
          image: spot.image,
          latitude: spot.latitude || '',
          longitude: spot.longitude || '',
          bases: spot.bases || [],
        }
      : defaultSpot
  );

  const buttonClickCallback = async () => {
    setIsExecution(true);
    await upsertSpot(spotData);
    setIsExecution(false);
    closeCallback();
  };

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>配送先設定</DialogTitle>
      <DialogContent>
        <div className="mb-4 ml-4">
          配送先情報の設定が行えます。
          <br />
          情報を編集し、{spot ? '更新' : '新規作成'}ボタンをクリックしてください。
        </div>
        <div className="overflow-y-auto pt-4 flex flex-col gap-4">
          <TextField
            label="配送先名"
            className="w-full"
            value={spotData.spotName}
            onChange={(e) => setSpotData({ ...spotData, spotName: e.target.value })}
          />
          <TextField
            label="緯度"
            className="w-full"
            value={spotData.latitude}
            type="number"
            onChange={(e) => setSpotData({ ...spotData, latitude: e.target.value })}
          />
          <TextField
            label="経度"
            className="w-full"
            value={spotData.longitude}
            type="number"
            onChange={(e) => setSpotData({ ...spotData, longitude: e.target.value })}
          />
          <TextField
            label="配送手段"
            className="w-full"
            value={spotData.bases[0] ? spotData.bases[0].name : ''}
            onChange={(e) => {
              let newBases: DroneBase[] = [];
              if (spotData.bases.length > 0) {
                const firstBase = { ...spotData.bases[0] };
                firstBase.name = e.target.value;
                newBases = [firstBase, ...spotData.bases.slice(1)];
                setSpotData({ ...spotData, bases: newBases });
              } else {
                const newBase = { baseId: '', name: e.target.value };
                newBases = [newBase];
              }
              setSpotData({ ...spotData, bases: newBases });
            }}
          />
          <TextField
            label="配送baseId"
            className="w-full"
            value={spotData.bases[0] ? spotData.bases[0].baseId : ''}
            onChange={(e) => {
              let newBases: DroneBase[] = [];
              if (spotData.bases.length > 0) {
                const firstBase = { ...spotData.bases[0] };
                firstBase.baseId = e.target.value;
                newBases = [firstBase, ...spotData.bases.slice(1)];
              } else {
                const newBase = { name: '', baseId: e.target.value };
                newBases = [newBase];
              }
              setSpotData({ ...spotData, bases: newBases });
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="mt-auto"
          disabled={isExecution}
          onClick={buttonClickCallback}
        >
          {spot ? '更新' : '新規作成'}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          onClick={() => {
            closeCallback();
            // MEMO: キャンセル時に記述をクリアする
            if (spot) {
              setSpotData(spot);
              return;
            }
            setSpotData(defaultSpot);
          }}
        >
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ImageUploadDialog = ({
  isOpen,
  spot,
  closeCallback,
}: {
  isOpen: boolean;
  spot: ApiSpot;
  closeCallback: () => void;
}) => {
  const [isExecution, setIsExecution] = useState(false);
  const addImage = dataSpot.useAddImage();
  const changeImage = dataSpot.useChangeImage();
  const sortImage = dataSpot.useSortImage();

  // ファイルをリサイズする関数
  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        608,
        608,
        'JPEG',
        80,
        0,
        (value) => {
          resolve(value as File);
        },
        'file'
      );
    });

  const onAddImageCallback = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsExecution(true);
    const files = e.target.files;
    if (!files) return;
    const resizedFile = (await resizeFile(files[0])) as File;
    await addImage(spot.spotId, spot.image, resizedFile);
    setIsExecution(false);
  };

  const onChangeImageCallback = async (
    e: ChangeEvent<HTMLInputElement>,
    changeTargetUrl: string
  ) => {
    setIsExecution(true);
    const files = e.target.files;
    if (!files) return;
    const resizedFile = (await resizeFile(files[0])) as File;
    await changeImage(spot.spotId, changeTargetUrl, spot.image, resizedFile);
    setIsExecution(false);
  };

  const sortCallback = async (currentIndex: number, destinationIndex: number) => {
    setIsExecution(true);
    await sortImage(spot.spotId, spot.image, currentIndex, destinationIndex);
    setIsExecution(false);
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>商品画像設定</DialogTitle>
      <DialogContent>
        <div className="mb-4 ml-4">
          画像は複数枚設定可能です。
          <br />
          ※1枚目に設定している画像が基本的に表示されます。
          <br />
          ユーザーに表示されるメインの画像を変更したい場合は
          <br />
          対象画像の上部の選択肢から「1枚目に変更」に設定を行なってください。
        </div>
        <div className="overflow-x-auto flex gap-4">
          {spot.image.map((imageUrl, index) => (
            <ImageSetting
              key={imageUrl}
              spot={spot}
              imageUrl={imageUrl}
              index={index}
              isExecution={isExecution}
              onChangeImageCallback={onChangeImageCallback}
              sortCallback={sortCallback}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="mt-auto" component="label" disabled={isExecution}>
          画像を追加
          <input hidden accept="image/*" type="file" onChange={(e) => onAddImageCallback(e)} />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          onClick={closeCallback}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ImageSetting = ({
  spot,
  index,
  imageUrl,
  isExecution,
  onChangeImageCallback,
  sortCallback,
}: {
  spot: ApiSpot;
  index: number;
  imageUrl: string;
  isExecution: boolean;
  onChangeImageCallback: (e: ChangeEvent<HTMLInputElement>, changeTargetUrl: string) => void;
  sortCallback: (currentIndex: number, destinationIndex: number) => void;
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const deleteImage = dataSpot.useDeleteImage();

  return (
    <div key={imageUrl} className="mb-4 border p-2">
      <DialogOkCancel
        open={openConfirmDialog}
        title={''}
        message={index + 1 + '枚目を削除しますか？'}
        callbackOk={async () => {
          await deleteImage(spot.spotId, imageUrl, spot.image);
          setOpenConfirmDialog(false);
        }}
        callbackCancel={() => setOpenConfirmDialog(false)}
      />
      <Typography>{index + 1}枚目</Typography>
      <SortImageSelectBox
        spot={spot}
        currentIndex={index}
        isExecution={isExecution}
        sortCallback={sortCallback}
      />
      <div className="w-48 h-48 flex align-middle justify-center">
        <img alt="商品画像" src={imageUrl} className="object-cover rounded-l-md my-auto" />
      </div>
      <Button variant="contained" component="label" disabled={isExecution}>
        変更
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={(e) => onChangeImageCallback(e, imageUrl)}
        />
      </Button>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginLeft: 1 }}
        disabled={isExecution}
        onClick={(e) => setOpenConfirmDialog(true)}
      >
        削除
      </Button>
    </div>
  );
};

const SortImageSelectBox = ({
  spot,
  currentIndex,
  isExecution,
  sortCallback,
}: {
  spot: ApiSpot;
  currentIndex: number;
  isExecution: boolean;
  sortCallback: (currentIndex: number, destinationIndex: number) => void;
}) => {
  const [sortValue, setSortValue] = useState('');

  const onChangeOrderCallBack = async (e: SelectChangeEvent<string>, currentIndex: number) => {
    setSortValue(e.target.value);
    await sortCallback(currentIndex, Number(e.target.value));
    setSortValue('');
  };

  return (
    <FormControl variant="filled" className="w-full" sx={{ marginY: 1 }} disabled={isExecution}>
      <InputLabel id="selectLabel">順序を変更</InputLabel>
      <Select
        labelId="selectLabel"
        value={sortValue}
        onChange={(e) => onChangeOrderCallBack(e, currentIndex)}
      >
        {spot.image.map((image, index) =>
          currentIndex === index ? (
            <MenuItem key={'menuItem' + index} value={index} disabled>
              {index + 1} 枚目に変更
            </MenuItem>
          ) : (
            <MenuItem key={'menuItem' + index} value={index}>
              {index + 1} 枚目に変更
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};
