import moment, { Moment } from 'moment';
import { atomFamily, selector, useRecoilCallback, useRecoilValue } from 'recoil';
import { CONST } from '@common/global';
import { api, ApiCapacity } from './Api';
import { RecoilKeys } from './RecoilKeys';
import { spotSelectedIdState } from './Spot';
import { cognitoUserState } from './CognitoUser';

// すべての配達可能日（複数日）
const capacityState = atomFamily<ApiCapacity[], string>({
  key: RecoilKeys.CAPACITY_STATE,
  default: [],
  effects: (spotId: string) => [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          const capacities = await api.FetchCapacities(spotId);
          setSelf(capacities);
        };
        initialize();
      }
    },
  ],
});

export type Capacity = {
  spotId: string;
  deliveryTimeBegin: number;
  beginTime: string;
  endTime: string;
  free: number;
  TTL?: number;
  removedBy?: string;
};

type CapacitiesByDate = {
  [date: string]: Capacity[];
};

const capacitiesByDate = selector<CapacitiesByDate>({
  key: RecoilKeys.CAPACITIES_BY_DATE,
  get: ({ get }) => {
    const spotSelectedId = get(spotSelectedIdState);
    const capacities = get(capacityState(spotSelectedId));

    return capacities.reduce((result: CapacitiesByDate, currentValue) => {
      const beginMoment = moment.unix(currentValue.deliveryTimeBegin).utcOffset(+9);
      const endMoment = beginMoment
        .clone()
        .add(CONST.NEXT_DELIVERY_SLOT_INTERVAL_MINUTES, 'minute');
      const yyyymmddString = beginMoment.format('YYYY-MM-DD');

      if (!result[yyyymmddString]) {
        result[yyyymmddString] = [];
      }

      result[yyyymmddString].push({
        spotId: spotSelectedId,
        deliveryTimeBegin: currentValue.deliveryTimeBegin,
        beginTime: beginMoment.format('HH:mm'),
        endTime: endMoment.format('HH:mm'),
        free: currentValue.free,
        TTL: currentValue.TTL,
        removedBy: currentValue.removedBy,
      });

      return result;
    }, {});
  },
});

const useUpsertCapacity = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (spotId: string, deliveryTimeBegin: number, difference: number) => {
        await api.UpsertCapacity(spotId, deliveryTimeBegin, difference);
        const updatedCapacities = await api.FetchCapacities(spotId);
        set(capacityState(spotId), updatedCapacities);
      },
    []
  );

const useBatchUpsertCapacity = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (capacities: Capacity[]) => {
        const selectedSpotId = await snapshot.getPromise(spotSelectedIdState);
        await api.BatchUpsertCapacity(capacities);
        set(capacityState(selectedSpotId), (currentCapacities) => {
          return [...currentCapacities, ...capacities];
        });
      },
    []
  );

export type TimeSlot = {
  startHour: number;
  endHour: number;
};

const useCreateCapacities = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (moment: Moment, timeSlots: TimeSlot[]) => {
        // timeSlotsは { startHour: number, endHour: number }[] 型
        const selectedSpotId = await snapshot.getPromise(spotSelectedIdState);

        const capacityTemplates: ApiCapacity[] = [];

        // 選択された時間帯ごとに配送枠を作成
        timeSlots.forEach(({ startHour, endHour }) => {
          // 何分ごとに作成するか
          const EVERY_MINUTES = CONST.NEXT_DELIVERY_SLOT_INTERVAL_MINUTES;
          // 空きの初期値
          const DEFAULT_FREE = 2;

          const startHours = Math.floor(startHour);
          const startMinutes = (startHour - startHours) * 60;
          const endHours = Math.floor(endHour);
          const endMinutes = (endHour - endHours) * 60;

          // いくつ配送枠を作成するか
          const capacityCount =
            (endHours * 60 + endMinutes - (startHours * 60 + startMinutes)) / EVERY_MINUTES;

          for (let index = 0; index < capacityCount; index++) {
            if (index & 1) {
              // 1枠飛ばし
              continue;
            }
            const momentClone = moment.clone();
            capacityTemplates.push({
              spotId: selectedSpotId,
              deliveryTimeBegin: momentClone
                .hour(startHours)
                .minute(startMinutes)
                .second(0)
                .add(index * EVERY_MINUTES, 'minute')
                .unix(),
              free: DEFAULT_FREE,
            });
          }
        });

        await api.BatchUpsertCapacity(capacityTemplates);
        set(capacityState(selectedSpotId), (currentCapacities) => {
          return [...currentCapacities, ...capacityTemplates];
        });
      },
    []
  );

const useBatchDeleteCapacity = () =>
  useRecoilCallback(({ set, snapshot }) => async (capacities: { deliveryTimeBegin: number }[]) => {
    const selectedSpotId = await snapshot.getPromise(spotSelectedIdState);
    const { userName } = await snapshot.getPromise(cognitoUserState);
    await api.BatchDeleteCapacity(selectedSpotId, capacities);
    set(capacityState(selectedSpotId), (currentCapacities) => {
      const deliveryTimeBegins = capacities.map((capacity) => capacity.deliveryTimeBegin);
      return currentCapacities.map((capacity) => {
        if (deliveryTimeBegins.includes(capacity.deliveryTimeBegin)) {
          return { ...capacity, TTL: moment().unix(), removedBy: userName };
        }

        return capacity;
      });
    });
  });

const useCapacityRefresh = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (spotId: string) => {
        const updatedCapacities = await api.FetchCapacities(spotId);
        set(capacityState(spotId), updatedCapacities);
      },
    []
  );

export const dataCapacity = {
  useCapacity: (spotId: string) => useRecoilValue(capacityState(spotId)),
  useCapacityByDate: () => useRecoilValue(capacitiesByDate),
  useUpsertCapacity,
  useBatchUpsertCapacity,
  useBatchDeleteCapacity,
  useCreateCapacities,
  useCapacityRefresh,
};
