import { Auth } from 'aws-amplify';
import { atom, selector, useRecoilValue } from 'recoil';
import { RecoilKeys } from './RecoilKeys';

type CognitoUserInfo = {
  userName: string;
  email: string;
  groups: string[];
};

export const cognitoUserState = atom<CognitoUserInfo>({
  key: RecoilKeys.COGNITO_USER_STATE,
  effects: [
    ({ setSelf, onSet, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          const user = await Auth.currentAuthenticatedUser();

          setSelf({
            userName: user.username,
            email: user.attributes.email,
            groups: user.signInUserSession.accessToken.payload['cognito:groups'] ?? [],
          });
        };
        initialize();
      }
    },
  ],
});

const isDeveloper = selector<boolean>({
  key: RecoilKeys.IS_DEVELOPER,
  get: ({ get }) => {
    const cognitoUser = get(cognitoUserState);
    return cognitoUser.groups.includes('Developer');
  },
});

const isAdministrator = selector<boolean>({
  key: RecoilKeys.IS_ADMINISTRATOR,
  get: ({ get }) => {
    const cognitoUser = get(cognitoUserState);
    return cognitoUser.groups.includes('Administrator');
  },
});

export const dataCognitoUser = {
  useIsDeveloper: () => useRecoilValue(isDeveloper),
  useIsAdministrator: () => useRecoilValue(isAdministrator),
};
