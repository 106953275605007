import moment from 'moment';
import { atomFamily, selector, useRecoilCallback, useRecoilValue } from 'recoil';
import { CONST } from '@common/global';
import { api, ApiCapacity } from './Api';
import { RecoilKeys } from './RecoilKeys';
import { spotSelectedIdState } from './Spot';

// すべての配達可能日（複数日）
const userCapacityState = atomFamily<ApiCapacity[], string>({
  key: RecoilKeys.USER_CAPACITY_STATE,
  default: [],
  effects: (spotId: string) => [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          const capacities = await api.FetchUserCapacities(spotId);
          setSelf(capacities);
        };
        initialize();
      }
    },
  ],
});

export type Capacity = {
  spotId: string;
  deliveryTimeBegin: number;
  beginTime: string;
  endTime: string;
  free: number;
  TTL?: number;
  removedBy?: string;
};

const useUserCapacityRefresh = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (spotId: string) => {
        const updatedUserCapacities = await api.FetchUserCapacities(spotId);
        set(userCapacityState(spotId), updatedUserCapacities);
      },
    []
  );

type CapacitiesByDate = {
  [date: string]: Capacity[];
};

const userCapacitiesByDate = selector<CapacitiesByDate>({
  key: RecoilKeys.USER_CAPACITIES_BY_DATE,
  get: ({ get }) => {
    const spotSelectedId = get(spotSelectedIdState);
    const capacities = get(userCapacityState(spotSelectedId));

    return capacities.reduce((result: CapacitiesByDate, currentValue) => {
      const beginMoment = moment.unix(currentValue.deliveryTimeBegin).utcOffset(+9);
      const endMoment = beginMoment
        .clone()
        .add(CONST.NEXT_DELIVERY_SLOT_INTERVAL_MINUTES, 'minute');
      const yyyymmddString = beginMoment.format('YYYY-MM-DD');

      if (!result[yyyymmddString]) {
        result[yyyymmddString] = [];
      }

      result[yyyymmddString].push({
        spotId: spotSelectedId,
        deliveryTimeBegin: currentValue.deliveryTimeBegin,
        beginTime: beginMoment.format('HH:mm'),
        endTime: endMoment.format('HH:mm'),
        free: currentValue.free,
        TTL: currentValue.TTL,
        removedBy: currentValue.removedBy,
      });

      return result;
    }, {});
  },
});

export const dataUserCapacity = {
  useUserCapacity: (spotId: string) => useRecoilValue(userCapacityState(spotId)),
  useUserCapacityByDate: () => useRecoilValue(userCapacitiesByDate),
  useUserCapacityRefresh,
};
