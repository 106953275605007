import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { Suspense, useEffect, useState } from 'react';
import { dataShop } from '../data/Shop';
import {
  Unit,
  aggregateItems,
  aggregateOrders,
  aggregateDailySales,
  aggregateMonthlySales,
  dataAggregate,
  aggregateDailyUsers,
  aggregateMonthlyUsers,
} from '../data/Aggregate';
import { DialogOk } from '../utils/Dialog';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const EXPORT_TARGET = {
  TOTAL_SALES: 'totalSales',
  TOTAL_ITEMS: 'totalItems',
  COMPLETED_ORDERS: 'completedOrders',
  TOTAL_USERS: 'totalUsers',
};

const GRAPH_TARGET = {
  SALE_AND_ORDER: 'saleAndOrder',
  SALE: 'sale',
  ORDER: 'order',
  USER: 'user',
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const Aggregate = () => {
  const [shopId] = dataShop.useShopSelectedId();
  const [value, setValue] = useState(0);
  const [isOpenBackDrop, setIsOpenBackDrop] = useState(false);
  const [openSettingErrorDialog, setSettingErrorDialog] = useState(false);
  const setDailyGraphDataSales = dataAggregate.useSetDailyGraphDataSales();
  const setDailyGraphDataUsers = dataAggregate.useSetDailyGraphDataUsers();

  // 日単位の出力
  const dailyExportCallback = async (
    dateFrom: Moment | null,
    dateTo: Moment | null,
    exportTarget: string
  ) => {
    if (!dateFrom || !dateTo) {
      setSettingErrorDialog(true);
      return;
    }
    setIsOpenBackDrop(true);
    if (exportTarget === EXPORT_TARGET.TOTAL_SALES) {
      await aggregateDailySales({ shopId, dateFrom, dateTo });
    } else if (exportTarget === EXPORT_TARGET.TOTAL_ITEMS) {
      await aggregateItems({ shopId, dateFrom, dateTo });
    } else if (exportTarget === EXPORT_TARGET.COMPLETED_ORDERS) {
      await aggregateOrders({ shopId, dateFrom, dateTo });
    } else if (exportTarget === EXPORT_TARGET.TOTAL_USERS) {
      await aggregateDailyUsers({ dateFrom, dateTo });
    }

    setIsOpenBackDrop(false);
  };

  // 月単位の出力
  const monthlyExportCallback = async (exportTarget: string) => {
    setIsOpenBackDrop(true);
    // 月単位の出力
    if (exportTarget === EXPORT_TARGET.TOTAL_SALES) {
      await aggregateMonthlySales({ shopId });
    } else if (exportTarget === EXPORT_TARGET.TOTAL_USERS) {
      await aggregateMonthlyUsers();
    }

    setIsOpenBackDrop(false);
  };

  const dailyGraphDataCallback = (
    target: string,
    dateFrom: Moment | null,
    dateTo: Moment | null
  ) => {
    if (!dateFrom || !dateTo) {
      return;
    }

    // グラフの描画に必要なデータを取得
    setDailyGraphDataUsers(dateFrom, dateTo);
    setDailyGraphDataSales(dateFrom, dateTo);
  };

  return (
    <div className="flex flex-col items-center mt-4">
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpenBackDrop}>
        <CircularProgress />
      </Backdrop>
      <DialogOk
        open={openSettingErrorDialog}
        title={'エラー 正しく設定されていない項目があります。'}
        message={'出力項目、開始日、終了日を正しく設定してください。'}
        callbackOk={async () => {
          setSettingErrorDialog(false);
        }}
      />
      <div>
        <p>総売上や、注文回数、ユーザー数などを出力できます。</p>
      </div>
      <div className="mt-4 border-b-2 border-b-gray-100 w-full text-center">
        <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} centered>
          <Tab label="グラフ" value={0} />
          <Tab label="CSV" value={1} />
        </Tabs>
      </div>
      {value === 0 && <ExportGraph dailyGraphDataCallback={dailyGraphDataCallback} />}

      {value === 1 && (
        <ExportCsv
          dailyExportCallback={dailyExportCallback}
          monthlyExportCallback={monthlyExportCallback}
        />
      )}
    </div>
  );
};

const ExportGraph = ({
  dailyGraphDataCallback,
}: {
  dailyGraphDataCallback: (target: string, dateFrom: Moment | null, dateTo: Moment | null) => void;
}) => {
  const GRAPH_TARGET_LIST = [
    { label: '売上と注文数', value: GRAPH_TARGET.SALE_AND_ORDER },
    { label: '売上のみ', value: GRAPH_TARGET.SALE },
    { label: '注文数のみ', value: GRAPH_TARGET.ORDER },
    { label: '新規ユーザ数', value: GRAPH_TARGET.USER },
  ];
  const [exportUnit, setExportUnit] = useState<Unit>('monthly');
  const [exportTarget, setExportTarget] = useState<string>(GRAPH_TARGET.SALE_AND_ORDER);
  const [dateFrom, setDateFrom] = useState<Moment | null>(null);
  const [dateTo, setDateTo] = useState<Moment | null>(null);
  const getDateToMaxDate = () => {
    if (!dateFrom) return moment();
    if (dateFrom) {
      if (dateFrom.clone().add(1, 'month') > moment()) {
        return moment();
      }
      return dateFrom.clone().add(1, 'month');
    }
  };

  const isVisibleGraph = () => {
    if (exportUnit === 'monthly') return true;
    if (exportUnit === 'daily' && dateFrom && dateTo) return true;
    return false;
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="mt-4">
        <p>出力したい項目を選択するとグラフが出力されます。</p>
        <p>日別の場合は出力範囲を選択してください、最長1ヶ月分が出力できます。</p>
      </div>
      <div className="mt-2 flex justify-center gap-8">
        <FormControl variant="filled" sx={{ marginTop: 2 }}>
          <FormLabel id="unit-label">出力単位</FormLabel>
          <RadioGroup
            row
            value={exportUnit}
            onChange={(e) => setExportUnit(e.target.value as Unit)}
            sx={{ justifyContent: 'center' }}
          >
            <FormControlLabel value="monthly" control={<Radio />} label="月別" />
            <FormControlLabel value="daily" control={<Radio />} label="日別" />
          </RadioGroup>
        </FormControl>
        <FormControl variant="filled" sx={{ marginTop: 2 }}>
          <InputLabel id="selectLabel">出力項目</InputLabel>
          <Select
            labelId="selectLabel"
            value={exportTarget}
            onChange={(e) => {
              setExportTarget(e.target.value);
            }}
          >
            {GRAPH_TARGET_LIST.map((graphTarget, index) => (
              <MenuItem key={'menuItem' + index} value={graphTarget.value}>
                {graphTarget.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {exportUnit === 'daily' && (
        <div className="flex items-center mt-4">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="開始日を選択"
              format="YYYY-MM-DD"
              views={['year', 'month', 'day']}
              minDate={dateTo ? dateTo.clone().add(-1, 'month') : null}
              maxDate={dateTo ?? moment().add(-1, 'day')}
              value={dateFrom}
              onChange={(newValue) => {
                setDateFrom(newValue);
                dailyGraphDataCallback(exportTarget, newValue, dateTo);
              }}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </LocalizationProvider>
          <p className="mx-2">〜</p>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="終了日を選択"
              format="YYYY-MM-DD"
              views={['year', 'month', 'day']}
              minDate={dateFrom}
              maxDate={getDateToMaxDate()}
              value={dateTo}
              onChange={(newValue) => {
                setDateTo(newValue);
                dailyGraphDataCallback(exportTarget, dateFrom, newValue);
              }}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </LocalizationProvider>
        </div>
      )}
      {isVisibleGraph() && (
        <Suspense
          fallback={
            <div className="flex justify-center mt-16">
              <CircularProgress />
            </div>
          }
        >
          <div className="mt-4">
            {exportTarget === GRAPH_TARGET.USER ? (
              <GraphUsers unit={exportUnit} />
            ) : (
              <GraphSales unit={exportUnit} target={exportTarget} />
            )}
          </div>
        </Suspense>
      )}
    </div>
  );
};

const GraphSales = ({ unit, target }: { unit: Unit; target: string }) => {
  const graphData = dataAggregate.useGraphDataSales(unit);
  const [dataSets, setDataSets] = useState<
    {
      label: string;
      yAxisID: string;
      data: number[];
      borderColor: string;
      backgroundColor: string;
    }[]
  >([]);
  const [scales, setScales] = useState<{
    sale?: { position: string };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    order?: { position: string; ticks: { callback: (label: any) => any } };
  }>({});

  useEffect(() => {
    const salesData = {
      label: '売上',
      yAxisID: 'sale',
      data: graphData.sales,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    };
    const ordersData = {
      label: '注文数',
      yAxisID: 'order',
      data: graphData.orderCounts,
      borderColor: 'rgb(0, 230, 218)',
      backgroundColor: 'rgba(0, 230, 218, 0.5)',
    };
    // 出力対象によってグラフの表示を変更
    if (target === GRAPH_TARGET.SALE_AND_ORDER) {
      setDataSets([salesData, ordersData]);
      setScales({
        sale: { position: 'left' },
        order: {
          position: 'right',
          ticks: {
            callback: (label) => {
              if (Math.floor(label) === label) {
                return label;
              }
            },
          },
        },
      });
    }

    if (target === GRAPH_TARGET.SALE) {
      setDataSets([salesData]);
      setScales({
        sale: { position: 'right' },
      });
    }

    if (target === GRAPH_TARGET.ORDER) {
      setDataSets([ordersData]);
      setScales({
        order: {
          position: 'right',
          ticks: {
            callback: function (label) {
              if (Math.floor(label) === label) {
                return label;
              }
            },
          },
        },
      });
    }
  }, [target, unit]);

  const data = {
    labels: graphData.labels,
    datasets: dataSets,
  };

  return (
    <Line
      height={500}
      width={500}
      data={data}
      options={{
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: '売上と注文数の推移',
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        scales: scales as any,
      }}
    />
  );
};

const GraphUsers = ({ unit }: { unit: Unit }) => {
  const graphData = dataAggregate.useGraphDataUsers(unit);
  const [dataSets, setDataSets] = useState<
    {
      label: string;
      yAxisID: string;
      data: number[];
      borderColor: string;
      backgroundColor: string;
    }[]
  >([]);

  useEffect(() => {
    const usersData = {
      label: '新規ユーザ数',
      yAxisID: 'user',
      data: graphData.userCounts,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    };

    setDataSets([usersData]);
  }, [unit]);

  const data = {
    labels: graphData.labels,
    datasets: dataSets,
  };

  return (
    <Line
      height={500}
      width={500}
      data={data}
      options={{
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: '新規ユーザ数の推移',
          },
        },
        scales: { user: { position: 'right' } },
      }}
    />
  );
};

const ExportCsv = ({
  dailyExportCallback,
  monthlyExportCallback,
}: {
  dailyExportCallback: (
    dateFrom: Moment | null,
    dateTo: Moment | null,
    exportTarget: string
  ) => void;
  monthlyExportCallback: (exportTarget: string) => void;
}) => {
  const [exportUnit, setExportUnit] = useState<Unit>('monthly');

  return (
    <>
      <div className="mt-4">
        <p>出力したい項目を選択し、出力ボタンをクリックするとcsvでダウンロードが行えます。</p>
        <p>日別の場合は出力範囲を選択してください、最長1ヶ月分が出力できます。</p>
      </div>
      <div className="mt-2">
        <RadioGroup
          row
          value={exportUnit}
          onChange={(e) => setExportUnit(e.target.value as Unit)}
          sx={{ justifyContent: 'center' }}
        >
          <FormControlLabel value="monthly" control={<Radio />} label="月別" />
          <FormControlLabel value="daily" control={<Radio />} label="日別" />
        </RadioGroup>
      </div>
      {exportUnit === 'daily' && <ExportDaily dailyExportCallback={dailyExportCallback} />}
      {exportUnit === 'monthly' && <ExportMonthly monthlyExportCallback={monthlyExportCallback} />}
    </>
  );
};

const ExportDaily = ({
  dailyExportCallback,
}: {
  dailyExportCallback: (
    dateFrom: Moment | null,
    dateTo: Moment | null,
    exportTarget: string
  ) => void;
}) => {
  const EXPORT_TARGET_LIST = [
    { label: '1日単位の総売上(CSV)', value: EXPORT_TARGET.TOTAL_SALES },
    { label: '商品別の総売上(CSV)', value: EXPORT_TARGET.TOTAL_ITEMS },
    { label: '受取済注文一覧(CSV)', value: EXPORT_TARGET.COMPLETED_ORDERS },
    { label: '新規ユーザ数(CSV)', value: EXPORT_TARGET.TOTAL_USERS },
  ];
  const [exportTarget, setExportTarget] = useState<string>(EXPORT_TARGET.TOTAL_SALES);
  const [dateFrom, setDateFrom] = useState<Moment | null>(null);
  const [dateTo, setDateTo] = useState<Moment | null>(null);
  const getDateToMaxDate = () => {
    if (!dateFrom) return moment();
    if (dateFrom) {
      if (dateFrom.clone().add(1, 'month') > moment()) {
        return moment();
      }
      return dateFrom.clone().add(1, 'month');
    }
  };

  return (
    <div className="flex flex-col items-center">
      <FormControl variant="filled" sx={{ marginTop: 2 }}>
        <InputLabel id="selectLabel">出力項目</InputLabel>
        <Select
          labelId="selectLabel"
          value={exportTarget}
          onChange={(e) => {
            setExportTarget(e.target.value);
          }}
        >
          {EXPORT_TARGET_LIST.map((exportTargetObj, index) => (
            <MenuItem key={'menuItem' + index} value={exportTargetObj.value}>
              {exportTargetObj.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="flex items-center mt-4">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="開始日を選択"
            format="YYYY-MM-DD"
            views={['year', 'month', 'day']}
            minDate={dateTo ? dateTo.clone().add(-1, 'month') : null}
            maxDate={dateTo ?? moment().add(-1, 'day')}
            value={dateFrom}
            onChange={(newValue) => {
              setDateFrom(newValue);
            }}
            slotProps={{ textField: { variant: 'outlined' } }}
          />
        </LocalizationProvider>
        <p className="mx-2">〜</p>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="終了日を選択"
            format="YYYY-MM-DD"
            views={['year', 'month', 'day']}
            minDate={dateFrom}
            maxDate={getDateToMaxDate()}
            value={dateTo}
            onChange={(newValue) => {
              setDateTo(newValue);
            }}
            slotProps={{ textField: { variant: 'outlined' } }}
          />
        </LocalizationProvider>
      </div>

      <div className="mt-4">
        <Button
          variant="contained"
          onClick={() => dailyExportCallback(dateFrom, dateTo, exportTarget)}
        >
          出力
        </Button>
      </div>
    </div>
  );
};

const ExportMonthly = ({
  monthlyExportCallback,
}: {
  monthlyExportCallback: (exportTarget: string) => void;
}) => {
  const EXPORT_TARGET_LIST = [
    { label: '年間の月単位の総売上(CSV)', value: EXPORT_TARGET.TOTAL_SALES },
    { label: '新規ユーザ数(CSV)', value: EXPORT_TARGET.TOTAL_USERS },
  ];
  const [exportTarget, setExportTarget] = useState<string>(EXPORT_TARGET.TOTAL_SALES);

  return (
    <div className="flex flex-col items-center">
      <FormControl variant="filled" sx={{ marginTop: 2 }}>
        <InputLabel id="selectLabel">出力項目</InputLabel>
        <Select
          labelId="selectLabel"
          value={exportTarget}
          onChange={(e) => {
            setExportTarget(e.target.value);
          }}
        >
          {EXPORT_TARGET_LIST.map((exportTargetObj, index) => (
            <MenuItem key={'menuItem' + index} value={exportTargetObj.value}>
              {exportTargetObj.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div className="mt-4">
        <Button variant="contained" onClick={() => monthlyExportCallback(exportTarget)}>
          出力
        </Button>
      </div>
    </div>
  );
};
