import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

// MUI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
// MUI icon
import AirplanemodeActiveRoundedIcon from '@mui/icons-material/AirplanemodeActiveRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import StoreRoundedIcon from '@mui/icons-material/StoreRounded';
import InboxIcon from '@mui/icons-material/Inbox';
import ListAltIcon from '@mui/icons-material/ListAlt';

// Local components
import { ReactComponent as Logo } from '@common/assets/logo-sorakarabin.svg';
import { theme } from './theme';
import { Menu as MyMenu } from '../menu/Menu';
import { Account } from '../account/Account';
import { OrderTodo, OrderOnDelivery } from '../order/Order';
import { data } from '../data/Data';
// import { api } from '../Data/Api';
import useLocationChange from '@common/hooks/UseLocationChange';
import { useRingToneSound } from '@common/data/SoundEffect';

// Amplify
import { Amplify, I18n } from 'aws-amplify';
import { Authenticator, translations } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';
import { ManagementItems } from '../management/ManagementItems';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  TextField,
} from '@mui/material';
import { ManagementMakers } from '../management/ManagementMakers';
import { ManagementCapacities } from '../management/ManagementCapacities';
import { ManagementDroneSlots } from '../management/ManagementDroneSlots';
import { ManagementUserCapacities } from '../management/ManagementUserCapacities';
import { BoxStateList } from '../box/BoxStateList';
import { ManagementCategories } from '../management/ManagementCategories';
import { dataShop } from '../data/Shop';
import { ManagementSpots } from '../management/ManagementSpots';
import { Aggregate } from '../aggregate/Aggregate';
import { ManagementShops } from '../management/ManagementShops';
import { ManagementUsers } from '../management/ManagementUsers';
import { ManagementAppMessages } from '../management/ManagementAppMessages';
import { ManagementAdminMail } from '../management/ManagementAdminMail';
import { OrderFailed } from '../order/OrderFailed';
import { OrderList } from '../order/OrderList';
import { OrderItemState, dataOrder } from '../data/Order';
import { Staff, dataStaff } from '../data/Staff';
import { StaffForm } from '../staff/Staff';
import { ManagementStaffs } from '../management/ManagementStaffs';
import { DeliveryTime } from '../data/OrderDeliveryTime';
import { useJudgeSize } from '@common/utils/JudgeSize';

Amplify.configure(awsExports);
I18n.putVocabularies(translations);
I18n.setLanguage('ja');

const formFields = {
  forceNewPassword: {
    phone_number: {
      dialCode: '+81',
    },
  },
};

export default function App() {
  if (!process.env.REACT_APP_API_ENDPOINT) {
    console.error('REACT_APP_API_ENDPOINTが設定されていません.');
  }

  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <Authenticator formFields={formFields} hideSignUp={true}>
          {({ signOut, user }) => (
            <Suspense
              fallback={
                <div className="w-full h-screen flex justify-center items-center">
                  <CircularProgress />
                </div>
              }
            >
              <App2 />
            </Suspense>
          )}
        </Authenticator>
      </RecoilRoot>
    </ThemeProvider>
  );
}

// キャンセルになった注文がある場合はダイアログを表示する
const useItemCanceled = () => {
  const [orderItemsCanceledState, setOrderItemsCanceledState] = useState<OrderItemState[]>([]);
  const [newOrderItemsCanceledState, setNewOrderItemsCanceledState] = useState<OrderItemState[]>(
    []
  );
  const orderItemsCanceled = dataOrder.useOrderItemsCanceled();
  const [openCanceledDialog, setOpenCanceledDialog] = useState(false);

  useEffect(() => {
    // stateが空の場合はキャンセル済みの注文をセット
    if (orderItemsCanceledState.length === 0) {
      setOrderItemsCanceledState(orderItemsCanceled);
      return;
    }
    // stateと更新されたキャンセル済みの注文が異なる場合は、差分の注文をセット
    if (orderItemsCanceledState.length !== orderItemsCanceled.length) {
      const newOrderItemsCanceled = orderItemsCanceled.filter((orderItem) => {
        return !orderItemsCanceledState.some((orderItemState) => {
          return orderItem.orderId === orderItemState.orderId;
        });
      });
      setNewOrderItemsCanceledState([...newOrderItemsCanceledState, ...newOrderItemsCanceled]);
      setOrderItemsCanceledState(orderItemsCanceled);
      setOpenCanceledDialog(true);
    }
    return;
  }, [orderItemsCanceled]);

  return {
    openCanceledDialog,
    setOpenCanceledDialog,
    newOrderItemsCanceledState,
    setNewOrderItemsCanceledState,
  };
};

// 配達時間が変更になった注文がある場合はダイアログを表示する
const useItemDeliveryTimeChanged = () => {
  const [orderItemsDeliveryTimeChanged, setOrderItemsDeliveryTimeChanged] = useState<
    (OrderItemState & { beforeDeliveryTime?: DeliveryTime })[]
  >([]);
  const [lastOrderItemsState, setLastOrderItemsState] = useState<OrderItemState[]>([]);
  const lastOrderItems = dataOrder.useOrderItemsFilteredByLastModified();
  const [openDeliveryTimeChangedDialog, setOpenDeliveryTimeChangedDialog] = useState(false);

  useEffect(() => {
    // stateが空の場合は注文をセット
    if (lastOrderItemsState.length === 0) {
      setLastOrderItemsState(lastOrderItems);
      return;
    }

    for (const lastOrderItem of lastOrderItems) {
      const lastOrderItemState = lastOrderItemsState.find((orderItemState) => {
        return orderItemState.orderId === lastOrderItem.orderId;
      });
      // stateに存在しない注文(新規注文)は追加してスキップ
      if (!lastOrderItemState) {
        setLastOrderItemsState([...lastOrderItemsState, lastOrderItem]);
        continue;
      }

      // 複数回配達時間が変更された注文
      const multipleChangeOrder = orderItemsDeliveryTimeChanged.find((orderItem) => {
        return orderItem.orderId === lastOrderItem.orderId;
      });

      // 複数回配達時間が変更された注文をセット
      if (
        multipleChangeOrder &&
        lastOrderItem.deliveryTime?.beginUnixTime !== lastOrderItemState.deliveryTime?.beginUnixTime
      ) {
        // 既にセットされている注文を更新
        setOrderItemsDeliveryTimeChanged(
          orderItemsDeliveryTimeChanged.map((orderItemDeliveryTimeChanged) => {
            if (orderItemDeliveryTimeChanged.orderId === lastOrderItem.orderId) {
              return {
                ...lastOrderItem,
                beforeDeliveryTime: orderItemDeliveryTimeChanged.deliveryTime,
              };
            }
            return orderItemDeliveryTimeChanged;
          })
        );
        // stateを最新に更新
        setLastOrderItemsState(
          lastOrderItemsState.map((orderItemState) => {
            if (orderItemState.orderId === lastOrderItem.orderId) {
              return lastOrderItem;
            }
            return orderItemState;
          })
        );
      }

      // 配達時間が変更された注文をセット
      if (
        !multipleChangeOrder &&
        lastOrderItemState.deliveryTime?.beginUnixTime !== lastOrderItem.deliveryTime?.beginUnixTime
      ) {
        setOrderItemsDeliveryTimeChanged([
          ...orderItemsDeliveryTimeChanged,
          { ...lastOrderItem, beforeDeliveryTime: lastOrderItemState.deliveryTime },
        ]);
        // stateを最新に更新
        setLastOrderItemsState(
          lastOrderItemsState.map((orderItemState) => {
            if (orderItemState.orderId === lastOrderItem.orderId) {
              return lastOrderItem;
            }
            return orderItemState;
          })
        );
      }
    }

    // 配達時間が変更された注文がある場合はダイアログを表示する
    if (orderItemsDeliveryTimeChanged.length > 0) {
      setOpenDeliveryTimeChangedDialog(true);
    }

    return;
  }, [lastOrderItems]);

  return {
    openDeliveryTimeChangedDialog,
    setOpenDeliveryTimeChangedDialog,
    orderItemsDeliveryTimeChanged,
    setOrderItemsDeliveryTimeChanged,
  };
};

function App2() {
  const [openStaffFormDialog, setOpenStaffFormDialog] = useState(false);
  const isNotRegistStaff = dataStaff.useIsNotRegistStaff();
  useEffect(() => {
    if (isNotRegistStaff) {
      setOpenStaffFormDialog(true);
    }
  }, [isNotRegistStaff]);

  // キャンセルになった注文に関するhooks
  const {
    openCanceledDialog,
    setOpenCanceledDialog,
    newOrderItemsCanceledState,
    setNewOrderItemsCanceledState,
  } = useItemCanceled();
  // 配達時間が変更になった注文に関するhooks
  const {
    openDeliveryTimeChangedDialog,
    setOpenDeliveryTimeChangedDialog,
    orderItemsDeliveryTimeChanged,
    setOrderItemsDeliveryTimeChanged,
  } = useItemDeliveryTimeChanged();

  // py:7 は Header, MyBottomNavigation に隠れる部分

  return (
    <BrowserRouter>
      <MyCancelOrdersDialog
        closeCallback={() => {
          setOpenCanceledDialog(false);
          setNewOrderItemsCanceledState([]);
        }}
        open={openCanceledDialog}
        orderItems={newOrderItemsCanceledState}
      />
      <DeliveryTimeChangedDialog
        closeCallback={() => {
          setOpenDeliveryTimeChangedDialog(false);
          setOrderItemsDeliveryTimeChanged([]);
        }}
        open={openDeliveryTimeChangedDialog}
        orderItems={orderItemsDeliveryTimeChanged}
      />
      <StaffFormDialog
        open={openStaffFormDialog}
        closeCallback={() => {
          setOpenStaffFormDialog(false);
        }}
      />
      <Box sx={{ py: 7 }} className="overflow-hidden">
        <Header />
        {/* <TopMessage /> */}
        <Suspense
          fallback={
            <div className="w-full h-screen flex justify-center items-center">
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route index element={<OrderTodo />} />
            <Route path="order">
              <Route path="todo" element={<OrderTodo />} />
              <Route path="on_delivery" element={<OrderOnDelivery />} />
              <Route path="failed" element={<OrderFailed />} />
              <Route path="list" element={<OrderList />} />
            </Route>
            <Route path="menu" element={<MyMenu />} />
            <Route path="account" element={<Account />} />
            <Route path="staff" element={<StaffForm />} />
            <Route path="aggregate" element={<Aggregate />} />
            <Route path="management">
              <Route path="items" element={<ManagementItems />} />
              <Route path="makers" element={<ManagementMakers />} />
              <Route path="capacities" element={<ManagementCapacities />} />
              <Route path="drone-slots" element={<ManagementDroneSlots />} />
              <Route path="user-capacities" element={<ManagementUserCapacities />} />
              <Route path="categories" element={<ManagementCategories />} />
              <Route path="spots" element={<ManagementSpots />} />
              <Route path="shops" element={<ManagementShops />} />
              <Route path="users" element={<ManagementUsers />} />
              <Route path="staffs" element={<ManagementStaffs />} />
              <Route path="appMessages" element={<ManagementAppMessages />} />
              <Route path="AdminMail" element={<ManagementAdminMail />} />
            </Route>
            <Route path="box">
              <Route path="list" element={<BoxStateList />} />
            </Route>
            <Route path="*" element={<></>} />
          </Routes>
          <MyBottomNavigation />
        </Suspense>
      </Box>
    </BrowserRouter>
  );
}

function Header() {
  const navigate = useNavigate();
  const selectedShop = dataShop.useShopSelected();
  const [isOpenShopSelectDialog, setIsOpenShopSelectDialog] = useState(false);

  if (process.env.REACT_APP_LOCAL_TEST !== '1') {
    const CheckServiceWorkerUpdate = () => {
      useLocationChange((location) => {
        // check for sw updates on page change
        navigator.serviceWorker
          .getRegistrations()
          .then((regs) => regs.forEach((reg) => reg.update()));
      });
      return <div>Sample Component</div>;
    };

    CheckServiceWorkerUpdate();
  }

  return (
    <AppBar position="fixed" color="inherit" elevation={2}>
      <ShopSelectDialog
        isOpen={isOpenShopSelectDialog}
        closeCallback={() => setIsOpenShopSelectDialog(false)}
      />

      <Toolbar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ flexGrow: 1 }}
          className="flex mx-auto"
        >
          <IconButton
            aria-label="back button"
            color="primary"
            className="flex-1"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <Box sx={{ color: 'primary.main' }} className="flex-1 justify-center">
            <Logo fill="currentColor" className="h-10 flex container mx-auto pr-6" />
          </Box>

          <IconButton
            aria-label="menu button"
            color="primary"
            className="flex-1"
            onClick={() => navigate('/menu')}
          >
            <MenuRoundedIcon />
          </IconButton>
          <Box>
            <Button variant="contained" onClick={() => setIsOpenShopSelectDialog(true)}>
              {selectedShop.shopName}
            </Button>
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

const MyCancelOrdersDialog = ({
  open,
  closeCallback,
  orderItems,
}: {
  open: boolean;
  closeCallback: () => void;
  orderItems: OrderItemState[];
}) => {
  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>キャンセルされた注文</DialogTitle>
      <DialogContent>
        <div className="ml-2">注文がキャンセルされました</div>
        <div className="flex flex-col px-4 overflow-y-auto">
          {orderItems.map((orderItem) => {
            return (
              <Paper key={orderItem.orderId} className="my-2 p-2">
                <p className="text-sm">受付番号：{orderItem.receiptNumber}</p>
                <p className="text-sm">配送時間：{orderItem.deliveryTime?.toStringTime('〜')}</p>
                <div className="mt-2 ml-8">
                  <p className="text-sm font-bold">注文内容：</p>
                  <div className="ml-2 grid grid-cols-1 md:grid-cols-4 gap-2">
                    {orderItem.items.map((item) => {
                      return (
                        <p key={item.itemId} className="text-sm font-bold">
                          {item.name} {item.count}個
                        </p>
                      );
                    })}
                  </div>
                </div>
              </Paper>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeCallback}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
};

const DeliveryTimeChangedDialog = ({
  open,
  closeCallback,
  orderItems,
}: {
  open: boolean;
  closeCallback: () => void;
  orderItems: (OrderItemState & { beforeDeliveryTime?: DeliveryTime })[];
}) => {
  const isSp = useJudgeSize();
  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>配達時間変更通知</DialogTitle>
      <DialogContent>
        <div className="ml-2">
          注文の配送時間が
          {isSp && <br />}
          変更されました
        </div>
        <div className="flex flex-col px-4 overflow-y-auto">
          {orderItems.map((orderItem) => {
            return (
              <Paper key={orderItem.orderId} className="my-2 p-2">
                <p className="text-sm">受付番号：{orderItem.receiptNumber}</p>
                <div className="text-sm">
                  配送時間：
                  <br />
                  <p className="ml-2 font-bold">
                    変更前 {orderItem.beforeDeliveryTime?.toStringTime('〜')}
                  </p>
                  <p className="ml-2 font-bold">
                    変更後 {orderItem.deliveryTime?.toStringTime('〜')}
                  </p>
                </div>
                <div className="mt-2 ml-2">
                  <p className="text-sm font-bold">注文内容：</p>
                  <div className="ml-2 grid grid-cols-1 md:grid-cols-4 gap-2">
                    {orderItem.items.map((item) => {
                      return (
                        <p key={item.itemId} className="text-sm font-bold">
                          {item.name} {item.count}個
                        </p>
                      );
                    })}
                  </div>
                </div>
              </Paper>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeCallback}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
};

const StaffFormDialog = ({ open, closeCallback }: { open: boolean; closeCallback: () => void }) => {
  const [staff, setStaff] = useState<Staff>({
    staffName: '',
    phoneNumber: '',
  });
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>('');
  const [processing, setProcessing] = useState(false);
  const upsertStaff = dataStaff.useUpsertStaff();

  const submitCallback = async () => {
    let isError = false;
    setNameErrorMessage('');
    setPhoneErrorMessage('');

    if (staff.staffName === '') {
      isError = true;
      setNameErrorMessage('表示名を入力してください');
    }
    if (staff.phoneNumber === '') {
      isError = true;
      setPhoneErrorMessage('携帯電話番号を入力してください');
    }
    // TODO: 一旦携帯電話番号のバリデーションにしているが、後で変更する
    if (!staff.phoneNumber.match(/^0\d{1}0\d{4}\d{4}$/)) {
      isError = true;
      setPhoneErrorMessage('携帯電話番号の形式が正しくありません');
    }

    if (isError) {
      return;
    }
    setProcessing(true);
    await upsertStaff(staff);
    setProcessing(false);
    closeCallback();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>担当者表示名の登録</DialogTitle>
      <DialogContent>
        <div className="mb-2 ml-2">担当者表示名の登録が必要です</div>
        <div className="flex flex-col gap-4 px-4 items-center">
          <TextField
            label="表示名"
            variant="standard"
            size="medium"
            className="w-full"
            value={staff.staffName}
            onChange={(e) => {
              setStaff({ ...staff, staffName: e.target.value });
            }}
            error={Boolean(nameErrorMessage)}
            helperText={nameErrorMessage}
          />
          <TextField
            label="携帯電話番号(ハイフンなし)"
            variant="standard"
            size="medium"
            className="w-full"
            value={staff.phoneNumber}
            onChange={(e) => {
              setStaff({ ...staff, phoneNumber: e.target.value });
            }}
            error={Boolean(phoneErrorMessage)}
            helperText={phoneErrorMessage}
          />

          <Button
            variant="contained"
            className="w-1/2"
            disabled={processing}
            onClick={submitCallback}
          >
            設定
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const ShopSelectDialog = ({
  isOpen,
  closeCallback,
}: {
  isOpen: boolean;
  closeCallback: () => void;
}) => {
  const shops = dataShop.useShopsValue();
  const [selectedShopId, setSelectedShopId] = dataShop.useShopSelectedId();

  return (
    <Dialog open={isOpen}>
      <DialogTitle>店舗の選択</DialogTitle>
      <DialogContent>
        <div className="mb-2 ml-2">管理する店舗を選択してください</div>
        <div className="flex flex-col gap-2 px-4">
          {shops.map((shop) => {
            return (
              <Button
                key={shop.shopId}
                variant="contained"
                disabled={shop.shopId === selectedShopId}
                onClick={() => {
                  setSelectedShopId(shop.shopId);
                  window.location.reload();
                }}
              >
                <p>{shop.shopName}</p>
              </Button>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={closeCallback} autoFocus>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MyBottomNavigation = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const ordersPlaced = data.order.useOrderItemsPlaced();
  const ordersAssigned = data.order.useOrderItemsAssigned();
  const ordersAccepted = data.order.useOrderItemsAccepted();
  const ordersOnDelivery = data.order.useOrderItemsOnDelivery();
  const ordersArrived = data.order.useOrderItemsArrived();
  const ordersPosted = data.order.useOrderItemsPosted();
  const playRingToneSound = useRingToneSound();

  const buttonSx: SxProps = {
    minWidth: '60px',
    maxWidth: '120px',
  };
  const buttonLabelStyle = { whiteSpace: 'noWrap', fontSize: '0.8em' };
  const [ordersPlacedLength, setOrdersPlacedLength] = useState(0);

  useEffect(() => {
    if (ordersPlacedLength < ordersPlaced.length) {
      playRingToneSound();
    }
    setOrdersPlacedLength(ordersPlaced.length);
  }, [ordersPlacedLength, ordersPlaced.length, playRingToneSound]);

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 100,
        width: '100%',
        overflowX: 'scroll',
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <BottomNavigationAction
          sx={buttonSx}
          label={<Typography sx={buttonLabelStyle}>Todo</Typography>}
          icon={
            <Badge
              badgeContent={ordersPlaced.length + ordersAssigned.length + ordersAccepted.length}
              color="warning"
            >
              <StoreRoundedIcon />
            </Badge>
          }
          onClick={() => navigate('/order/todo')}
        />

        <BottomNavigationAction
          sx={buttonSx}
          label={<Typography sx={buttonLabelStyle}>配送中</Typography>}
          icon={
            <Badge
              badgeContent={ordersOnDelivery.length + ordersArrived.length + ordersPosted.length}
              color="primary"
            >
              <AirplanemodeActiveRoundedIcon />
            </Badge>
          }
          onClick={() => navigate('/order/on_delivery')}
        />

        <BottomNavigationAction
          sx={buttonSx}
          label={<Typography sx={buttonLabelStyle}>注文一覧</Typography>}
          icon={<ListAltIcon />}
          onClick={() => navigate('/order/list')}
        />

        <BottomNavigationAction
          sx={buttonSx}
          label={<Typography sx={buttonLabelStyle}>Box</Typography>}
          icon={<InboxIcon />}
          onClick={() => navigate('/box/list')}
        />
      </BottomNavigation>
    </Paper>
  );
};
