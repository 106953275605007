import { useState } from 'react';
import { OrderItem, OrderItemState, dataOrder } from '../data/Order';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@mui/material';
import { dataItem } from '../data/Item';

export const OrderStockOutDialog = ({
  open,
  order,
  callbackOnClose,
}: {
  open: boolean;
  order: OrderItemState;
  callbackOnClose: () => void;
}) => {
  const [selectedItems, setSelectedItemsState] = useState<OrderItem[]>([]);
  const setOrderState = dataOrder.useSetOrderState();

  if (!order.items) return <></>;

  const callbackOnSave = () => {
    if (selectedItems.length === 0) return;
    setOrderState(order.orderId, 'waitingCustomerByStockOut', undefined, selectedItems);
    setSelectedItemsState([]);
    callbackOnClose();
  };
  const callbackOnSelectItem = (item: OrderItem) => {
    setSelectedItemsState([...selectedItems, item]);
  };

  return (
    <Dialog open={open} onClose={callbackOnClose}>
      <DialogTitle>欠品の連絡</DialogTitle>
      <DialogContent className="text-sm md:text-md">
        <p>欠品の連絡が行えます</p>
        <p>商品を選択し、連絡ボタンを押下してください</p>

        {order.items.map((item) => {
          return (
            <ItemDetail
              key={item.itemId}
              item={item}
              isSelected={selectedItems.some((selectedItem) => selectedItem.itemId === item.itemId)}
              callbackOnSelectItem={callbackOnSelectItem}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={selectedItems.length === 0} onClick={callbackOnSave}>
          連絡
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            callbackOnClose();
            setSelectedItemsState([]);
          }}
        >
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ItemDetail = ({
  item,
  isSelected,
  callbackOnSelectItem,
}: {
  item: OrderItem;
  isSelected: boolean;
  callbackOnSelectItem: (item: OrderItem) => void;
}) => {
  const shopItem = dataItem.useShopItemMaster(item.itemId)
    ? dataItem.useShopItemMaster(item.itemId)
    : '';
  if (!shopItem) return <></>;

  return (
    <Paper
      key={shopItem.itemId}
      variant="outlined"
      color="primary"
      className="mt-4 p-2 cursor-pointer"
      onClick={() => {
        callbackOnSelectItem(item);
      }}
    >
      <div className="flex gap-2 items-center">
        <img
          alt="商品画像"
          src={shopItem.image ? shopItem.image[0] : ''}
          className="rounded-l-md object-cover w-1/3"
        />
        <div className="flex items-center gap-2">
          <p>{shopItem.name}</p>
          {isSelected && <p className="w-fit p-2 ml-auto rounded-md bg-blue-300 ">選択中</p>}
        </div>
      </div>
    </Paper>
  );
};
