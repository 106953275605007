import React from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import QRCodeSVG from 'qrcode.react';

import { api } from '../data/Api';
import { FormControlLabel, Paper, Radio, RadioGroup, Skeleton } from '@mui/material';

type MFAProps = {
  readonly mfaState: string;
  readonly setSMS: () => void;
  readonly setTOTP: () => void;
};

export const Account = () => {
  //none, SMS_MFA, SOFTWARE_TOKEN_MFA
  const [mfaState, setMfaState] = React.useState<string>('none');

  const handleMFASMS = () => {
    api.SetMFA('sms').then((res) => {
      console.log(`SetMFA : ${res.PreferredMfaSetting}`);
      setMfaState(res.PreferredMfaSetting);
    });
  };
  const handleMFATOTP = () => {
    // verify済んでればTOTPになってる（してる）
    console.log('SetMFA : SOFTWARE_TOKEN_MFA');
    setMfaState('SOFTWARE_TOKEN_MFA');
  };

  const refFirstRef = React.useRef<boolean>(true);
  React.useEffect(() => {
    // 開発モードの2度呼び防止
    if (process.env.NODE_ENV === 'development') {
      if (refFirstRef.current) {
        refFirstRef.current = false;
        return;
      }
    }
    api.GetMFA().then((res) => {
      console.log(`GetMFA : ${res.PreferredMfaSetting}`);
      setMfaState(res.PreferredMfaSetting);
    });
  }, []);

  return (
    <React.Suspense
      fallback={
        <div className="w-full h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      }
    >
      <Account2 mfaState={mfaState} setSMS={handleMFASMS} setTOTP={handleMFATOTP} />
    </React.Suspense>
  );
};

export const Account2 = ({ mfaState, setSMS, setTOTP }: MFAProps) => {
  const handleMFASMS = setSMS;
  const handleMFATOTP = setTOTP;

  return (
    <Stack sx={{ paddingTop: 2 }} spacing={2} alignItems={'center'}>
      <Typography variant="h5" className="text-center">
        二段階認証設定
      </Typography>
      <Paper sx={{ width: 'auto', padding: 2 }}>
        <MFASettings mfaState={mfaState} setSMS={handleMFASMS} setTOTP={handleMFATOTP} />
      </Paper>
    </Stack>
  );
};

export const MFASettings = ({ mfaState, setSMS, setTOTP }: MFAProps) => {
  const [isTOTP, setIsTOTP] = React.useState<boolean>(false);
  const handleTOTPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'true') {
      setIsTOTP(true);
    } else {
      setIsTOTP(false);
      setSMS();
    }
  };

  const handleMFASMS = () => {
    setIsTOTP(false);
    setSMS();
  };
  const handleMFATOTP = () => {
    setTOTP();
  };

  const refFirstRef = React.useRef<boolean>(true);
  React.useEffect(() => {
    // 開発モードの2度呼び防止
    if (process.env.NODE_ENV === 'development') {
      if (refFirstRef.current) {
        refFirstRef.current = false;
        return;
      }
    }
    if (mfaState === 'none') {
      setSMS();
    }
  }, [mfaState, setSMS]);

  React.useEffect(() => {
    setIsTOTP(mfaState !== 'SMS_MFA');
  }, [mfaState]);

  return (
    <>
      <div className="mt-4">
        <Typography className="text-center" variant="h6">
          ※このアプリでは、二段階認証の設定が必ず必要です。
        </Typography>

        <div className="mt-2">
          <Typography>・ 二段階認証の方式を選択してください</Typography>
        </div>
        <div className="mt-2">
          <RadioGroup
            row
            value={isTOTP}
            onChange={handleTOTPChange}
            sx={{ justifyContent: 'center' }}
          >
            <FormControlLabel value={false} control={<Radio />} label="SMS" />
            <FormControlLabel value={true} control={<Radio />} label="TOTP" />
          </RadioGroup>
        </div>
      </div>
      {isTOTP ? (
        <TOTPSetup mfaState={mfaState} setSMS={handleMFASMS} setTOTP={handleMFATOTP} />
      ) : (
        <></>
      )}
    </>
  );
};

export const TOTPSetup = ({ mfaState, setSMS, setTOTP }: MFAProps) => {
  const [totpCode, setTotpCode] = React.useState<string>();
  const [secret, setSecret] = React.useState<string>();
  const [totpInput, setTotpInput] = React.useState<string>('');
  const [done, setDone] = React.useState<boolean>(mfaState === 'SOFTWARE_TOKEN_MFA');

  // const handleMFANone = setNone;
  const handleMFASMS = setSMS;
  const handleMFATOTP = () => {
    setDone(true);
    setTOTP();
  };

  const refFirstRef = React.useRef<boolean>(true);
  React.useEffect(() => {
    // 開発モードの2度呼び防止
    if (process.env.NODE_ENV === 'development') {
      if (refFirstRef.current) {
        refFirstRef.current = false;
        return;
      }
    }
    // TOTPのQRコード、シークレットコードを取得
    api.AssociateSoftwareToken().then((res) => {
      setSecret(res.secretCode);
      setTotpCode(res.totpCode);
    });
  }, []);

  const verify = () => {
    api
      .VerifySoftwareToken(totpInput)
      .then(() => {
        handleMFATOTP();
      })
      .catch((err) => {
        alert('TOTP設定エラー');
        handleMFASMS();
      });
  };

  return (
    <div className="mt-4">
      {!done ? (
        <>
          <Typography>・ 認証アプリでQRコードを読み込み、またはコードを入力してください</Typography>
          <div>
            {totpCode ? (
              <QRCodeSVG
                value={totpCode}
                style={{ width: 240, height: 'auto', margin: '0 auto' }}
                size={128}
                bgColor={'#ffffff'}
                fgColor={'#000000'}
                level={'L'}
                includeMargin={true}
                imageSettings={{
                  height: 480,
                  width: 480,
                  excavate: false,
                  src: '',
                  x: 0,
                  y: 0,
                }}
              />
            ) : (
              <Skeleton
                variant="rectangular"
                width={240}
                height={240}
                sx={{ marginX: 'auto' }}
                animation="wave"
              />
            )}
            <div className="mx-auto text-center">
              <Typography>コードを入力する場合はこちら</Typography>
              <TextField value={secret} multiline maxRows={4} sx={{ width: 300 }} disabled />
            </div>
          </div>
          <div className="mt-4">
            <Typography>・ 認証アプリで表示されるワンタイムパスワードを入力してください</Typography>
            <div className="mt-2 flex justify-center ">
              <TextField
                sx={{ zIndex: 0 }}
                label="TOTP Code"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onChange={(newValue) => setTotpInput(newValue.target.value)}
              />
              <Button sx={{ marginLeft: 1 }} variant="contained" onClick={() => verify()}>
                <span className="text-lg">Verify</span>
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="mt-4">
          <Typography>・TOTPの設定は完了しています</Typography>
        </div>
      )}
    </div>
  );
};
