import { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { OrderItemState } from '../data/Order';
import CloseIcon from '@mui/icons-material/Close';

export const DialogOrderInfo = ({
  open,
  order,
  callbackOnClose,
}: {
  open: boolean;
  order: OrderItemState;
  callbackOnClose: () => void;
}) => {
  const undefinedToString = (str?: string) => {
    if (!str) return '未設定';
    return str;
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Dialog open={open} onClose={callbackOnClose}>
      <div className="flex">
        <DialogTitle>注文詳細</DialogTitle>
        <div className="ml-auto pt-1">
          <IconButton size="large" onClick={callbackOnClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <DialogContent>
        <div className="grid grid-cols-2 gap-2 items-center">
          <p>注文ID: </p>
          <p>{undefinedToString(order.orderId)}</p>
          <p>配送先: </p>
          <p>{undefinedToString(order.deliveryTo)}</p>
          <p>支払い時間: </p>
          <p>{undefinedToString(order.timePaying?.toLocaleString())}</p>
          <p>支払い完了時間: </p>
          <p>{undefinedToString(order.timeCredited?.toLocaleString())}</p>
          <p>注文受理時間: </p>
          <p>{undefinedToString(order.timeAccepted?.toLocaleString())}</p>
          <p>注文失敗時間: </p>
          <p>{undefinedToString(order.timeFailed?.toLocaleString())}</p>
          <p>配送開始時間: </p>
          <p>{undefinedToString(order.timeOnDelivery?.toLocaleString())}</p>
          <p>到着時間: </p>
          <p>{undefinedToString(order.timeArrived?.toLocaleString())}</p>
          <p>投函時間: </p>
          <p>{undefinedToString(order.timePosted?.toLocaleString())}</p>
          <p>注文完了時間: </p>
          <p>{undefinedToString(order.timeCompleted?.toLocaleString())}</p>
          <p>キャンセル時間:</p>
          <p>{undefinedToString(order.timeCancelled?.toLocaleString())}</p>
          <p onClick={toggleIsOpen}>Order Object: {isOpen ? '▼' : '▶'}</p>
          {isOpen && <p>{JSON.stringify(order, null, 2)}</p>}
        </div>
      </DialogContent>
    </Dialog>
  );
};
