export class DeliveryTime {
  readonly beginUnixTime: number;
  readonly date: Date;
  readonly beginHour: number;
  readonly beginMinute: number;
  readonly endHour: number;
  readonly endMinute: number;

  constructor(
    beginUnixTime: number,
    year: number,
    month: number,
    date: number,
    beginHour: number,
    beginMinute: number,
    endHour: number,
    endMinute: number
  ) {
    this.beginUnixTime = beginUnixTime;
    this.date = new Date(year, month, date);
    this.beginHour = beginHour;
    this.beginMinute = beginMinute;
    this.endHour = endHour;
    this.endMinute = endMinute;
  }

  getTimeOfDate() {
    return this.date.getTime();
  }

  getStringDate() {
    const year = this.date.getFullYear();
    const month = this.date.getMonth() + 1;
    const date = this.date.getDate();
    return year + '-' + month + '-' + date;
  }

  getStringMonthDate() {
    const month = this.date.getMonth() + 1;
    const date = this.date.getDate();
    return month + '-' + date;
  }

  toStringTime(connector: string) {
    return (
      this.beginHour +
      ':' +
      ('0' + this.beginMinute).slice(-2) +
      connector +
      this.endHour +
      ':' +
      ('0' + this.endMinute).slice(-2)
    );
  }
}
