import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { Suspense, useEffect, useState } from 'react';
import { Category, dataCategory } from '../data/Category';
import { DialogOkCancel, DialogOk } from '@common/components/Dialog';
import { dataCognitoUser } from '../data/CognitoUser';
import moment from 'moment';
import { dataShop } from '../data/Shop';

export const ManagementCategories = () => {
  const categories = dataCategory.useCategory();
  const [isOpenSettingCategoryDialog, setIsOpenSettingCategoryDialog] = useState(false);
  const [isOpenImportCategoryDialog, setIsOpenImportCategoryDialog] = useState(false);
  const isDeveloper = dataCognitoUser.useIsDeveloper();

  return (
    <div className="pt-8 flex flex-col items-center gap-2">
      <SettingCategoryDialog
        isOpen={isOpenSettingCategoryDialog}
        closeCallback={() => setIsOpenSettingCategoryDialog(false)}
      />
      {isDeveloper && (
        <ImportCategoryDialog
          isOpen={isOpenImportCategoryDialog}
          closeCallback={() => {
            setIsOpenImportCategoryDialog(false);
          }}
        />
      )}
      <div className="mb-2 flex flex-col items-center">
        <p>カテゴリの新規作成・編集が行えます。</p>
        <div className="mt-2">
          <Button onClick={() => setIsOpenSettingCategoryDialog(true)} variant="contained">
            新規作成
          </Button>
          <Button
            onClick={() => {
              setIsOpenImportCategoryDialog(true);
            }}
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
          >
            他店舗からインポート
          </Button>
        </div>
      </div>
      <div className="grid responsive-grid gap-4">
        {categories.map((category) =>
          category.TTL && category.removedBy ? (
            <DeletedCategory key={category.categoryId} category={category} ttl={category.TTL} />
          ) : (
            <SettingCategory key={category.categoryId} category={category} />
          )
        )}
      </div>
    </div>
  );
};

const SettingCategory = ({ category }: { category: Category }) => {
  const isDeveloper = dataCognitoUser.useIsDeveloper();
  const deleteCategory = dataCategory.useCategoryDelete();
  const [isOpenSettingCategoryDialog, setIsOpenSettingCategoryDialog] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);

  return (
    <div key={category.categoryId} className="p-2 border">
      <SettingCategoryDialog
        isOpen={isOpenSettingCategoryDialog}
        category={category}
        closeCallback={() => setIsOpenSettingCategoryDialog(false)}
      />
      {isDeveloper && (
        <DialogOkCancel
          open={isOpenConfirmDialog}
          title={''}
          message={category.categoryName + 'を削除しますか？'}
          callbackOk={async () => {
            await deleteCategory(category.categoryId);
            setIsOpenConfirmDialog(false);
          }}
          callbackCancel={() => setIsOpenConfirmDialog(false)}
        />
      )}
      <p className="font-bold text-lg">{category.categoryName}</p>
      <p className="text-sm my-2">
        <span>ID：{category.categoryId}</span>
      </p>
      <p className="text-sm my-2">
        {category.visible ? <span>表示</span> : <span className="text-gray-400">非表示</span>}
      </p>
      <div className="flex gap-2 justify-end">
        <Button variant="contained" onClick={() => setIsOpenSettingCategoryDialog(true)}>
          編集
        </Button>
        {isDeveloper && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsOpenConfirmDialog(true)}
          >
            削除
          </Button>
        )}
      </div>
    </div>
  );
};

const DeletedCategory = ({ category, ttl }: { category: Category; ttl: number }) => {
  const isDeveloper = dataCognitoUser.useIsDeveloper();

  return (
    <div key={category.categoryId} className="p-2 border">
      <p className="font-bold text-lg">{category.categoryName}</p>
      <p className="text-md font-bold my-2">削除実行済み</p>
      <p className="text-sm my-2">
        削除予定時間：
        <br />
        {moment.unix(ttl).utcOffset(+9).format('YYYY-MM-DD H:mm:ss')}
        {' 〜 '}
        {moment.unix(ttl).utcOffset(+10).format('YYYY-MM-DD H:mm:ss')}
      </p>
      <p className="text-sm my-2">
        <span>ID：{category.categoryId}</span>
      </p>
      <div className="flex gap-2 justify-end">
        <Button variant="contained" disabled>
          編集
        </Button>
        {isDeveloper && (
          <Button variant="contained" color="secondary" disabled>
            削除
          </Button>
        )}
      </div>
    </div>
  );
};

const checkCategoryValidity = (category: Category): string => {
  let errorMessage = '';
  if (category.categoryName.length === 0) {
    errorMessage = 'カテゴリー名が空です。カテゴリー名は必ず設定する必要があります。';
  } else if (category.categoryName.length !== category.categoryName.trimStart().length) {
    errorMessage = 'カテゴリー名の先頭にスペースが含まれています。';
  }

  return errorMessage;
};

const SettingCategoryDialog = ({
  isOpen,
  category,
  closeCallback,
}: {
  isOpen: boolean;
  category?: Category;
  closeCallback: () => void;
}) => {
  const [openSettingErrorDialogMessage, setSettingErrorDialogMessage] = useState('');
  const [openSettingErrorDialog, setSettingErrorDialog] = useState(false);
  const [isExecution, setIsExecution] = useState(false);
  const defaultCategory = {
    categoryId: '',
    categoryName: '',
    priority: 0,
    visible: true,
  };
  const [categoryData, setCategoryData] = useState<Category>(
    category
      ? {
          categoryId: category.categoryId,
          categoryName: category.categoryName,
          priority: category.priority,
          visible: category.visible,
          TTL: category.TTL,
        }
      : defaultCategory
  );
  const categoryUpsert = dataCategory.useCategoryUpsert();
  const buttonClickCallback = async () => {
    const errorMessage = checkCategoryValidity(categoryData);
    if (errorMessage.length !== 0) {
      setSettingErrorDialogMessage(errorMessage);
      setSettingErrorDialog(true);
      return;
    }

    setIsExecution(true);
    await categoryUpsert(categoryData);

    if (!category) {
      setCategoryData(defaultCategory);
    }

    closeCallback();
    setIsExecution(false);
  };
  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>カテゴリー設定</DialogTitle>
      <DialogContent>
        <div className="mb-4 ml-4">
          カテゴリーの設定を編集できます。
          <br />
          情報を編集し、{categoryData.categoryId ? '更新' : '新規作成'}
          ボタンをクリックしてください。
        </div>
        <div className="overflow-y-auto pt-4 flex flex-col gap-4">
          <FormControlLabel
            className="pl-4"
            control={
              <Checkbox
                checked={categoryData.visible}
                onChange={(e) => {
                  setCategoryData({ ...categoryData, visible: e.target.checked });
                }}
              />
            }
            label="表示する"
          />
          <TextField
            label="カテゴリー名"
            className="w-full"
            value={categoryData.categoryName}
            onChange={(e) => setCategoryData({ ...categoryData, categoryName: e.target.value })}
          />
          <TextField
            label="表示順"
            className="w-full"
            type="number"
            value={Number(categoryData.priority ?? 0)}
            onChange={(e) => setCategoryData({ ...categoryData, priority: Number(e.target.value) })}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="mt-auto"
          disabled={isExecution}
          onClick={buttonClickCallback}
        >
          {categoryData.categoryId ? '更新' : '新規作成'}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          disabled={isExecution}
          onClick={() => {
            closeCallback();
            // MEMO: キャンセル時に記述をクリアする
            if (category) {
              setCategoryData(category);
              return;
            }
            setCategoryData(defaultCategory);
          }}
        >
          キャンセル
        </Button>
      </DialogActions>
      <DialogOk
        open={openSettingErrorDialog}
        title={'エラー 正しく設定されていない項目があります。'}
        message={openSettingErrorDialogMessage}
        callbackOk={async () => {
          setSettingErrorDialog(false);
        }}
      />
    </Dialog>
  );
};

const ImportCategoryDialog = ({
  isOpen,
  closeCallback,
}: {
  isOpen: boolean;
  closeCallback: () => void;
}) => {
  const shops = dataShop.useShopsValue();
  const [selectedShopId] = dataShop.useShopSelectedId();
  const importCategory = dataCategory.useCategoryImport();
  const [processing, setProcessing] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const importCallback = async () => {
    setProcessing(true);
    await importCategory({ fromShopId: value });
    setProcessing(false);
    closeCallback();
  };
  useEffect(() => {
    const otherShops = shops.filter((shop) => shop.shopId !== selectedShopId);
    setValue(otherShops[0].shopId);
  }, [shops]);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>カテゴリーを他店舗からインポート</DialogTitle>
      <DialogContent>
        <div className="mb-4 ml-4">
          カテゴリーを他店舗からインポートできます。
          <br />
          インポートしたい店舗を選択し、内容を確認の上、インポートボタンをクリックしてください。
        </div>
        <div className="pt-4 flex flex-col gap-4">
          <div className="mt-2 border-b-2 border-b-gray-100 w-full text-center">
            <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} centered>
              {shops
                .filter((shop) => shop.shopId !== selectedShopId)
                .map((shop) => (
                  <Tab key={shop.shopId} label={shop.shopName} value={shop.shopId} />
                ))}
            </Tabs>
          </div>
        </div>
        {value !== '' && (
          <Suspense
            fallback={
              <div className="w-full h-full flex justify-center items-center">
                <CircularProgress />
              </div>
            }
          >
            <div className="pt-2 h-60 overflow-y-auto">
              <SelectedShopCategories shopId={value} />
            </div>
          </Suspense>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="mt-auto"
          disabled={value === '' || processing}
          onClick={importCallback}
        >
          インポート
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          onClick={closeCallback}
        >
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SelectedShopCategories = ({ shopId }: { shopId: string }) => {
  const categories = dataCategory.useOtherShopCategory(shopId);
  return (
    <div className="grid grid-cols-3">
      {categories.map((category) => (
        <div className="border p-2">
          <p>{category.categoryId}</p>
          <p>{category.categoryName}</p>
          <p>{category.visible ? '表示' : '非表示'}</p>
        </div>
      ))}
    </div>
  );
};
