export enum RecoilKeys {
  SHOPS_STATE = 'shopsState',
  SHOP_SELECTED_ID_STATE = 'shopSelectedIdState',
  SHOP_SELECTED = 'shopSelected',
  SHOP_INCLUDE_SPOTS = 'shopIncludeSpots',
  SHOP_SELECTED_BASEID_STATE = 'shopSelectedBaseIdState',
  SPOTS_STATE = 'spotsState',
  SPOT_SELECTED_ID_STATE = 'spotSelectedIdState',
  SPOTS_INCLUDE_BOXES = 'spotsIncludeBoxes',

  ORDER_STATE = 'orderState',
  ORDER_ITEM = 'orderItem',
  ORDER_ITEMS_IN_PERIOD = 'orderItemsInPeriod',
  ORDER_ITEMS_PLACED = 'orderItemsPlaced',
  ORDER_ITEMS_ASSIGNED = 'orderItemsAssigned',
  ORDER_ITEMS_ACCEPTED = 'orderItemsAccepted',
  ORDER_ITEMS_ON_DELIVERY = 'orderItemsOnDelivery',
  ORDER_ITEMS_ARRIVED = 'orderItemsArrived',
  ORDER_ITEMS_POSTED = 'orderItemsPosted',
  ORDER_ITEMS_FAILED = 'orderItemsFailed',
  MY_ORDER_ITEMS = 'myOrderItems',
  ORDER_ITEMS_CANCELED = 'orderItemsCanceled',
  ORDER_ITEMS_WAITING_CUSTOMER_BY_STOCK_OUT = 'orderItemsWaitingCustomerByStockOut',
  ORDER_ITEMS_ARE_FILTERED_BY_LAST_MODIFIED = 'orderItemsAreFilteredByLastModified',
  ORDER_ITEMS_FILTERED_BY_LAST_MODIFIED = 'orderItemsFilteredByLastModified',

  OFFER_STATE = 'offerState',
  STOCK_STATE = 'stockState',
  CATEGORY_STATE = 'categoryState',
  OTHER_SHOP_CATEGORIES_STATE = 'otherShopCategoriesState',
  CATEGORY_VISIBLE = 'categoryVisible',
  MAKER_STATE = 'makerState',
  ITEM_STATE = 'itemState',
  CAPACITY_STATE = 'capacityState',
  CAPACITIES_BY_DATE = 'capacityByDate',
  USER_CAPACITY_STATE = 'userCapacityState',
  USER_CAPACITIES_BY_DATE = 'userCapacityByDate',
  DRONESLOTS_STATE = ' DroneSlotsState',
  DRONESLOTS_BY_DATE = ' DroneSlotsByDate',
  PAST_CAPACITIES = 'pastCapacities',
  MANAGEMENT_ITEMS = 'managementItems',
  MANAGEMENT_ITEM = 'managementItem',
  ITEMS_BY_CATEGORY = 'itemsByCategory',
  SHOP_ITEM_MAKER_ALL = 'SHOP_ITEM_MAKER_ALL',
  SHOP_ITEM_MAKER = 'SHOP_ITEM_MAKER',
  ITEMS_FILTERED = 'itemsFiltered',
  BOX_STATE = 'boxState',
  BOXES_IN_SHOP_STATE = 'boxesInShopState',

  APP_MESSAGES_STATE = 'appMessagesState',
  APP_MESSAGES_MESSAGES = 'appMessagesMessages',
  APP_MESSAGES_MESSAGE = 'appMessagesMessage',

  GRAPH_DATA_SALES = 'graphDataSales',
  GRAPH_DATA_USERS = 'graphDataUsers',

  COGNITO_USER_STATE = 'cognitoUserState',
  IS_DEVELOPER = 'isDeveloper',
  IS_ADMINISTRATOR = 'isAdministrator',

  USERS_STATE = 'usersState',
  USERS_FILTERED = 'usersFiltered',

  STAFFS_STATE = 'staffsState',
  STAFF_STATE = 'staffState',
  IS_NOT_REGIST_STAFF = 'isNotRegistStaff',
}
