import { api } from './Api';

export const administratorMail = async ({
  type,
  title,
  message,
}: {
  type: string;
  title: string;
  message: string;
}) => {
  const ret = await api.PostAdministratorMail({
    type: type,
    title: title,
    message: message,
  });
  return ret;
};
