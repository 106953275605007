import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { OrderItem, OrderItemState, dataOrder } from '../data/Order';
import { dataItem } from '../data/Item';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { dataCategory } from '../data/Category';
import { Item } from '../data/Item';
import { dataStock } from '../data/Stock';

export const OrderChangeDialog = ({
  open,
  order,
  callbackOnClose,
}: {
  open: boolean;
  order: OrderItemState;
  callbackOnClose: () => void;
}) => {
  const [processing, setProcessing] = useState(false);
  const [targetItemId, setTargetItemId] = useState<string>('');
  const [itemsState, setItemsState] = useState<
    (OrderItem & {
      oldItemId: string;
      oldCount: number;
      isDelete: boolean;
      isChange: boolean;
    })[]
  >([]);
  const updateOrderItems = dataOrder.useUpdateOrderItems();
  const [openItemChangeDialog, setOpenItemChangeDialog] = useState(false);
  const stockRefresh = dataStock.useStockRefresh();

  useEffect(() => {
    if (!order.items) return;
    setItemsState(
      order.items.map((item) => ({
        ...item,
        oldItemId: item.itemId,
        oldCount: item.count,
        isDelete: false,
        isChange: false,
      }))
    );
  }, [order.items]);

  if (!order.items) {
    return null;
  }

  const deletedItems = itemsState.filter((item) => item.isDelete);

  const callbackOnSave = async () => {
    setProcessing(true);
    await updateOrderItems(
      order.orderId,
      itemsState.filter((item) => !item.isDelete)
    );
    await stockRefresh();
    setProcessing(false);
    callbackOnClose();
  };

  return (
    <Dialog open={open} onClose={callbackOnClose}>
      <ItemChangeDialog
        open={openItemChangeDialog}
        targetItemId={targetItemId}
        callbackOnSave={(targetItemId, newItem) => {
          setItemsState(
            itemsState.map((i) => {
              if (i.itemId === targetItemId) {
                // MEMO: 商品を変更した場合は金額を維持
                return {
                  ...i,
                  itemId: newItem.itemId,
                  name: newItem.name,
                  isDelete: false,
                  isChange: true,
                };
              }
              return i;
            })
          );
        }}
        callbackOnClose={() => setOpenItemChangeDialog(false)}
      />
      <DialogTitle>注文内容の変更</DialogTitle>
      <DialogContent className="text-sm md:text-md">
        <p>注文内容の変更が行えます</p>
        <p>※必ずお客様に連絡を取った上で変更を行なってください</p>

        {itemsState.map((item) => {
          return (
            <Paper key={item.itemId} variant="outlined" className="mt-4 p-2">
              <ItemDetail
                item={item}
                isStockOut={order.stockOutItems?.some((i) => i.itemId === item.itemId) ?? false}
                countChangeCallback={(e) => {
                  setItemsState(
                    itemsState.map((i) => {
                      if (i.itemId === item.itemId) {
                        return {
                          ...i,
                          count: Number(e.target.value),
                        };
                      }
                      return i;
                    })
                  );
                }}
              />
              <div className="mt-4 flex gap-2">
                <div>
                  {item.isDelete ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setItemsState(
                          itemsState.map((i) => {
                            if (i.itemId === item.itemId) {
                              return {
                                ...i,
                                isDelete: false,
                              };
                            }
                            return i;
                          })
                        )
                      }
                    >
                      削除取消
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={item.count === 0}
                      onClick={() =>
                        setItemsState(
                          itemsState.map((i) => {
                            if (i.itemId === item.itemId) {
                              return {
                                ...i,
                                isDelete: true,
                              };
                            }
                            return i;
                          })
                        )
                      }
                    >
                      削除
                    </Button>
                  )}
                </div>
                <div>
                  {item.isChange ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setItemsState(
                          itemsState.map((i) => {
                            if (i.isChange) {
                              const oldItem = order.items.find((oi) => oi.itemId === i.oldItemId);
                              return {
                                ...i,
                                itemId: oldItem?.itemId ?? '',
                                name: oldItem?.name ?? '',
                                isChange: false,
                              };
                            }
                            return i;
                          })
                        );
                      }}
                    >
                      変更を取消
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={item.isDelete}
                      onClick={() => {
                        setTargetItemId(item.itemId);
                        setOpenItemChangeDialog(true);
                      }}
                    >
                      商品を変更
                    </Button>
                  )}
                </div>
              </div>
            </Paper>
          );
        })}
        <p className="mt-4 text-right">
          合計金額(変更前):{' '}
          <span className="font-bold">
            {itemsState.length !== 0 &&
              itemsState
                .map((item) => item.price * item.oldCount)
                .reduce((prev, next) => prev + next, 0)}
          </span>
          円
        </p>
        <p className="mt-2 text-right">
          合計金額(変更後):{' '}
          <span className="font-bold">
            {itemsState.length !== 0 &&
              itemsState
                .filter((item) => !item.isDelete)
                .map((item) => item.price * item.count)
                .reduce((prev, next) => prev + next, 0)}
          </span>
          円
        </p>
        {itemsState.length === deletedItems.length && (
          <p className="mt-4 text-right text-red-500">※全ての商品が削除対象になっています</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={callbackOnSave}
          disabled={
            (deletedItems.length === 0 &&
              !itemsState.some(
                // 個数の変更、商品の変更があった場合
                (i) => i.count !== i.oldCount || order.items.some((oi) => oi.itemId !== i.itemId)
              )) ||
            itemsState.length === deletedItems.length ||
            processing
          }
        >
          変更
        </Button>
        <Button
          color="secondary"
          variant="contained"
          disabled={processing}
          onClick={callbackOnClose}
        >
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ItemDetail = ({
  item,
  isStockOut,
  countChangeCallback,
}: {
  item: OrderItem & {
    oldCount: number;
    isDelete: boolean;
    isChange: boolean;
  };
  isStockOut: boolean;
  countChangeCallback: (e: SelectChangeEvent<number>) => void;
}) => {
  const shopItem = dataItem.useShopItemMaster(item.itemId);

  return (
    <div className="flex gap-2 items-center">
      <img
        alt="商品画像"
        src={shopItem && shopItem.image ? shopItem.image[0] : ''}
        className="rounded-l-md object-cover w-1/3"
      />
      <div className="w-full">
        {isStockOut && <p className="font-bold text-warning">欠品連絡済</p>}
        <p>{item.name}</p>
        <div className="mt-2">
          <FormControl className="w-1/2" size="small">
            <InputLabel id="category-label">個数</InputLabel>
            <Select
              label="個数"
              labelId="category-label"
              disabled={item.isDelete}
              value={item.count}
              onChange={countChangeCallback}
            >
              {!item.isChange &&
                Array.from(Array(item.oldCount).keys()).map((i) => (
                  <MenuItem key={i} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
              {item.isChange &&
                Array.from(Array(10).keys()).map(
                  (i) =>
                    // MEMO: 在庫数を超える個数は選択できない
                    (shopItem?.stock?.freeStockCount ?? 0) > i && (
                      <MenuItem key={i} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    )
                )}
            </Select>
          </FormControl>
        </div>
        {item.isChange && <p className="text-red-500">商品を変更済み</p>}
        {item.count !== item.oldCount && <p className="text-red-500">商品の個数が変更されました</p>}
        {item.isDelete && <p className="text-red-500">※変更を保存すると削除されます</p>}
      </div>
    </div>
  );
};

const ItemChangeDialog = ({
  open,
  targetItemId,
  callbackOnSave,
  callbackOnClose,
}: {
  open: boolean;
  targetItemId: string;
  callbackOnSave: (targetItemId: string, item: Item) => void;
  callbackOnClose: () => void;
}) => {
  const categories = dataCategory.useCategoryVisible();
  const [category, setCategory] = useState('all');
  const [stockFrom, setStockFrom] = useState<number | undefined>(undefined);
  const [stockTo, setStockTo] = useState<number | undefined>(undefined);
  const [keyword, setKeyword] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<Item | undefined>(undefined);

  return (
    <Dialog open={open} onClose={callbackOnClose} fullScreen={true}>
      <DialogTitle>商品の変更</DialogTitle>
      <DialogContent>
        <p>商品を選択した上で、変更ボタンを押下してください</p>
        <div className="my-4 p-2 border">
          <div className="pl-4 text-sm md:text-base">絞り込み検索</div>
          <div className="flex flex-col gap-2 px-2 mt-2">
            <FormControl className="w-full md:w-1/3">
              <InputLabel id="category-label">カテゴリー</InputLabel>
              <Select
                label="カテゴリー"
                labelId="category-label"
                value={category}
                size="small"
                onChange={(e) => setCategory(e.target.value)}
              >
                <MenuItem value="all">全て</MenuItem>
                {categories.map((category) => {
                  return (
                    <MenuItem key={category.categoryId} value={category.categoryId}>
                      {category.categoryName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div className="flex">
              <TextField
                label="在庫数from"
                type="number"
                className="w-32"
                size="small"
                onChange={(e) => setStockFrom(Number(e.target.value))}
              />
              <span className="inline-block my-auto">〜</span>
              <TextField
                label="在庫数to"
                type="number"
                className="w-32"
                size="small"
                onChange={(e) => setStockTo(Number(e.target.value))}
              />
            </div>
            <div>
              <TextField
                label="キーワード検索"
                className="w-full"
                size="small"
                onChange={_.debounce((e) => setKeyword(e.target.value), 200)}
              />
            </div>
          </div>
        </div>

        <Items
          categoryId={category}
          stockFrom={stockFrom}
          stockTo={stockTo}
          keyword={keyword}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (selectedItem) {
              callbackOnSave(targetItemId, selectedItem);
              setSelectedItem(undefined);
              callbackOnClose();
            }
          }}
          disabled={!selectedItem}
        >
          変更
        </Button>
        <Button variant="contained" color="secondary" onClick={callbackOnClose}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Items = ({
  categoryId,
  stockFrom,
  stockTo,
  keyword,
  selectedItem,
  setSelectedItem,
}: {
  categoryId: string;
  stockFrom: number | undefined;
  stockTo: number | undefined;
  keyword: string;
  selectedItem: Item | undefined;
  setSelectedItem: (item: Item) => void;
}) => {
  // MEMO: itemsの取得結果をキャッシュに保存しておく
  dataItem.useItems();
  // MEMO: キャッシュから絞り込みを行う
  const filteredItems = dataItem.useItemsFiltered({
    categoryId,
    stockFrom,
    stockTo,
    keyword,
    visibleCondition: 'all',
  });
  return (
    <div className="flex gap-4 h-64 overflow-x-auto overflow-y-auto">
      {filteredItems.map((item) => (
        <div
          key={item.itemId}
          className="flex flex-col border rounded-md p-2 cursor-pointer h-auto"
          onClick={() => {
            if (item.stock?.freeStockCount) {
              setSelectedItem(item);
            }
          }}
        >
          {item.itemId === selectedItem?.itemId && (
            <div className="relative">
              <p className="absolute top-0 right-0 opacity-90 px-2 rounded-md text-center bg-blue-300">
                選択中
              </p>
            </div>
          )}
          <div className="w-24 h-24">
            <img alt="商品画像" src={item.image[0]} className="object-cover w-full h-full" />
          </div>
          {!item.stock?.freeStockCount && <p className="text-sm text-red-500">在庫切れ</p>}
          <p className="text-sm line-clamp-2"> ID：{item.itemId} </p>
          <p className="text-sm line-clamp-2"> 名称：{item.name} </p>
          <p className="text-sm line-clamp-2"> 価格：{item.price}円 </p>
          <p className="text-sm line-clamp-2">在庫数：{item.stock?.freeStockCount ?? '0'}個</p>
        </div>
      ))}
    </div>
  );
};
