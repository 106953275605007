import { Button, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import DialogContentText from '@mui/material/DialogContentText';
import { administratorMail } from '../data/Administrator';
import { DialogOk, DialogOkCancel } from '@common/components/Dialog';
import { dataCognitoUser } from '../data/CognitoUser';

export const ManagementAdminMail = () => {
  const isAdministrator = dataCognitoUser.useIsAdministrator();
  return (
    <div className="mt-2">
      <div className="py-4 text-center">
        cognitoに登録されており、メール配信拒否以外の方へメールを一斉配信します。
        <br />
        ※登録している方へのメール配信となります慎重に扱ってください
      </div>
      {isAdministrator && <ManagementAdminMailForm />}
    </div>
  );
};

const ManagementAdminMailForm = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [mailTitle, setMailTitle] = useState('');
  const [mailBody, setMailBody] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [openSettingErrorDialog, setSettingErrorDialog] = useState(false);
  const [sendTestDialog, setSendTestDialog] = useState(false);
  const [sendDialog, setSendDialog] = useState(false);
  const [sendSecondDialog, setSendSecondDialog] = useState(false);
  const [confirmString, setConfirmString] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  const generateVerificationCode = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };

  useEffect(() => {
    setVerificationCode(generateVerificationCode());
  }, []);

  const containsDependentCharacters = (text: string): boolean => {
    // 私用領域、特殊用途文字、追加の絵文字など追加したい
    return false;
  };
  const handleConfirmSend = async () => {
    if (verificationCode === confirmString) {
      setSendSecondDialog(false);
      sendMail('SEND');
    } else {
      alert('入力された数字が一致しません。');
    }
  };

  const handleConfirmSendTest = async () => {
    sendMail('TEST');
  };

  const handleSendMailClick = async () => {
    setSendDialog(true);
  };

  const handleTestMailClick = async () => {
    setSendTestDialog(true);
  };

  const sendMail = async (mailType: string) => {
    setDialogMessage('');
    if (containsDependentCharacters(mailTitle) || containsDependentCharacters(mailBody)) {
      setDialogTitle('エラー');
      setDialogMessage('メールタイトルまたは本文に機種依存文字が含まれています。');
      setSettingErrorDialog(true);
      return;
    }

    setIsDisabled(true);
    try {
      const ret = await administratorMail({
        message: mailBody,
        title: mailTitle,
        type: mailType,
      });
      setDialogTitle('送信結果');
      setDialogMessage(ret.list.map((pair) => pair.join(', ')).join('\n'));
      setSettingErrorDialog(true);
    } catch (error) {
      setDialogTitle('送信結果');
      setDialogMessage('メール送信に失敗しました。');
      setSettingErrorDialog(true);
    }
    setIsDisabled(false);
  };

  return (
    <>
      <div className="py-4 justify-center">
        <TextField
          label="メールタイトル"
          variant="outlined"
          fullWidth
          margin="normal"
          value={mailTitle}
          onChange={(e) => setMailTitle(e.target.value)}
          autoComplete="off"
        />
        <TextField
          label="メール本文"
          variant="outlined"
          fullWidth
          multiline
          rows={20}
          margin="normal"
          value={mailBody}
          onChange={(e) => setMailBody(e.target.value)}
          autoComplete="off"
        />
        <Button
          variant="contained"
          sx={{ marginLeft: 1 }}
          disabled={isDisabled}
          onClick={handleTestMailClick}
        >
          テストメール送信
        </Button>
        <Button
          variant="contained"
          sx={{ marginLeft: 1 }}
          disabled={isDisabled}
          onClick={handleSendMailClick}
        >
          全ユーザへメール送信
        </Button>
      </div>
      <DialogOk
        open={openSettingErrorDialog}
        title={dialogTitle}
        message={dialogMessage}
        callbackOk={async () => {
          setSettingErrorDialog(false);
        }}
      />
      <DialogOkCancel
        open={sendTestDialog}
        title={'テストメール送信'}
        message={
          '現在ログインしているアドレスへ、メールのタイトル、本文確認用のメールを送信します。'
        }
        callbackOk={() => {
          setSendTestDialog(false);
          handleConfirmSendTest();
        }}
        callbackCancel={() => setSendTestDialog(false)}
        textOk="確認"
        textCancel="キャンセル"
        isAutoFocusCancel={true}
      ></DialogOkCancel>
      <DialogOkCancel
        open={sendDialog}
        title={'全ユーザへメール送信'}
        message={'全ユーザへメール送信しますか、この操作は取り消しができません'}
        callbackOk={() => {
          setSendDialog(false);
          setVerificationCode(generateVerificationCode());
          setSendSecondDialog(true);
        }}
        callbackCancel={() => setSendDialog(false)}
        textOk="確認"
        textCancel="キャンセル"
        isAutoFocusCancel={true}
      >
        <DialogContentText>この操作では、cognitoに登録されている情報を元に</DialogContentText>
        <DialogContentText>
          登録済みのユーザへメールを送信します。１分あたり１００通程度のペースで送信されます
        </DialogContentText>
        <DialogContentText>
          Lambda(実行上限１５分間)を利用して送信しています。ユーザ数が多いとタイムアウトする可能性があります。
        </DialogContentText>
      </DialogOkCancel>
      <DialogOkCancel
        open={sendSecondDialog}
        title={'本当に全ユーザへメール送信しますか？'}
        message={`メールを送信するには表示された数字 ${verificationCode} を入力し、送信ボタンを押してください`}
        callbackOk={handleConfirmSend}
        callbackCancel={() => setSendSecondDialog(false)}
        textOk="送信"
        textCancel="キャンセル"
      >
        <TextField
          label="表示された４桁の数字"
          variant="outlined"
          fullWidth
          value={confirmString}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmString(e.target.value)}
          autoComplete="off"
        />
      </DialogOkCancel>
    </>
  );
};
