import { Typography } from '@mui/material';
import { dataBox } from '../data/Box';

export const BoxStateList = () => {
  const boxesInShopState = dataBox.useBoxesInShopState();
  const cellStyle = 'py-2 px-4 border';

  return (
    <div className="mt-4">
      <Typography variant="h5" className="text-center">
        Box状態一覧
      </Typography>
      <div className="mt-4 flex gap-8 justify-center">
        <table>
          <thead>
            <tr className="text-lg text-center">
              <th className={cellStyle}>spotId</th>
              <th className={cellStyle}>配送先名</th>
              <th className={cellStyle}>boxId</th>
              <th className={cellStyle}>オーダーId</th>
              <th className={cellStyle}>状態</th>
            </tr>
          </thead>
          <tbody>
            {boxesInShopState.map((boxState) => (
              <tr key={boxState.boxId} className="text-lg text-center">
                <td className={cellStyle}>{boxState.spotId}</td>
                <td className={cellStyle}>{boxState.spotName}</td>
                <td className={cellStyle}>{boxState.boxId}</td>
                <td className={cellStyle}>{boxState.orderId}</td>
                <td className={cellStyle}>{boxState.state}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
