import { Button, Paper, TextField, Typography } from '@mui/material';
import { Staff, dataStaff } from '../data/Staff';
import { useState } from 'react';
import { DialogOk } from '@common/components/Dialog';

export const StaffForm = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const staff = dataStaff.useStaffState();
  const [staffState, setStaffState] = useState<Staff>(
    staff ?? {
      staffName: '',
      phoneNumber: '',
    }
  );
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const upsertStaff = dataStaff.useUpsertStaff();

  const submitCallback = async () => {
    let isError = false;
    setNameErrorMessage('');
    setPhoneErrorMessage('');

    if (staffState.staffName === '') {
      isError = true;
      setNameErrorMessage('表示名を入力してください');
    }
    if (staffState.phoneNumber === '') {
      isError = true;
      setPhoneErrorMessage('携帯電話番号を入力してください');
    }
    // TODO: 一旦携帯電話番号のバリデーションにしているが、後で変更する
    if (!staffState.phoneNumber.match(/^0\d{1}0\d{4}\d{4}$/)) {
      isError = true;
      setPhoneErrorMessage('携帯電話番号の形式が正しくありません');
    }

    if (isError) {
      return;
    }

    setProcessing(true);
    await upsertStaff(staffState);
    setProcessing(false);
    setOpenDialog(true);
  };

  return (
    <div className="flex flex-col items-center gap-2 p-5">
      <DialogOk
        open={openDialog}
        message={'スタッフ情報を更新しました'}
        callbackOk={() => {
          setOpenDialog(false);
        }}
      />
      <Typography variant="h5" className="text-center">
        スタッフ情報の編集
      </Typography>
      <Paper className="w-full sm:w-[500px] p-4">
        <div className="flex flex-col gap-4 px-2 items-center">
          <TextField
            label="表示名"
            variant="standard"
            size="medium"
            className="w-full"
            value={staffState.staffName}
            onChange={(e) => {
              setStaffState({ ...staffState, staffName: e.target.value });
            }}
            error={Boolean(nameErrorMessage)}
            helperText={nameErrorMessage}
          />
          <TextField
            label="携帯電話番号(ハイフンなし)"
            variant="standard"
            size="medium"
            className="w-full"
            value={staffState.phoneNumber ?? ''}
            onChange={(e) => {
              setStaffState({ ...staffState, phoneNumber: e.target.value });
            }}
            error={Boolean(phoneErrorMessage)}
            helperText={phoneErrorMessage}
          />

          <Button
            variant="contained"
            className="w-1/2"
            disabled={
              (staff?.staffName === staffState.staffName &&
                staff?.phoneNumber === staffState.phoneNumber) ||
              processing
            }
            onClick={submitCallback}
          >
            設定
          </Button>
        </div>
      </Paper>
    </div>
  );
};
