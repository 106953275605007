import React, { useState } from 'react';

import { OrderItemState, UserInfo, dataOrder } from '../data/Order';
import { Accordion, AccordionDetails, AccordionSummary, Button, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DialogOrderInfo } from './OrderInfoDialog';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { useJudgeSize } from '@common/utils/JudgeSize';
import { dataItem } from '../data/Item';
import { dataMaker } from '../data/Maker';
import { OrderCustomerDetailDialog } from './OrderCustomerDetailDialog';
import { DialogOkCancel } from '@common/components/Dialog';
import { data } from '../data/Data';
import { dataCognitoUser } from '../data/CognitoUser';

const getBackgroundColorByOrderState = (state: string) => {
  switch (state) {
    case 'deleted':
    case 'payingTimeout':
      return 'Whitesmoke';
    default:
      return 'white';
  }
};

export const OrderList = () => {
  const [dateFrom, setDateFrom] = useState<Moment>(moment());
  const [dateTo, setDateTo] = useState<Moment>(moment());
  const orders = dataOrder.useOrderItemsInPeriod(
    dateFrom.startOf('d').toDate(),
    // MEMO: 1日後の0時までにしておかないと指定日時の注文を取得できない
    dateTo.clone().add(1, 'd').startOf('d').toDate()
  );
  const [orderDetail, setOrderDetail] = useState<OrderItemState>({
    userInfo: {} as UserInfo,
  } as OrderItemState);
  const [infoDialog, setInfoDialog] = useState(false);
  const [customerDetailDialog, setCustomerDetailDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [deleteSecondDialog, setDeleteSecondDialog] = React.useState(false);
  const [expanded, setExpanded] = useState<string>('');
  const isSp = useJudgeSize();
  const isDeveloper = dataCognitoUser.useIsDeveloper();
  const [confirmName, setConfirmName] = useState('');

  const setOrderState = data.order.useSetOrderState();

  const setDeleted = async (orderId: string) => {
    setOrderState(orderId, 'deleted');
  };

  const clickDetail = (order: OrderItemState) => {
    setOrderDetail(order);
    setInfoDialog(true);
  };
  const clickCustomerDetail = (order: OrderItemState) => {
    setOrderDetail(order);
    setCustomerDetailDialog(true);
  };
  const clickDelete = (order: OrderItemState) => {
    setOrderDetail(order);
    setDeleteDialog(true);
  };

  const isDeleteButtonVisible = (state: string) => {
    return isDeveloper && (state === 'cancelled' || state === 'completed');
  };

  const handleDelete = async () => {
    if (orderDetail.userInfo.surName === confirmName) {
      setDeleteSecondDialog(false);
      await setDeleted(orderDetail.orderId);
    } else {
      alert('注文者の名字が一致しません。');
    }
  };

  return (
    <div className="mt-4">
      <DialogOkCancel
        open={deleteDialog}
        title={'注文削除'}
        message={
          '注文を削除しますか？削除した注文は復元できません。 また支払い済みの場合は返金されます。'
        }
        callbackOk={() => {
          setDeleteDialog(false);
          setDeleteSecondDialog(true);
        }}
        callbackCancel={() => setDeleteDialog(false)}
        textOk="確認"
        textCancel="キャンセル"
        isAutoFocusCancel={true}
      ></DialogOkCancel>
      <DialogOkCancel
        open={deleteSecondDialog}
        title={'本当に注文を削除しますか？'}
        message={'注文を削除するには注文者の名字を入力してください。'}
        callbackOk={handleDelete}
        callbackCancel={() => setDeleteSecondDialog(false)}
        textOk="削除"
        textCancel="キャンセル"
      >
        <TextField
          label="注文者名字"
          variant="outlined"
          fullWidth
          value={confirmName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmName(e.target.value)}
        />
      </DialogOkCancel>

      <p className="text-2xl text-center">注文一覧</p>
      <div className="w-[380px] my-4 mx-auto p-2 border">
        <div className="pl-4 text-lg">絞り込み検索</div>
        <div className="flex items-center mt-4">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="開始日を選択"
              format="YYYY-MM-DD"
              views={['year', 'month', 'day']}
              maxDate={dateTo ?? moment()}
              value={dateFrom}
              onChange={(newValue) => {
                setDateFrom(newValue ?? moment());
              }}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </LocalizationProvider>
          <p className="mx-2">〜</p>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="終了日を選択"
              format="YYYY-MM-DD"
              views={['year', 'month', 'day']}
              minDate={dateFrom}
              maxDate={moment()}
              value={dateTo}
              onChange={(newValue) => {
                setDateTo(newValue ?? moment());
              }}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="p-2 py-4 flex flex-col gap-2">
        <DialogOrderInfo
          open={infoDialog}
          order={orderDetail}
          callbackOnClose={() => setInfoDialog(false)}
        />
        <OrderCustomerDetailDialog
          open={customerDetailDialog}
          order={orderDetail}
          callbackOnClose={() => setCustomerDetailDialog(false)}
        />
        {orders.map((order) => (
          <Accordion
            key={order.orderId}
            expanded={expanded === order.orderId}
            onChange={() => {
              if (expanded === order.orderId) {
                setExpanded('');
                return;
              }
              setExpanded(order.orderId);
            }}
            style={{
              backgroundColor:
                expanded === order.orderId
                  ? getBackgroundColorByOrderState(order.state)
                  : 'transparent',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ backgroundColor: getBackgroundColorByOrderState(order.state) }}
            >
              {isSp ? (
                <div className="flex gap-2">
                  <div className="w-16 text-sm break-words">
                    <p>受付番号</p>
                    <p>{order.receiptNumber}</p>
                  </div>
                  <div className="w-20 text-sm break-words">
                    <p>担当者</p>
                    <p>{order.shopUserInfo.staffName}</p>
                  </div>
                  <div className="w-20 text-sm break-words">
                    <p>配送時刻</p>
                    <div>
                      {order.deliveryTime && (
                        <>
                          <p>{order.deliveryTime.date.toLocaleDateString()}</p>
                          <span>{order.deliveryTime.toStringTime('-')}</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="w-20 text-sm break-words">
                    <p>状態</p>
                    <p>{order.state}</p>
                  </div>
                </div>
              ) : (
                <>
                  <div className="w-1/4">受付番号 : {order.receiptNumber}</div>
                  <div className="w-1/4">担当者 : {order.shopUserInfo.staffName}</div>
                  <div className="w-1/4">
                    配送時刻
                    {order.deliveryTime && (
                      <>
                        <span className="ml-4 font-bold">
                          {order.deliveryTime.date.toLocaleDateString()}
                        </span>
                        <span className="ml-4 font-bold">
                          {order.deliveryTime.toStringTime('～')}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="w-1/4">
                    状態
                    <span className="ml-4 font-bold">{order.state}</span>
                  </div>
                </>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex gap-1">
                <div className="w-1/3 flex flex-col">
                  <div className="mb-2">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => clickDetail(order)}
                    >
                      <span className="text-sm md:text-lg">注文詳細</span>
                    </Button>
                  </div>
                  <div className="mb-2">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => clickCustomerDetail(order)}
                    >
                      <span className="text-sm md:text-lg">注文者詳細</span>
                    </Button>
                  </div>
                  {isDeleteButtonVisible(order.state) && (
                    <div className="mt-2">
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: 'white',
                          color: 'black',
                          '&:hover': { background: 'gray' },
                        }}
                        onClick={() => clickDelete(order)}
                      >
                        <span className="text-sm">注文削除</span>
                      </Button>
                    </div>
                  )}
                  <div className="mb-1 mt-2 flex items-center">
                    <div
                      className="text-xs italic cursor-pointer underline"
                      onClick={() => clickDetail(order)}
                    >
                      {order.orderId}
                    </div>
                  </div>
                  <div className="mb-1 flex items-center">
                    <div className="w-16 md:w-20 text-sm">合計金額</div>
                    <div>
                      <Price price={order.totalPrice} />
                    </div>
                  </div>
                  <div className="mb-1 flex items-center">
                    <div className="w-20 text-sm">配送先</div>
                    <p className="text-xs md:text-md break-words">{order.deliveryTo}</p>
                  </div>
                  <div className="mb-1 mt-2 flex items-center">
                    <div className="w-20 text-sm">注文</div>
                    <div className="text-xs">{order.timePaying.toLocaleString()}</div>
                  </div>
                  {order.timeCompleted && (
                    <div className="mb-1 flex items-center">
                      <div className="w-20 text-sm">受取</div>
                      <div className="text-xs">{order.timeCompleted.toLocaleString()}</div>
                    </div>
                  )}
                  {order.timeCancelled && (
                    <div className="mb-1 flex items-center">
                      <div className="w-20 text-sm">キャンセル</div>
                      <div className="text-xs">
                        {order.timeCancelled.toLocaleString()}
                        <br /> {order.cancellationReason}
                      </div>
                    </div>
                  )}
                </div>

                <div className="w-2/3 flex flex-col">
                  <p className="text-sm font-bold mb-2">配達履歴</p>
                  {order.trackShipments.map((trackShipment) => (
                    <div
                      key={trackShipment.timestamp}
                      className="flex mb-2 p-2 w-full items-center rounded-md shadow shadow-gray-300"
                    >
                      <div className="w-1/2 text-sm">
                        {moment.unix(trackShipment.timestamp).format('YYYY/MM/DD H:mm')}
                      </div>
                      <div className="w-1/2 text-sm">{trackShipment.info}</div>
                    </div>
                  ))}
                  {order.trackShipments.length === 0 && (
                    <div className="flex mb-2 p-2 w-full items-center rounded-md shadow shadow-gray-300">
                      <div className="w-full text-sm">履歴なし</div>
                    </div>
                  )}
                  <p className="text-sm font-bold mb-2">注文商品</p>
                  {order.items.map((item) => (
                    <div
                      key={order.orderId + item.itemId}
                      className="flex mb-2 w-full items-center rounded-md shadow shadow-gray-300"
                    >
                      <React.Suspense>
                        <OrderItemDetail
                          itemId={item.itemId}
                          count={item.count}
                          price={item.price}
                        />
                      </React.Suspense>
                    </div>
                  ))}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

const Price = ({ price }: { price: number }) => {
  return (
    <span>
      <span className="font-bold text-sm md:text-lg">{price.toLocaleString()}</span>
      <span className="text-sm"> 円</span>
    </span>
  );
};

const OrderItemDetail = ({
  itemId,
  count,
  price,
}: {
  itemId: string;
  count: number;
  price: number;
}) => {
  const item = dataItem.useShopItemMaster(itemId) ? dataItem.useShopItemMaster(itemId) : '';
  const makerId: string = item && item.makerId ? item.makerId : '';
  const itemName = item && item.name ? item.name : '';
  const makerName: string | undefined = dataMaker.useShopItemMaker(makerId)?.makerName;

  return (
    <>
      <React.Suspense>
        <img
          alt="商品画像"
          src={item && item.image ? item.image[0] : ''}
          className="rounded-l-md object-cover h-full w-16 sm:w-24"
        />
      </React.Suspense>
      <div className="flex flex-col pl-4 text-sm sm:text-md">
        <div>{itemId}</div>
        {makerName}
        <div>{itemName}</div>
        <div>
          <span className="text-xl font-bold text-warning">{count}</span>
          <span className="text-sm mr-8"> 個</span>
          <Price price={price} />
        </div>
      </div>
    </>
  );
};
