import React, { useState } from 'react';
// MUI
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import PersonIcon from '@mui/icons-material/Person';

import { DialogOkCancel } from '@common/components/Dialog';
import { useJudgeSize } from '@common/utils/JudgeSize';

import { data } from '../data/Data';
import { OrderItemState, UserInfo, dataOrder } from '../data/Order';
import {
  Alert,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { dataItem } from '../data/Item';
import { dataMaker } from '../data/Maker';
import { DialogOk } from '../utils/Dialog';
import { DialogOrderInfo } from './OrderInfoDialog';
import { dataCognitoUser } from '../data/CognitoUser';
import { OrderChangeDialog } from './OrderChangeDialog';
import { OrderStockOutDialog } from './OrderStockOutDialog';
import { OrderCustomerDetailDialog } from './OrderCustomerDetailDialog';

export const OrderTodo = () => {
  dataCognitoUser.useIsDeveloper();

  const ordersAccepted = data.order.useOrderItemsAccepted();
  const ordersPlaced = data.order.useOrderItemsPlaced();
  const ordersAssigned = data.order.useOrderItemsAssigned();
  const ordersWaitingCustomerByStockOut = data.order.useOrderItemsWaitingCustomerByStockOut();

  //   const setOrderState = data.order.submitOrderState;
  const setOrderState = data.order.useSetOrderState();

  const setOnDelivery = async (order: OrderItemState) => {
    setOrderState(order.orderId, 'onDelivery');
  };
  const setAccepted = async (order: OrderItemState) => {
    setOrderState(order.orderId, 'accepted');
  };
  const setAssigned = async (order: OrderItemState) => {
    setOrderState(order.orderId, 'assigned');
  };
  const setCancelled = async (orderId: string, cancellationReason: string) => {
    setOrderState(orderId, 'cancelled', cancellationReason);
  };

  return (
    <div className="p-2 py-4 flex flex-col">
      <OrderSwitchButton />
      <div className="">
        <div className="mb-2 w-full text-center text-2xl font-bold">
          {ordersAccepted.length ? (
            <span className="text-warning">発送して下さい</span>
          ) : (
            <span className="">発送待ちの注文はありません</span>
          )}
        </div>
        <ShowOrders
          orders={ordersAccepted}
          todoName="発送"
          todoCallback={setOnDelivery}
          cancelCallback={setCancelled}
        />
      </div>

      <div className="mt-8">
        <div className="mb-2 w-full text-center text-2xl font-bold">
          {ordersAssigned.length ? (
            <span className="text-warning">注文内容を確認して受理して下さい</span>
          ) : (
            <span className="">受理待ちの注文はありません</span>
          )}
        </div>
        <ShowOrders
          orders={ordersAssigned}
          todoName="受理"
          todoCallback={setAccepted}
          cancelCallback={setCancelled}
        />
      </div>

      <div className="mt-8">
        <div className="mb-2 w-full text-center text-2xl font-bold">
          {ordersPlaced.length ? (
            <span className="text-warning">注文内容を確認して担当者割当を行なって下さい</span>
          ) : (
            <span className="">担当者割当待ちの注文はありません</span>
          )}
        </div>
        <ShowOrders
          orders={ordersPlaced}
          todoName="担当"
          todoCallback={setAssigned}
          cancelCallback={setCancelled}
        />
      </div>

      <div className="mt-8">
        <div className="mb-2 w-full text-center text-2xl font-bold">
          {ordersWaitingCustomerByStockOut.length ? (
            <span className="text-warning">欠品のためお客様からの連絡待ち</span>
          ) : (
            <span className="">お客様からの連絡待ちの注文はありません</span>
          )}
        </div>
        <ShowOrders
          orders={ordersWaitingCustomerByStockOut}
          todoName="受理"
          todoCallback={setAssigned}
          cancelCallback={setCancelled}
        />
      </div>
    </div>
  );
};

export const OrderOnDelivery = () => {
  const ordersOnDelivery = data.order.useOrderItemsOnDelivery();
  const ordersArrived = data.order.useOrderItemsArrived();
  const ordersPosted = data.order.useOrderItemsPosted();
  const [isOpenErrorDialog, setIsOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // TODO : demo用(Droneがセットする)
  const setOrderState = data.order.useSetOrderState();
  const setArrived = async (order: OrderItemState) => {
    try {
      await data.order.ReadyForPosting(order);
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message);
        setIsOpenErrorDialog(true);
      }
    }
  };
  const setPosted = async (order: OrderItemState) => {
    await data.order.PostingCompleted(order);
  };
  const setReceived = async (order: OrderItemState) => {
    await data.order.ReceivedPackage(
      order,
      '',
      '' //order.boxKey ?? ''
    );
    setOrderState(order.orderId, 'completed');
  };
  const setCancelled = async (orderId: string, cancellationReason: string) => {
    setOrderState(orderId, 'cancelled', cancellationReason);
  };

  return (
    <div className="p-2 py-4 flex flex-col">
      <DialogOk
        open={isOpenErrorDialog}
        message={errorMessage}
        callbackOk={() => {
          setIsOpenErrorDialog(false);
        }}
      />
      <OrderSwitchButton />
      <div className="mb-8">
        <div className="mb-2 w-full text-center text-2xl font-bold">
          {ordersOnDelivery.length + ordersArrived.length
            ? '配送中の注文'
            : '配送中の注文はありません'}
        </div>
        {/* <ShowOrders orders={ordersOnDelivery} /> */}
        <ShowOrders
          orders={ordersOnDelivery}
          todoName={'到着'}
          todoCallback={setArrived}
          cancelCallback={setCancelled}
        />
        <ShowOrders
          orders={ordersArrived}
          todoName={'投函'}
          todoCallback={setPosted}
          cancelCallback={setCancelled}
        />
      </div>
      <div className="">
        <div className="mb-2 w-full text-center text-2xl font-bold">
          {ordersPosted.length ? '投函して受取待の注文' : '投函して受取待の注文はありません'}
        </div>
        <ShowOrders
          orders={ordersPosted}
          todoName="商品受取"
          todoCallback={setReceived}
          cancelCallback={setCancelled}
        />
      </div>
    </div>
  );
};

type ShowOrdersProps = {
  readonly orders: OrderItemState[];
  readonly todoName?: string;
  readonly todoCallback?: (order: OrderItemState) => void;
  readonly cancelCallback?: (orderId: string, cancellationReason: string) => void;
  readonly isCancelButtonVisible?: boolean;
};

const CancelReasonInputForm = (props: {
  setReason: React.Dispatch<React.SetStateAction<string>>;
}) => {
  // cancel reason to value
  const cancelReasonToValue = (cancelReason: number): string => {
    switch (cancelReason) {
      case 10:
        return 'ご注文いただいた商品の確保ができなかったため';
      case 20:
        return '配達Boxがいっぱいで配達できないため';
      case 30:
        return '悪天候のため、現在配達を見合わせているため';
      case -1:
        return 'その他';
      default:
        return '';
    }
  };

  // cancel reason
  const [cancelReasonSelect, setCancelReasonSelect] = React.useState(10);
  const [cancelReasonText, setCancelReasonText] = React.useState(
    cancelReasonToValue(cancelReasonSelect)
  );

  props.setReason(cancelReasonText);

  return (
    <FormControl fullWidth>
      <InputLabel id="cancel-reason-select-label">キャンセル理由</InputLabel>
      <Select
        sx={{ width: '480px' }}
        labelId="cancel-reason-select-label"
        id="cancel-reason-select"
        value={cancelReasonSelect}
        label="キャンセル理由"
        onChange={(event) => {
          setCancelReasonSelect(event.target.value as number);
          setCancelReasonText(cancelReasonToValue(event.target.value as number));
        }}
        className="mb-2"
      >
        <MenuItem value={10}>ご注文いただいた商品の確保ができなかったため</MenuItem>
        <MenuItem value={20}>配達Boxがいっぱいで配達できないため</MenuItem>
        <MenuItem value={30}>悪天候で配達が困難であるため</MenuItem>
        <MenuItem value={-1}>その他(記入してください)</MenuItem>
      </Select>
      {cancelReasonSelect === -1 && (
        <TextField
          id="cancel-reason-text"
          label="キャンセル理由(自由記入)"
          multiline
          rows={4}
          value={cancelReasonText}
          onChange={(event) => {
            setCancelReasonText(event.target.value);
          }}
        />
      )}
    </FormControl>
  );
};

const ShowOrders = ({
  orders,
  todoName,
  todoCallback,
  cancelCallback,
  isCancelButtonVisible = true,
}: ShowOrdersProps) => {
  const isSp = useJudgeSize();

  // Accordion
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) =>
    setExpanded(isExpanded ? panel : false);

  // Next state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [cancelDialog, setCancelDialog] = React.useState(false);
  const [cancelSecondDialog, setCancelSecondDialog] = React.useState(false);
  const [detailDialog, setDetailDialog] = React.useState(false);
  const [infoDialog, setInfoDialog] = React.useState(false);
  const [changeDialog, setChangeDialog] = React.useState(false);
  const [stockOutDialog, setStockOutDialog] = React.useState(false);
  const [orderDetail, setOrderDetail] = React.useState<OrderItemState>({
    userInfo: {} as UserInfo,
  } as OrderItemState);
  const [clickedItem, setClickedItem] = React.useState<OrderItemState | null>(null);
  const [openProgress, setOpenProgress] = React.useState(false);

  // reason
  const [reason, setReason] = React.useState('');

  const clickTodo = (order: OrderItemState) => {
    setClickedItem(order);
    setOpenDialog(true);
  };
  const clickCancel = (order: OrderItemState) => {
    setClickedItem(order);
    setCancelDialog(true);
  };
  const clickDetail = (order: OrderItemState) => {
    setOrderDetail(order);
    setDetailDialog(true);
  };
  const clickInfo = (order: OrderItemState) => {
    setOrderDetail(order);
    setInfoDialog(true);
  };
  const clickChange = (order: OrderItemState) => {
    setOrderDetail(order);
    setChangeDialog(true);
  };
  const clickStockOut = (order: OrderItemState) => {
    setOrderDetail(order);
    setStockOutDialog(true);
  };
  const clickArrive = (order: OrderItemState) => {
    setClickedItem(order);
    setOpenDialog(true);
  };
  const clickPosting = (order: OrderItemState) => {
    setClickedItem(order);
    setOpenDialog(true);
  };
  const clickReceivedPackage = (order: OrderItemState) => {
    setClickedItem(order);
    setOpenDialog(true);
  };
  const setNextState = async () => {
    if (!clickedItem) {
      return;
    }
    setOpenDialog(false);
    setOpenProgress(true);
    todoCallback && (await todoCallback(clickedItem));
    setOpenProgress(false);
  };
  const setCancelState = async () => {
    if (!clickedItem) {
      return;
    }
    setCancelSecondDialog(false);
    setOpenProgress(true);
    cancelCallback && (await cancelCallback(clickedItem.orderId, reason));
    setOpenProgress(false);
  };

  //
  return (
    <div>
      <>
        <DialogOkCancel
          open={openDialog}
          title={'「' + todoName + '済」にしますか？'}
          callbackOk={setNextState}
          callbackCancel={() => setOpenDialog(false)}
        />
        <DialogOkCancel
          open={cancelDialog}
          title={'注文取消'}
          callbackOk={() => {
            setCancelDialog(false);
            setCancelSecondDialog(true);
          }}
          callbackCancel={() => setCancelDialog(false)}
          textOk="確認"
          textCancel="キャンセル"
          isAutoFocusCancel={true}
        >
          <DialogContent>
            <CancelReasonInputForm setReason={setReason} />
          </DialogContent>
        </DialogOkCancel>
        <DialogOkCancel
          open={cancelSecondDialog}
          title={'本当に注文を取り消しますか？'}
          callbackOk={setCancelState}
          callbackCancel={() => setCancelSecondDialog(false)}
          textOk="はい"
          textCancel="いいえ"
          isAutoFocusCancel={true}
        >
          <DialogContent>
            <h3>キャンセル理由</h3>
            <Alert severity="info">{reason}</Alert>
          </DialogContent>
        </DialogOkCancel>
        <OrderCustomerDetailDialog
          open={detailDialog}
          order={orderDetail}
          callbackOnClose={() => setDetailDialog(false)}
        />
        <DialogOrderInfo
          open={infoDialog}
          order={orderDetail}
          callbackOnClose={() => setInfoDialog(false)}
        />
        <OrderChangeDialog
          open={changeDialog}
          order={orderDetail}
          callbackOnClose={() => setChangeDialog(false)}
        />
        <OrderStockOutDialog
          open={stockOutDialog}
          order={orderDetail}
          callbackOnClose={() => setStockOutDialog(false)}
        />
        <Backdrop open={openProgress} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress />
        </Backdrop>
      </>
      {
        // TODO: このコンポーネントの責務が大きすぎるので、分割する必要がある。別途タスクで対応する。
        // eslint-disable-next-line complexity
        orders.map((order) => (
          <Accordion
            key={order.orderId}
            expanded={expanded === order.orderId}
            onChange={handleChange(order.orderId)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {isSp ? (
                <div className="flex gap-2">
                  <div className="w-16 text-sm break-words">
                    <p>受付番号</p>
                    <p>{order.receiptNumber}</p>
                  </div>
                  <div className="w-20 text-sm break-words">
                    <p>担当者</p>
                    <p>{order.shopUserInfo.staffName}</p>
                  </div>
                  <div className="w-20 text-sm break-words">
                    <p>配送時刻</p>
                    <div>
                      {order.deliveryTime && (
                        <>
                          <p>{order.deliveryTime.date.toLocaleDateString()}</p>
                          <span>{order.deliveryTime.toStringTime('-')}</span>
                        </>
                      )}
                      {order.deliveryTime === undefined && (
                        <>
                          <span className="text-sm">いますぐ</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="w-20 text-sm">
                    <p>注文者</p>
                    <p>{`${order.userInfo.surName ?? ''} ${order.userInfo.givenName ?? ''}`}</p>
                  </div>
                </div>
              ) : (
                <>
                  <div className="w-1/4">受付番号 : {order.receiptNumber}</div>
                  <div className="w-1/4">担当者 : {order.shopUserInfo.staffName}</div>
                  <div className="w-1/4">
                    配送時刻
                    {order.deliveryTime && (
                      <>
                        <span className="ml-4 font-bold">
                          {order.deliveryTime.date.toLocaleDateString()}
                        </span>
                        <span className="ml-4 font-bold">
                          {order.deliveryTime.toStringTime('～')}
                        </span>
                      </>
                    )}
                    {order.deliveryTime === undefined && (
                      <>
                        <span className="ml-4 font-bold">いますぐ</span>
                      </>
                    )}
                  </div>
                  <div>
                    注文者
                    <span className="ml-4 font-bold">
                      {order.userInfo.isVendingMachine ? <FoodBankIcon /> : <PersonIcon />}
                      {`${order.userInfo.surName ?? ''} ${order.userInfo.givenName ?? ''}`}
                    </span>
                  </div>
                </>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex gap-1">
                <div className="w-1/3 flex flex-col">
                  {todoName &&
                    order.state !== 'onDelivery' &&
                    order.state !== 'arrived' &&
                    order.state !== 'posted' && (
                      <div className="mb-2">
                        <Button variant="contained" onClick={() => clickTodo(order)}>
                          <span className="text-sm md:text-lg">{todoName}する</span>
                        </Button>
                      </div>
                    )}
                  <div className="mb-2">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => clickDetail(order)}
                    >
                      <span className="text-sm md:text-lg">注文者詳細</span>
                    </Button>
                  </div>
                  <div className="mb-1 mt-2 flex items-center">
                    <div
                      className="text-xs italic cursor-pointer underline"
                      onClick={() => clickInfo(order)}
                    >
                      {order.orderId}
                    </div>
                  </div>
                  <div className="mb-1 flex items-end">
                    <div className="w-20 text-sm">商品点数</div>
                    <div className="text-md md:text-xl font-bold text-warning">
                      {order.totalCount}
                    </div>
                  </div>
                  <div className="mb-1 flex items-end">
                    <div className="w-16 md:w-20 text-sm">送料</div>
                    <div>
                      <Price price={order.deliveryFee} />
                    </div>
                  </div>
                  {order.discount !== 0 && (
                    <div className="mb-1 flex items-end">
                      <div className="w-16 md:w-20 text-sm">割引</div>
                      <div>
                        <Price price={order.discount} />
                      </div>
                    </div>
                  )}
                  <div className="mb-1 flex items-end">
                    <div className="w-16 md:w-20 text-sm">合計金額</div>
                    <div>
                      <Price price={order.totalPrice} />
                    </div>
                  </div>
                  <div className="mb-1 flex items-center">
                    <div className="w-20 text-sm">配送先</div>
                    <p className="text-xs md:text-md break-words">{order.deliveryTo}</p>
                  </div>
                  {order.timePosted && order.boxId && (
                    <div className="mb-1 flex items-center">
                      <div className="w-20 text-sm">投函先BOX</div>
                      <div className="text-xs">{order.boxId}</div>
                    </div>
                  )}
                  <div className="mb-1 mt-2 flex items-center">
                    <div className="w-20 text-sm">注文</div>
                    <div className="text-xs">{order.timePaying.toLocaleString()}</div>
                  </div>
                  {order.timeAccepted && (
                    <div className="mb-1 flex items-center">
                      <div className="w-20 text-sm">注文受理</div>
                      <div className="text-xs">{order.timeAccepted.toLocaleString()}</div>
                    </div>
                  )}
                  {order.timeOnDelivery && (
                    <div className="mb-1 flex items-center">
                      <div className="w-20 text-sm">発送</div>
                      <div className="text-xs">{order.timeOnDelivery.toLocaleString()}</div>
                    </div>
                  )}
                  {order.timePosted && (
                    <div className="mb-1 flex items-center">
                      <div className="w-20 text-sm">到着</div>
                      <div className="text-xs">{order.timePosted.toLocaleString()}</div>
                    </div>
                  )}
                  {order.timeCompleted && (
                    <div className="mb-1 flex items-center">
                      <div className="w-20 text-sm">受取</div>
                      <div className="text-xs">{order.timeCompleted.toLocaleString()}</div>
                    </div>
                  )}
                  {order.timeCancelled && (
                    <div className="mb-1 flex items-center">
                      <div className="w-20 text-sm">キャンセル</div>
                      <div className="text-xs">
                        {order.timeCancelled.toLocaleString()}
                        <br /> {order.cancellationReason}
                      </div>
                    </div>
                  )}
                  {isCancelButtonVisible === true && (
                    <div className="mt-2">
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: 'white',
                          color: 'black',
                          '&:hover': { background: 'gray' },
                        }}
                        onClick={() => clickCancel(order)}
                      >
                        <span className="text-sm">注文取消</span>
                      </Button>
                    </div>
                  )}
                </div>

                <div className="w-2/3 flex flex-col">
                  {order.items.map((item) => (
                    <div
                      key={order.orderId + item.itemId}
                      className="flex mb-2 w-full items-center rounded-md shadow shadow-gray-300"
                    >
                      <React.Suspense>
                        <OrderItemDetail
                          itemId={item.itemId}
                          count={item.count}
                          price={item.price}
                          isStockOut={
                            order.stockOutItems?.some((stockOutItem) => {
                              return stockOutItem.itemId === item.itemId;
                            }) ?? false
                          }
                        />
                      </React.Suspense>
                    </div>
                  ))}
                  <div className="flex flex-row gap-2">
                    {(order.state === 'credited' ||
                      order.state === 'accepted' ||
                      order.state === 'assigned' ||
                      order.state === 'waitingCustomerByStockOut') && (
                      <Button variant="contained">
                        <span className="text-sm md:text-lg" onClick={() => clickChange(order)}>
                          注文内容変更
                        </span>
                      </Button>
                    )}
                    {(order.state === 'accepted' || order.state === 'assigned') && (
                      <Button variant="contained" onClick={() => clickStockOut(order)}>
                        <span className="text-sm md:text-lg">欠品を連絡</span>
                      </Button>
                    )}
                  </div>

                  {order.state === 'onDelivery' && (
                    <div>
                      <Button variant="contained" onClick={() => clickArrive(order)}>
                        <span className="text-lg">Boxに到着</span>
                      </Button>
                    </div>
                  )}
                  {order.state === 'arrived' && (
                    <div>
                      <Button variant="contained" onClick={() => clickPosting(order)}>
                        <span className="text-lg">Boxに投函完了</span>
                      </Button>
                    </div>
                  )}
                  {order.state === 'posted' && (
                    <div>
                      <Button variant="contained" onClick={() => clickReceivedPackage(order)}>
                        <span className="text-lg">商品受取済に変更</span>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        ))
      }
    </div>
  );
};

const Price = ({ price }: { price: number }) => {
  return (
    <span>
      <span className="font-bold text-sm md:text-lg">{price.toLocaleString()}</span>
      <span className="text-sm"> 円</span>
    </span>
  );
};

// 1番目の商品画像を表示する
// 複数の画像を表示するようにしたい
const OrderItemDetail = ({
  itemId,
  count,
  price,
  isStockOut,
}: {
  itemId: string;
  count: number;
  price: number;
  isStockOut?: boolean;
}) => {
  const item = dataItem.useShopItemMaster(itemId) ? dataItem.useShopItemMaster(itemId) : '';
  const makerId: string = item && item.makerId ? item.makerId : '';
  const itemName = item && item.name ? item.name : '';
  const makerName: string | undefined = dataMaker.useShopItemMaker(makerId)?.makerName;

  return (
    <>
      <React.Suspense>
        <img
          alt="商品画像"
          src={item && item.image ? item.image[0] : ''}
          className="rounded-l-md object-cover h-full w-16 sm:w-24"
        />
      </React.Suspense>
      <div className="flex flex-col pl-4 text-sm sm:text-md">
        {isStockOut && <div className="font-bold text-warning">欠品連絡済</div>}
        <div>{itemId}</div>
        {makerName}
        <div>{itemName}</div>
        <div>
          <span className="text-xl font-bold text-warning">{count}</span>
          <span className="text-sm mr-8"> 個</span>
          <Price price={price} />
        </div>
      </div>
    </>
  );
};

const OrderSwitchButton = () => {
  const [isFiltered, setIsFiltered] = dataOrder.useIsFilteredOrderByLastModified();

  return (
    <div className="flex justify-end my-2">
      <Button variant="contained" onClick={() => setIsFiltered(!isFiltered)}>
        {isFiltered ? '過去の注文をすべて表示' : '5日前までの注文のみ表示'}
      </Button>
    </div>
  );
};
