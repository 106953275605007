import { atomFamily, useRecoilCallback } from 'recoil';
import { api, ApiStock } from './Api';
import { RecoilKeys } from './RecoilKeys';
import { shopSelectedIdState } from './Shop';

export const stocksState = atomFamily<ApiStock[], string>({
  key: RecoilKeys.STOCK_STATE,
  default: [],
  effects: (shopId: string) => [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          setSelf(await api.FetchStocks(shopId));
        };
        initialize();
      }
    },
  ],
});

const useUpsertStock = () =>
  useRecoilCallback(({ set, snapshot }) => async (itemId: string, difference: number) => {
    const shopId = await snapshot.getPromise(shopSelectedIdState);

    await api.UpsertStock({
      shopId,
      itemId,
      difference,
    });

    set(stocksState(shopId), (currentStocks) => {
      let isUpdate = false;
      const updatedStocks = currentStocks.map((currentStock) => {
        if (currentStock.itemId === itemId) {
          isUpdate = true;
          return {
            ...currentStock,
            freeStockCount: Number(currentStock.freeStockCount) + Number(difference),
            totalStockCount: Number(currentStock.totalStockCount) + Number(difference),
          };
        }
        return currentStock;
      });

      if (!isUpdate) {
        updatedStocks.push({
          itemId,
          freeStockCount: difference,
          totalStockCount: difference,
          reservedStockCount: 0,
          soldStockCount: 0,
        });
      }

      return updatedStocks;
    });
  });

//在庫情報を更新
const useStockRefresh = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const selectedShopId = await snapshot.getPromise(shopSelectedIdState);
        set(stocksState(selectedShopId), await api.FetchStocks(selectedShopId));
      },
    []
  );

export const dataStock = {
  useUpsertStock,
  useStockRefresh,
};
