import { Moment } from 'moment';
import { api } from './Api';
import { atomFamily, useRecoilCallback, useRecoilValue } from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { shopSelectedIdState } from './Shop';

export const aggregateDailySales = async ({
  shopId,
  dateFrom,
  dateTo,
}: {
  shopId: string;
  dateFrom: Moment;
  dateTo: Moment;
}) => {
  const csvString = await api.AggregateDailySales({
    shopId,
    dateFrom: dateFrom.unix(),
    dateTo: dateTo.endOf('day').unix(),
  });
  csvDownload(`${dateFrom.format('YYYY-MM-DD')}~${dateTo.format('YYYY-MM-DD')}_総売上`, csvString);
};

export const aggregateMonthlySales = async ({ shopId }: { shopId: string }) => {
  const csvString = await api.AggregateMonthlySales({
    shopId,
  });
  csvDownload(`月単位の売上推移`, csvString);
};

export const aggregateDailyUsers = async ({
  dateFrom,
  dateTo,
}: {
  dateFrom: Moment;
  dateTo: Moment;
}) => {
  const csvString = await api.AggregateDailyUsers({
    dateFrom: dateFrom.unix(),
    dateTo: dateTo.endOf('day').unix(),
  });
  csvDownload(
    `${dateFrom.format('YYYY-MM-DD')}~${dateTo.format('YYYY-MM-DD')}_新規ユーザ数`,
    csvString
  );
};

export const aggregateMonthlyUsers = async () => {
  const csvString = await api.AggregateMonthlyUsers();
  csvDownload(`月単位の新規ユーザ数`, csvString);
};

export const aggregateItems = async ({
  shopId,
  dateFrom,
  dateTo,
}: {
  shopId: string;
  dateFrom: Moment;
  dateTo: Moment;
}) => {
  const csvString = await api.AggregateItems({
    shopId,
    dateFrom: dateFrom.unix(),
    dateTo: dateTo.endOf('day').unix(),
  });
  csvDownload(
    `${dateFrom.format('YYYY-MM-DD')}~${dateTo.format('YYYY-MM-DD')}_商品ごとの総売上`,
    csvString
  );
};

export const aggregateOrders = async ({
  shopId,
  dateFrom,
  dateTo,
}: {
  shopId: string;
  dateFrom: Moment;
  dateTo: Moment;
}) => {
  const csvString = await api.AggregateOrders({
    shopId,
    dateFrom: dateFrom.unix(),
    dateTo: dateTo.endOf('day').unix(),
  });
  csvDownload(
    `${dateFrom.format('YYYY-MM-DD')}~${dateTo.format('YYYY-MM-DD')}_受取済注文一覧`,
    csvString
  );
};

export type Unit = 'daily' | 'monthly';

type GraphDataSales = {
  labels: string[];
  sales: number[];
  orderCounts: number[];
};

const graphDataSalesState = atomFamily<GraphDataSales, Unit>({
  key: RecoilKeys.GRAPH_DATA_SALES,
  effects: (unit) => [
    ({ setSelf, onSet, trigger, getPromise }) => {
      if (trigger === 'get') {
        // MEMO: 月単位のデータは初期化時に取得する
        if (unit === 'monthly') {
          const initialize = async () => {
            const shopId = await getPromise(shopSelectedIdState);
            const graphData = await api.GetGraphDataMonthlySales(shopId);
            setSelf(graphData);
          };
          initialize();
        }
      }
    },
  ],
});

const useSetDailyGraphDataSales = () =>
  useRecoilCallback(({ set, snapshot }) => async (dateFrom: Moment, dateTo: Moment) => {
    const shopId = await snapshot.getPromise(shopSelectedIdState);
    const graphData = await api.GetGraphDataDailySales({
      shopId: shopId,
      dateFrom: dateFrom.unix(),
      dateTo: dateTo.endOf('day').unix(),
    });
    set(graphDataSalesState('daily'), graphData);
  });

const useSetMonthlyGraphDataSales = () =>
  useRecoilCallback(({ set, snapshot }) => async () => {
    const shopId = await snapshot.getPromise(shopSelectedIdState);
    const graphData = await api.GetGraphDataMonthlySales(shopId);
    set(graphDataSalesState('monthly'), graphData);
  });

type GraphDataUsers = {
  labels: string[];
  userCounts: number[];
};
const graphDataUsersState = atomFamily<GraphDataUsers, Unit>({
  key: RecoilKeys.GRAPH_DATA_USERS,
  effects: (unit) => [
    ({ setSelf, onSet, trigger, getPromise }) => {
      if (trigger === 'get') {
        // MEMO: 月単位のデータは初期化時に取得する
        if (unit === 'monthly') {
          const initialize = async () => {
            const graphData = await api.GetGraphDataMonthlyUsers();
            setSelf(graphData);
          };
          initialize();
        }
      }
    },
  ],
});

const useSetDailyGraphDataUsers = () =>
  useRecoilCallback(({ set, snapshot }) => async (dateFrom: Moment, dateTo: Moment) => {
    const graphData = await api.GetGraphDataDailyUsers({
      dateFrom: dateFrom.unix(),
      dateTo: dateTo.endOf('day').unix(),
    });
    set(graphDataUsersState('daily'), graphData);
  });

const useSetMonthlyGraphDataUsers = () =>
  useRecoilCallback(({ set, snapshot }) => async () => {
    const graphData = await api.GetGraphDataMonthlyUsers();
    set(graphDataUsersState('monthly'), graphData);
  });

const csvDownload = (fileName: string, csvString: string) => {
  const utf8Bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([utf8Bom, csvString], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = `${fileName}.csv`;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const dataAggregate = {
  useGraphDataSales: (unit: Unit) => useRecoilValue(graphDataSalesState(unit)),
  useSetDailyGraphDataSales,
  useSetMonthlyGraphDataSales,
  useGraphDataUsers: (unit: Unit) => useRecoilValue(graphDataUsersState(unit)),
  useSetDailyGraphDataUsers,
  useSetMonthlyGraphDataUsers,
};
