import axios from 'axios';
import { Auth } from 'aws-amplify';
import { CONST } from '../global';
import { OrderItem } from './Order';

axios.interceptors.request.use(
  async (config) => {
    const user = await Auth.currentAuthenticatedUser();

    // APIを使用する場合
    if (config.url?.startsWith('v1')) {
      config.url = CONST.API_ENDPOINT + config.url;
      config.headers['Authorization'] = user.signInUserSession.idToken.jwtToken;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    console.log('API response:', res);
    return res;
  },
  (err) => {
    console.log('API error');
    console.log('Config', err.config);
    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log('Request', err.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error message', err.message);
    }
    return Promise.reject(err);
  }
);

type ApiOrderItem = {
  itemId: string;
  count: number;
  price: number;
  name: string;
  maker: string;
  image: string;
};

type ApiOrder = {
  orderId: string;
  state: string;
  storeId: number;
  items: ApiOrderItem[];
  price: number;
  deliveryFee: number;
  deliveryFeeId: string;
  discount: number;
  discountId: string;
  deliveryTimeBegin: number;
  deliveryTimeEnd: number;
  deliveryTo: string;
  lastModified: number;

  //   timePreOrder: number;
  timePaying: number;
  timeCredited?: number;
  timeAccepted?: number;
  timeOnDelivery?: number;
  timeArrived?: number;
  timePosted?: number;
  timeCompleted?: number;
  timePayingTimeout?: number;
  timeFailed?: number;
  timeCancelled?: number;

  userInfo: {
    givenName?: string;
    surName?: string;
    phone?: string;
    email?: string;
    isVendingMachine: boolean;
  };

  shopUserInfo: { Name: string; Value: string }[] | undefined;

  boxId?: string;
  cancelCause?: string;

  receiptNumber?: string;
  boxKey?: string;
  cancellationReason?: string;
  stockOutItems?: OrderItem[];
  phoneNumber?: string;
  shopId?: string;
  deliverySpotId?: string;
  paymentError?: string;
  assignedBy?: string;
  trackShipments: string[];
};

type ApiOffer = {
  itemId: string;
  //message?: string;
};

export type ApiItem = {
  itemId: string;
  allergen: string;
  bestByDate: string;
  brand: string;
  categoryId: string;
  copy: string;
  description: string;
  expiration: string;
  limitedStock?: number;
  image?: string[];
  makerId: string;
  name: string;
  price: number;
  priority: number;
  size: number;
  visible: boolean;
  weight: number;
  TTL?: number;
  removedBy?: string;
};

type ApiItemResponse = {
  itemId: string;
  allergen: string;
  bestByDate: string;
  brand: string;
  categoryId: string;
  copy: string;
  description: string;
  expiration: string;
  limitedStock?: string;
  image?: string[];
  makerId: string;
  name: string;
  price: string;
  priority: string;
  size: string;
  visible: string;
  weight: string;
  TTL?: string;
  removedBy?: string;
};

type ApiImageUpdate = {
  presignedPost: {
    url: string;
    fields: { [key: string]: string };
  };
  image: string[];
};

type ApiCategory = {
  categoryId: string;
  categoryName: string;
  priority: number;
  visible: boolean;
  TTL?: number;
  removedBy?: string;
};

type ApiMaker = {
  makerId: string;
  makerName: string;
  image: string[];
  businessHours?: string;
  holiday?: string;
  postalCode?: string;
  address?: string;
  phone?: string;
  url?: string;
  latitude?: string;
  longitude?: string;
  description?: string;
};

export type ApiStock = {
  itemId: string;
  freeStockCount: number;
  reservedStockCount: number;
  soldStockCount: number;
  totalStockCount: number;
};

export type ApiCapacity = {
  spotId: string;
  deliveryTimeBegin: number;
  free: number;
  TTL?: number;
  removedBy?: string;
};

type ApiCapacityResponse = {
  spotId: string;
  deliveryTimeBegin: string;
  free: string;
  TTL?: string;
  removedBy?: string;
};

export type ApiDroneSlot = {
  baseId: string;
  deliveryTimeBegin: number;
  free: number;
  TTL?: number;
  removedBy?: string;
};

type ApiDroneSlotResponse = {
  baseId: string;
  deliveryTimeBegin: string;
  free: string;
  TTL?: string;
  removedBy?: string;
};

export type ApiBoxState = {
  boxId: string;
  orderId: string;
  state: string;
  spotId: string;
  TTL?: number;
  removedBy?: string;
};

export interface DroneBase {
  baseId: string;
  name: string;
}

export type ApiShop = {
  shopId: string;
  shopName: string;
  latitude: string;
  longitude: string;
  image: string[];
  spotId: string[];
  bases: DroneBase[];
};

export type ApiSpot = {
  spotId: string;
  spotName: string;
  latitude: string;
  longitude: string;
  image: string[];
  bases: DroneBase[];
};

export type ApiUser = {
  Username: string;
  Attributes: { Name: string; Value: string }[];
  Enabled: boolean;
};

export type ApiStaff = {
  staffId: string;
  staffName: string;
  phoneNumber: string;
};

export type ApiUpdateOrderItemsArg = {
  orderId: string;
  price: number;
  items: {
    itemId: string;
    name: string;
    price: number;
    count: number;
  }[];
};

export type ApiAdministratorMail = {
  title: string;
  message: string;
  type: string;
};

type ApiAdministratorMailRet = {
  status: string;
  list: [string, string][];
};

type ApiAppMessage = {
  messageId: string;
  message: string;
  TTL?: number;
  removedBy?: string;
};

const fetchShops = async (): Promise<ApiShop[]> => {
  return axios
    .get(`v1/shops`)
    .then((res) => {
      return res.data.message;
    })
    .catch((err) => {
      return {};
    });
};

async function ListOrderShop(shopId: string, lastModified = 0): Promise<ApiOrder[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };

  const updateTime = Date.now();
  const { status, data } = await axios.get(`v1/orders/${shopId}/${lastModified}`, config);
  console.log(`ListOrders : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  const orders = data.map((order: ApiOrder) => {
    order.storeId = Number(order.storeId);

    order.items = order.items.map((item) => {
      item.count = Number(item.count);
      item.price = Number(item.price);
      return item;
    });

    order.price = Number(order.price);
    order.deliveryFee = Number(order.deliveryFee);
    order.discount = Number(order.discount);
    order.deliveryTimeBegin = Number(order.deliveryTimeBegin);
    order.deliveryTimeEnd = Number(order.deliveryTimeEnd);
    order.lastModified = Number(order.lastModified);

    order.timePaying = Number(order.timePaying);
    order.timeCredited = order.timeCredited ? Number(order.timeCredited) : undefined;
    order.timeAccepted = order.timeAccepted ? Number(order.timeAccepted) : undefined;
    order.timeOnDelivery = order.timeOnDelivery ? Number(order.timeOnDelivery) : undefined;
    order.timeArrived = order.timeArrived ? Number(order.timeArrived) : undefined;
    order.timePosted = order.timePosted ? Number(order.timePosted) : undefined;
    order.timeCompleted = order.timeCompleted ? Number(order.timeCompleted) : undefined;
    order.timePayingTimeout = order.timePayingTimeout ? Number(order.timePayingTimeout) : undefined;
    order.timeFailed = order.timeFailed ? Number(order.timeFailed) : undefined;
    order.timeCancelled = order.timeCancelled ? Number(order.timeCancelled) : undefined;

    return order;
  });
  return orders;
}

async function UpsertShop(shop: ApiShop): Promise<ApiShop> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post('v1/shops/upsert', shop, config);
  if (status !== 200) {
    console.error('upsert shop failed');
  }
  return message;
}

async function AddShopImage(
  shopId: string,
  images: string[],
  contentType: string,
  ext: string
): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/shops/add-image',
    { shopId: shopId, image: images, contentType: contentType, ext: ext },
    config
  );
  if (status !== 200) {
    console.error('add image failed');
  }
  return message;
}

async function ChangeShopImage(
  shopId: string,
  changeTargetUrl: string,
  images: string[],
  contentType: string,
  ext: string
): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/shops/change-image',
    {
      shopId: shopId,
      changeTargetUrl: changeTargetUrl,
      image: images,
      contentType: contentType,
      ext: ext,
    },
    config
  );
  if (status !== 200) {
    console.error('change image failed');
  }
  return message;
}

async function DeleteShopImage(
  shopId: string,
  deleteTargetUrl: string,
  images: string[]
): Promise<string[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/shops/delete-image',
    {
      shopId: shopId,
      deleteTargetUrl: deleteTargetUrl,
      image: images,
    },
    config
  );
  if (status !== 200) {
    console.error('delete image failed');
  }
  return message;
}

async function UpdateShopImage(shopId: string, images: string[]): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/shops/update-image',
    {
      shopId: shopId,
      image: images,
    },
    config
  );
  if (status !== 200) {
    console.error('update image failed');
  }
  return message;
}

const fetchSpots = async (): Promise<ApiSpot[]> => {
  return axios
    .get('v1/spots')
    .then((res) => {
      console.log('fetchSpots');
      console.log(res);
      return res.data.message.map((x: ApiSpot) => ({
        ...x,
      }));
    })
    .catch((err) => {
      return [];
    });
};

async function UpsertSpot(shopId: string, spot: ApiSpot): Promise<ApiSpot> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post('v1/spots/upsert', { ...spot, shopId }, config);
  if (status !== 200) {
    console.error('create offer failed');
  }
  return message;
}

async function DeleteSpot(shopId: string, spotId: string) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post('v1/spots/delete', { spotId, shopId }, config);
  if (status !== 200) {
    console.error('delete offer failed');
  }
  return data;
}

async function AddSpotImage(
  spotId: string,
  images: string[],
  contentType: string,
  ext: string
): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/spots/add-image',
    { spotId: spotId, image: images, contentType: contentType, ext: ext },
    config
  );
  if (status !== 200) {
    console.error('add image failed');
  }
  return message;
}

async function ChangeSpotImage(
  spotId: string,
  changeTargetUrl: string,
  images: string[],
  contentType: string,
  ext: string
): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/spots/change-image',
    {
      spotId: spotId,
      changeTargetUrl: changeTargetUrl,
      image: images,
      contentType: contentType,
      ext: ext,
    },
    config
  );
  if (status !== 200) {
    console.error('change image failed');
  }
  return message;
}

async function DeleteSpotImage(
  spotId: string,
  deleteTargetUrl: string,
  images: string[]
): Promise<string[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/spots/delete-image',
    {
      spotId: spotId,
      deleteTargetUrl: deleteTargetUrl,
      image: images,
    },
    config
  );
  if (status !== 200) {
    console.error('delete image failed');
  }
  return message;
}

async function UpdateSpotImage(spotId: string, images: string[]): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/spots/update-image',
    {
      spotId: spotId,
      image: images,
    },
    config
  );
  if (status !== 200) {
    console.error('update image failed');
  }
  return message;
}

async function PutOrderState(
  orderId: string,
  state: string,
  cancellationReason?: string,
  stockOutItems?: OrderItem[]
): Promise<ApiOrder> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };

  const putData = {
    orderId: orderId,
    state: state,
    cancellationReason: cancellationReason,
    stockOutItems: stockOutItems,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.put('v1/orders/', putData, config);
  console.log(`PutOrderState : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);

  return data;
}

async function UpdateOrderItems(arg: ApiUpdateOrderItemsArg) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  // TODO itemsの変換が必要か考える
  const payload = {
    ...arg,
    price: arg.price.toString(),
    items: arg.items.map((item) => ({
      ...item,
    })),
  };
  const { status, data } = await axios.put('v1/orders/update_items', payload, config);
  if (status !== 200) {
    console.error('update order items failed');
  }
  return data;
}

async function FetchOffers(shopId: string): Promise<ApiOffer[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    data: { message },
  } = await axios.get(`v1/offers/shopId/${shopId}`, config);
  return message;
}

async function CreateOffer(shopId: string, itemId: string) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post('v1/offers/create', { shopId, itemId }, config);
  if (status !== 200) {
    console.error('create offer failed');
  }
  return data;
}

async function DeleteOffer(shopId: string, itemId: string) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.delete(`v1/offers/delete/${shopId}/${itemId}`, config);
  if (status !== 200) {
    console.error('delete offer failed');
  }
  return data;
}

async function FetchItems(): Promise<ApiItem[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    data: { message },
  } = await axios.get<{ message: ApiItemResponse[] }>('v1/items/', config);
  const items: ApiItem[] = message.map((item: ApiItemResponse) => {
    return {
      ...item,
      price: Number(item.price),
      priority: Number(item.priority),
      size: Number(item.size),
      weight: Number(item.weight),
      limitedStock: item.limitedStock !== undefined ? Number(item.limitedStock) : undefined,
      TTL: item.TTL !== undefined ? Number(item.TTL) : undefined,
      visible: Boolean(item.visible),
    };
  });

  return items;
}

async function CreateItem(item: ApiItem) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const itemForApi = {
    ...item,
    ...(item.limitedStock !== undefined && { limitedStock: String(item.limitedStock) }),
    price: String(item.price),
    priority: String(item.priority),
    size: String(item.size),
    weight: String(item.weight),
    visible: String(item.visible),
    ...(item.TTL !== undefined && { TTL: String(item.TTL) }),
    ...(item.removedBy !== undefined && { removedBy: String(item.removedBy) }),
  };
  const { status, data } = await axios.post('v1/items/create', itemForApi, config);
  if (status !== 200) {
    console.error('create item failed');
  }
  if (data.status === 'error') {
    throw new Error(data.message);
  }
  return data;
}

async function UpdateItem(item: ApiItem) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const itemForApi = {
    ...item,
    ...(item.limitedStock !== undefined && { limitedStock: String(item.limitedStock) }),
    price: String(item.price),
    priority: String(item.priority),
    size: String(item.size),
    weight: String(item.weight),
    visible: String(item.visible),
    ...(item.TTL !== undefined && { TTL: String(item.TTL) }),
    ...(item.removedBy !== undefined && { removedBy: String(item.removedBy) }),
  };
  const { status, data } = await axios.post('v1/items/update', itemForApi, config);
  if (status !== 200) {
    console.error('update item failed');
  }
  return data;
}

async function DeleteItem(itemId: string): Promise<boolean> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.delete(`v1/items/delete/${itemId}`, config);
  if (status !== 200) {
    console.error('delete items failed');
    return false;
  }
  if (status === 200 && message === 'delete success') {
    return true;
  } else {
    return false;
  }
}

async function AddItemImage(
  itemId: string,
  images: string[],
  contentType: string,
  ext: string
): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/items/add-image',
    { itemId: itemId, image: images, contentType: contentType, ext: ext },
    config
  );
  if (status !== 200) {
    console.error('add image failed');
  }
  return message;
}

async function ChangeItemImage(
  itemId: string,
  changeTargetUrl: string,
  images: string[],
  contentType: string,
  ext: string
): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/items/change-image',
    {
      itemId: itemId,
      changeTargetUrl: changeTargetUrl,
      image: images,
      contentType: contentType,
      ext: ext,
    },
    config
  );
  if (status !== 200) {
    console.error('change image failed');
  }
  return message;
}

async function DeleteItemImage(
  itemId: string,
  deleteTargetUrl: string,
  images: string[]
): Promise<string[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/items/delete-image',
    {
      itemId: itemId,
      deleteTargetUrl: deleteTargetUrl,
      image: images,
    },
    config
  );
  if (status !== 200) {
    console.error('delete image failed');
  }
  return message;
}

async function UpdateItemImage(itemId: string, images: string[]): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/items/update-image',
    {
      itemId: itemId,
      image: images,
    },
    config
  );
  if (status !== 200) {
    console.error('update image failed');
  }
  return message;
}

async function FetchCategories(shopId: string): Promise<ApiCategory[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    data: { message },
  } = await axios.get(`v1/categories/shopId/${shopId}`, config);
  return message.map((category: ApiCategory) => ({
    ...category,
    priority: Number(category.priority),
    visible: Boolean(category.visible),
    TTL: category.TTL ? Number(category.TTL) : undefined,
  }));
}

async function UpsertCategory(shopId: string, category: ApiCategory): Promise<ApiCategory> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const categoryAsString = {
    categoryId: category.categoryId,
    categoryName: category.categoryName,
    priority: category.priority.toString(),
    visible: category.visible.toString(),
    ...(category.TTL !== undefined && { TTL: category.TTL.toString() }),
    ...(category.removedBy !== undefined && { removedBy: category.removedBy }),
  };
  const {
    status,
    data: { message },
  } = await axios.post('v1/categories/upsert', { shopId, ...categoryAsString }, config);
  if (status !== 200) {
    console.error('upsert category failed');
  }
  return message;
}

async function ImportCategory({
  fromShopId,
  toShopId,
}: {
  fromShopId: string;
  toShopId: string;
}): Promise<boolean> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  try {
    const {
      status,
      data: { message },
    } = await axios.post('v1/categories/import', { fromShopId, toShopId }, config);

    if (status === 200 && message === 'Success') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('An unknown error occurred');
    }
    return false;
  }
}

async function DeleteCategory(shopId: string, categoryId: string): Promise<boolean> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  try {
    const {
      status,
      data: { message },
    } = await axios.delete(`v1/categories/delete/${shopId}/${categoryId}`, config);

    if (status === 200 && message === 'delete success') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('An unknown error occurred');
    }
    return false;
  }
}

async function FetchMakers(): Promise<ApiMaker[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    data: { message },
  } = await axios.get('v1/makers/', config);
  return message;
}

async function UpsertMaker(maker: ApiMaker): Promise<ApiMaker> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post('v1/makers/upsert', maker, config);
  if (status !== 200) {
    console.error('create offer failed');
  }
  return message;
}

async function AddMakerImage(
  makerId: string,
  images: string[],
  contentType: string,
  ext: string
): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/makers/add-image',
    { makerId: makerId, image: images, contentType: contentType, ext: ext },
    config
  );
  if (status !== 200) {
    console.error('add image failed');
  }
  return message;
}

async function ChangeMakerImage(
  makerId: string,
  changeTargetUrl: string,
  images: string[],
  contentType: string,
  ext: string
): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/makers/change-image',
    {
      makerId: makerId,
      changeTargetUrl: changeTargetUrl,
      image: images,
      contentType: contentType,
      ext: ext,
    },
    config
  );
  if (status !== 200) {
    console.error('change image failed');
  }
  return message;
}

async function DeleteMakerImage(
  makerId: string,
  deleteTargetUrl: string,
  images: string[]
): Promise<string[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/makers/delete-image',
    {
      makerId: makerId,
      deleteTargetUrl: deleteTargetUrl,
      image: images,
    },
    config
  );
  if (status !== 200) {
    console.error('delete image failed');
  }
  return message;
}

async function UpdateMakerImage(makerId: string, images: string[]): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/makers/update-image',
    {
      makerId: makerId,
      image: images,
    },
    config
  );
  if (status !== 200) {
    console.error('update image failed');
  }
  return message;
}

async function FetchStocks(shopId: string): Promise<ApiStock[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    data: { message },
  } = await axios.get(`v1/stocks/shopId/${shopId}`, config);

  return message.map((stock: ApiStock) => ({
    ...stock,
    freeStockCount: Number(stock.freeStockCount),
    reservedStockCount: Number(stock.reservedStockCount),
    soldStockCount: Number(stock.soldStockCount),
    totalStockCount: Number(stock.totalStockCount),
  }));
}

async function UpsertStock({
  shopId,
  itemId,
  difference,
}: {
  shopId: string;
  itemId: string;
  difference: number;
}) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const payload = { shopId, itemId, difference: difference.toString() };
  const {
    status,
    data: { message },
  } = await axios.post('v1/stocks/upsert', payload, config);
  if (status !== 200) {
    console.error('upsert stock failed');
  }
  return message;
}

async function FetchCapacities(spotId: string): Promise<ApiCapacity[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    data: { message },
    status,
  } = await axios.get(`v1/capacity/spotId/${spotId}`, config);
  if (status !== 200) {
    console.error('get capacity failed');
    return [];
  }
  return message.map((item: ApiCapacityResponse) => ({
    spotId: item.spotId,
    deliveryTimeBegin: Number(item.deliveryTimeBegin),
    free: Number(item.free),
    TTL: item.TTL ? Number(item.TTL) : undefined,
    removedBy: item.removedBy,
  }));
}

async function FetchUserCapacities(spotId: string): Promise<ApiCapacity[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    data: { message },
    status,
  } = await axios.get(`v1/capacity/userApi/spotId/${spotId}`, config);
  if (status !== 200) {
    console.error('get capacity failed');
    return [];
  }
  return message.map((item: ApiCapacityResponse) => ({
    spotId: item.spotId,
    deliveryTimeBegin: Number(item.deliveryTimeBegin),
    free: Number(item.free),
    TTL: item.TTL ? Number(item.TTL) : undefined,
    removedBy: item.removedBy,
  }));
}

async function UpsertCapacity(spotId: string, deliveryTimeBegin: number, difference: number) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const payload = {
    spotId,
    deliveryTimeBegin: deliveryTimeBegin.toString(),
    difference: difference.toString(),
  };

  const {
    status,
    data: { message },
  } = await axios.post('v1/capacity/upsert', payload, config);

  if (status !== 200) {
    console.error('upsert capacity failed');
  }
  return message;
}

async function BatchUpsertCapacity(capacities: ApiCapacity[]) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const capacitiesAsString = capacities.map((capacity) => ({
    ...capacity,
    deliveryTimeBegin: capacity.deliveryTimeBegin.toString(),
    free: capacity.free.toString(),
    TTL: capacity.TTL ? capacity.TTL.toString() : undefined,
    removedBy: capacity.removedBy,
  }));

  const { status, data } = await axios.post(
    'v1/capacity/batchUpsert',
    { capacities: capacitiesAsString },
    config
  );
  if (status !== 200) {
    console.error('batch upsert capacity failed');
  }
  return data;
}

async function BatchDeleteCapacity(spotId: string, capacities: { deliveryTimeBegin: number }[]) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const capacitiesAsString = capacities.map((capacity) => ({
    ...capacity,
    deliveryTimeBegin: capacity.deliveryTimeBegin.toString(),
  }));
  const { status, data } = await axios.post(
    'v1/capacity/batchDelete',
    { spotId: spotId, capacities: capacitiesAsString },
    config
  );
  if (status !== 200) {
    console.error('batch delete capacity failed');
  }
  return data;
}

async function FetchDroneSlots(baseId: string): Promise<ApiDroneSlot[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    data: { message },
    status,
  } = await axios.get(`v1/drone-slots/baseId/${baseId}`, config);
  if (status !== 200) {
    console.error('get drone-slots failed');
    return [];
  }
  return message.map((item: ApiDroneSlotResponse) => ({
    baseId: item.baseId,
    deliveryTimeBegin: Number(item.deliveryTimeBegin),
    free: Number(item.free),
    TTL: item.TTL ? Number(item.TTL) : undefined,
    removedBy: item.removedBy,
  }));
}

async function UpsertDroneSlots(baseId: string, deliveryTimeBegin: number, difference: number) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const payload = {
    baseId,
    deliveryTimeBegin: deliveryTimeBegin.toString(),
    difference: difference.toString(),
  };

  const {
    status,
    data: { message },
  } = await axios.post('v1/drone-slots/upsert', payload, config);

  if (status !== 200) {
    console.error('upsert drone-slots failed');
  }
  return message;
}

async function BatchUpsertDroneSlots(droneSpots: ApiDroneSlot[]) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const capacitiesAsString = droneSpots.map((droneSpot) => ({
    ...droneSpot,
    deliveryTimeBegin: droneSpot.deliveryTimeBegin.toString(),
    free: droneSpot.free.toString(),
    TTL: droneSpot.TTL ? droneSpot.TTL.toString() : undefined,
    removedBy: droneSpot.removedBy,
  }));

  const { status, data } = await axios.post(
    'v1/drone-slots/batchUpsert',
    { 'drone-slots': capacitiesAsString },
    config
  );
  if (status !== 200) {
    console.error('batch upsert drone-slots failed');
  }
  return data;
}

async function BatchDeleteDroneSlots(shopId: string, droneSpots: { deliveryTimeBegin: number }[]) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const capacitiesAsString = droneSpots.map((droneSpot) => ({
    ...droneSpot,
    deliveryTimeBegin: droneSpot.deliveryTimeBegin.toString(),
  }));
  const { status, data } = await axios.post(
    'v1/drone-slots/batchDelete',
    { shopId: shopId, 'drone-slots': capacitiesAsString },
    config
  );
  if (status !== 200) {
    console.error('batch delete drone-slots failed');
  }
  return data;
}

async function FetchUsers(): Promise<ApiUser[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    data: { message },
    status,
  } = await axios.get(`v1/users`, config);
  if (status !== 200) {
    console.error('get users failed');
    return [];
  }
  return message;
}

async function EnableUser(Username: string): Promise<ApiShop> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post('v1/users/enable', { Username }, config);
  if (status !== 200) {
    console.error('enable user failed');
  }
  return message;
}

async function DisableUser(Username: string): Promise<ApiShop> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post('v1/users/disable', { Username }, config);
  if (status !== 200) {
    console.error('disable user failed');
  }
  return message;
}

async function DeleteUser(Username: string): Promise<ApiShop> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post('v1/users/delete', { Username }, config);
  if (status !== 200) {
    console.error('delete user failed');
  }
  return message;
}

async function FetchStaffs(): Promise<ApiStaff[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = {
    headers: { Authorization: idToken },
  };
  const {
    data: { message },
    status,
  } = await axios.get('v1/staff', config);
  if (status !== 200) {
    console.error('get staffs failed');
    return [];
  }
  return message;
}

async function UpsertStaff({
  staffName,
  phoneNumber,
}: {
  staffName: string;
  phoneNumber: string;
}): Promise<ApiImageUpdate> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    status,
    data: { message },
  } = await axios.post(
    'v1/staff/upsert',
    {
      staffName,
      phoneNumber,
    },
    config
  );
  if (status !== 200) {
    console.error('upsert staff failed');
  }
  return message;
}

async function FetchAppMessages(shopId: string): Promise<ApiAppMessage[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const {
    data: { message },
  } = await axios.get(`v1/app-msg/shopId/${shopId}`, config);
  return message.map((messages: ApiAppMessage) => ({
    ...messages,
    TTL: messages.TTL ? Number(messages.TTL) : undefined,
  }));
}

async function UpsertAppMessage(shopId: string, appMessage: ApiAppMessage): Promise<ApiAppMessage> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const appMessageAsString = {
    messageId: appMessage.messageId,
    message: appMessage.message,
  };
  const {
    status,
    data: { message },
  } = await axios.post('v1/app-msg/upsert', { shopId, ...appMessageAsString }, config);
  if (status !== 200) {
    console.error('upsert app-msg failed');
  }
  return message;
}

async function DeleteAppMessage(shopId: string, messageId: string): Promise<boolean> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  try {
    const {
      status,
      data: { message },
    } = await axios.delete(`v1/app-msg/delete/${shopId}/${messageId}`, config);

    if (status === 200 && message === 'delete success') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('An unknown error occurred');
    }
    return false;
  }
}

async function AggregateDailySales({
  shopId,
  dateFrom,
  dateTo,
}: {
  shopId: string;
  dateFrom: number;
  dateTo: number;
}): Promise<string> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post(
    `v1/aggregate/daily/sales`,
    { shopId, dateFrom, dateTo },
    config
  );
  if (status !== 200) {
    console.error('aggregate daily failed');
  }
  return data.body;
}

async function AggregateMonthlySales({ shopId }: { shopId: string }): Promise<string> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post(`v1/aggregate/monthly/sales`, { shopId }, config);
  if (status !== 200) {
    console.error('aggregate monthly failed');
  }
  return data.body;
}

async function AggregateItems({
  shopId,
  dateFrom,
  dateTo,
}: {
  shopId: string;
  dateFrom: number;
  dateTo: number;
}): Promise<string> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post(
    `v1/aggregate/items`,
    { shopId, dateFrom, dateTo },
    config
  );
  if (status !== 200) {
    console.error('aggregate items failed');
  }
  return data.body;
}

async function AggregateOrders({
  shopId,
  dateFrom,
  dateTo,
}: {
  shopId: string;
  dateFrom: number;
  dateTo: number;
}): Promise<string> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post(
    `v1/aggregate/orders`,
    { shopId, dateFrom, dateTo },
    config
  );
  if (status !== 200) {
    console.error('aggregate orders failed');
  }
  return data.body;
}

async function AggregateDailyUsers({
  dateFrom,
  dateTo,
}: {
  dateFrom: number;
  dateTo: number;
}): Promise<string> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post(
    `v1/aggregate/daily/users`,
    { dateFrom, dateTo },
    config
  );
  if (status !== 200) {
    console.error('aggregate daily users failed');
  }
  return data.body;
}

async function AggregateMonthlyUsers(): Promise<string> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post(`v1/aggregate/monthly/users`, {}, config);
  if (status !== 200) {
    console.error('aggregate monthly users failed');
  }
  return data.body;
}

async function GetGraphDataDailySales({
  shopId,
  dateFrom,
  dateTo,
}: {
  shopId: string;
  dateFrom: number;
  dateTo: number;
}): Promise<{
  labels: string[];
  sales: number[];
  orderCounts: number[];
}> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post(
    `v1/aggregate/graphData/daily/sales`,
    { shopId, dateFrom, dateTo },
    config
  );
  if (status !== 200) {
    console.error('get graph data daily sales failed');
  }
  return data.body;
}

async function GetGraphDataMonthlySales(shopId: string): Promise<{
  labels: string[];
  sales: number[];
  orderCounts: number[];
}> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post(
    `v1/aggregate/graphData/monthly/sales`,
    { shopId },
    config
  );
  if (status !== 200) {
    console.error('make graph monthly sales failed');
  }
  return data.body;
}

async function GetGraphDataDailyUsers({
  dateFrom,
  dateTo,
}: {
  dateFrom: number;
  dateTo: number;
}): Promise<{
  labels: string[];
  userCounts: number[];
}> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post(
    `v1/aggregate/graphData/daily/users`,
    { dateFrom, dateTo },
    config
  );
  if (status !== 200) {
    console.error('get graph data daily users failed');
  }
  return data.body;
}

async function GetGraphDataMonthlyUsers(): Promise<{
  labels: string[];
  userCounts: number[];
}> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post(`v1/aggregate/graphData/monthly/users`, {}, config);
  if (status !== 200) {
    console.error('make graph monthly users failed');
  }
  return data.body;
}

async function getBoxState(): Promise<ApiBoxState[]> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.get('v1/box/getBoxState', config);

  if (status !== 200) {
    console.error('get box state error');
  }

  return data;
}

async function CreateBox(spotId: string): Promise<ApiBoxState> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post('v1/box/createBox', { spotId }, config);
  if (status !== 200) {
    console.error('create box failed');
  }
  // MEMO: messageに作成されたIDが乗ってくる
  return data.message;
}

async function SetEmpty(boxId: string): Promise<string> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.post('v1/box/setEmpty', { boxId }, config);
  if (status !== 200) {
    console.error('set box as empty failed');
  }
  return data.message;
}

async function DeleteBox(boxId: string) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const { status, data } = await axios.delete(`v1/box/deleteBox/${boxId}`, config);
  if (status !== 200) {
    console.error('delete box failed');
  }
  return data;
}

async function PostAdministratorMail(
  administratorMail: ApiAdministratorMail
): Promise<ApiAdministratorMailRet> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };
  const administratorMailsString = {
    title: administratorMail.title,
    message: administratorMail.message,
    type: administratorMail.type,
  };
  const { status, data } = await axios.post(
    'v1/administrator/sendmail',
    { ...administratorMailsString },
    config
  );
  if (status !== 200) {
    console.error('post PostAdministratorMail failed');
  }
  return data;
}

//TODO : BOX APIテスト用スタブ
type ApiBox = {
  COMMAND: string;
  BOXID?: string;
};
async function GetEmptyBox(spotId: string): Promise<ApiBox> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };

  const updateTime = Date.now();
  const { status, data } = await axios.get(`v1/box/getEmptyBox/${spotId}`, config);
  console.log(`GetEmptyBox : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  return data;
}

//TODO : BOX APIテスト用スタブ
// async function SyncState() {
//   const user = await Auth.currentAuthenticatedUser();
//   const idToken = user.signInUserSession.idToken.jwtToken;
//   const config = { headers: { Authorization: idToken } };

//   const postData = {};

//   const updateTime = Date.now();
//   const { status, data } = await axios.post('v1/box/syncState', postData, config);
//   console.log(`SyncState : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
//   console.log(data);
//   // return JSON.parse(data);
// }

//TODO : BOX APIテスト用スタブ
async function SetArrived(orderId: string, boxId: string) {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };

  const postData = {
    orderId: orderId,
    boxId: boxId,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/box/setArrived', postData, config);
  console.log(`SetArrived : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  // return JSON.parse(data);
}

//TODO : BOX APIテスト用スタブ
async function ReadyForPosting(spotId: string, orderId: string) {
  const data = await GetEmptyBox(spotId);
  if (data.COMMAND === 'NONE') {
    throw new Error('空のBOXが見つかりませんでした');
  }
  if (data.COMMAND === 'RECEIVE') {
    const boxId = data.BOXID as string;
    await SetArrived(orderId, boxId);
  }
}

//TODO : BOX APIテスト用スタブ
async function GetCommand(spotId: string) {
  const getData = {};

  const updateTime = Date.now();
  const { status, data } = await axios.get(`v1/box/getCommand/${spotId}`, getData);
  console.log(`GetCommand : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  return data;
  // return JSON.parse(data);
}

//TODO : BOX APIテスト用スタブ
// async function GetAuthCode(orderId: string) {
//   const postData = {
//     orderId: orderId,
//   };

//   const updateTime = Date.now();
//   const { status, data } = await axios.post('v1/box/getAuthCode', postData);
//   console.log(`GetAuthCode : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
//   console.log(data);
//   // return JSON.parse(data);
// }

//TODO : BOX APIテスト用スタブ
async function DoChallenge(orderId: string, boxId: string, boxKey: string) {
  const postData = {
    QRDATA: orderId,
    boxId: boxId,
    boxKey: boxKey,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/box/doChallenge', postData);
  console.log(`DoChallenge : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  return data;
}

//TODO : BOX APIテスト用スタブ
async function SetPosted(boxId: string) {
  const postData = {
    boxId: boxId,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/box/setPosted', postData);
  console.log(`SetPosted : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  // return JSON.parse(data);
}

//TODO : BOX APIテスト用スタブ
async function SetCompleted(boxId: string) {
  const postData = {
    boxId: boxId,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/box/setCompleted', postData);
  console.log(`SetCompleted : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  // return JSON.parse(data);
}

async function PostingCompleted(spotId: string) {
  const data = await GetCommand(spotId);
  if (data.COMMAND === 'RECEIVE') {
    const boxId = data.BOXID as string;
    await SetPosted(boxId);
  }
}

async function ReceivedPackage(orderId: string, boxId: string, boxKey: string) {
  const data = await DoChallenge(orderId, boxId, boxKey);
  if (data.error === '') {
    const boxId = data.boxId as string;
    await SetCompleted(boxId);
  }
}

type ApiGetMFA = {
  message: string;
  PreferredMfaSetting: string;
};
async function GetMFA(): Promise<ApiGetMFA> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };

  const accessToken = user.signInUserSession.accessToken.jwtToken;

  const postData = {
    accessToken: accessToken,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/security/getMFA', postData, config);
  console.log(`getMFA : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  return data;
}

async function SetMFA(mfaMethod: string): Promise<ApiGetMFA> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };

  const accessToken = user.signInUserSession.accessToken.jwtToken;

  const postData = {
    accessToken: accessToken,
    mfa: mfaMethod,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/security/setMFA', postData, config);
  console.log(`setMFA : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  return data;
}

type ApiAssociateSoftwareToken = {
  message: string;
  totpCode: string;
  secretCode: string;
};
async function AssociateSoftwareToken(): Promise<ApiAssociateSoftwareToken> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };

  const accessToken = user.signInUserSession.accessToken.jwtToken;

  const postData = {
    accessToken: accessToken,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/security/associateSoftwareToken', postData, config);
  console.log(`associateSoftwareToken : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  return data;
}

async function VerifySoftwareToken(totp: string): Promise<ApiGetMFA> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };

  const accessToken = user.signInUserSession.accessToken.jwtToken;

  const postData = {
    accessToken: accessToken,
    userCode: totp,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/security/verifySoftwareToken', postData, config);
  console.log(`verifySoftwareToken : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  return data;
}

export const api = {
  fetchShops,
  ListOrderShop,
  UpsertShop,
  AddShopImage,
  ChangeShopImage,
  DeleteShopImage,
  UpdateShopImage,
  fetchSpots,
  UpsertSpot,
  DeleteSpot,
  AddSpotImage,
  ChangeSpotImage,
  DeleteSpotImage,
  UpdateSpotImage,
  PutOrderState,
  UpdateOrderItems,
  FetchItems,
  CreateItem,
  UpdateItem,
  DeleteItem,
  AddItemImage,
  ChangeItemImage,
  DeleteItemImage,
  UpdateItemImage,
  FetchCategories,
  UpsertCategory,
  ImportCategory,
  DeleteCategory,
  FetchOffers,
  FetchMakers,
  UpsertMaker,
  AddMakerImage,
  ChangeMakerImage,
  DeleteMakerImage,
  UpdateMakerImage,
  CreateOffer,
  DeleteOffer,
  FetchStocks,
  UpsertStock,
  FetchCapacities,
  FetchUserCapacities,
  UpsertCapacity,
  BatchUpsertCapacity,
  BatchDeleteCapacity,
  FetchDroneSlots,
  UpsertDroneSlots,
  BatchUpsertDroneSlots,
  BatchDeleteDroneSlots,
  FetchUsers,
  EnableUser,
  DisableUser,
  DeleteUser,
  FetchStaffs,
  UpsertStaff,
  FetchAppMessages,
  DeleteAppMessage,
  UpsertAppMessage,
  AggregateDailySales,
  AggregateMonthlySales,
  AggregateItems,
  AggregateOrders,
  AggregateDailyUsers,
  AggregateMonthlyUsers,
  GetGraphDataDailySales,
  GetGraphDataMonthlySales,
  GetGraphDataDailyUsers,
  GetGraphDataMonthlyUsers,
  CreateBox,
  SetEmpty,
  DeleteBox,
  getBoxState,
  PostAdministratorMail,

  GetMFA,
  SetMFA,
  AssociateSoftwareToken,
  VerifySoftwareToken,

  // for Test
  ReadyForPosting,
  PostingCompleted,
  ReceivedPackage,
};
