import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { AppMessage, dataAppMessages } from '../data/AppMessages';
import { DialogOkCancel, DialogOk } from '@common/components/Dialog';
import moment from 'moment';

interface Dictionary {
  [messageId: string]: string;
}

const MessageIds: Dictionary = {
  USERAPP_TOP: 'user_appトップメッセージ',
};

const getMessage = (messageId: string): string => {
  return MessageIds[messageId] || messageId;
};

const formatMessageLines = (message: string) => {
  if (!message) {
    return <span></span>;
  }
  return message.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
};

export const ManagementAppMessages = () => {
  const appmessages = dataAppMessages.useAppMessages();
  const [isOpenSettingAppMessageDialog, setIsOpenSettingAppMessageDialog] = useState(false);

  return (
    <div className="pt-8 flex flex-col items-center gap-2">
      {
        <SettingAppMessageDialog
          isOpen={isOpenSettingAppMessageDialog}
          closeCallback={() => setIsOpenSettingAppMessageDialog(false)}
        />
      }
      <div className="mb-2 flex flex-col items-center">
        <p>ユーザアプリケーションのメッセージ新規作成・編集が行えます。</p>
        <div className="mt-2">
          <Button onClick={() => setIsOpenSettingAppMessageDialog(true)} variant="contained">
            新規作成
          </Button>
        </div>
      </div>
      <div className="grid responsive-grid gap-4">
        {appmessages.map((appmessage) =>
          appmessage.TTL && appmessage.removedBy ? (
            <DeletedAppMessage
              key={appmessage.messageId}
              appmessage={appmessage}
              ttl={appmessage.TTL}
            />
          ) : (
            <SettingAppMeaage key={appmessage.messageId} appmessage={appmessage} />
          )
        )}
      </div>
    </div>
  );
};

const SettingAppMeaage = ({ appmessage }: { appmessage: AppMessage }) => {
  const deleteAppMessage = dataAppMessages.useAppMessageDelete();
  const [isOpenSettingAppMessageDialog, setIsOpenSettingAppMessageDialog] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);

  return (
    <div key={appmessage.messageId} className="p-2 border">
      {
        <SettingAppMessageDialog
          isOpen={isOpenSettingAppMessageDialog}
          appMessage={appmessage}
          closeCallback={() => setIsOpenSettingAppMessageDialog(false)}
        />
      }
      {
        <DialogOkCancel
          open={isOpenConfirmDialog}
          title={''}
          message={getMessage(appmessage.messageId) + 'を削除しますか？'}
          callbackOk={async () => {
            await deleteAppMessage(appmessage.messageId);
            setIsOpenConfirmDialog(false);
          }}
          callbackCancel={() => setIsOpenConfirmDialog(false)}
        />
      }
      <p className="font-bold text-lg">{getMessage(appmessage.messageId)}</p>
      <p className="text-sm my-2">
        <span>ID：{appmessage.messageId}</span>
      </p>
      <p className="font-bold text-lg">
        <span>{formatMessageLines(appmessage.message)}</span>
      </p>
      <div className="flex gap-2 justify-end">
        <Button variant="contained" onClick={() => setIsOpenSettingAppMessageDialog(true)}>
          編集
        </Button>
        {
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsOpenConfirmDialog(true)}
          >
            削除
          </Button>
        }
      </div>
    </div>
  );
};

const DeletedAppMessage = ({ appmessage, ttl }: { appmessage: AppMessage; ttl: number }) => {
  return (
    <div key={appmessage.messageId} className="p-2 border">
      <p className="font-bold text-lg">{appmessage.messageId}</p>
      <p className="text-md font-bold my-2">削除実行済み</p>
      <p className="text-sm my-2">
        削除予定時間：
        <br />
        {moment.unix(ttl).utcOffset(+9).format('YYYY-MM-DD H:mm:ss')}
        {' 〜 '}
        {moment.unix(ttl).utcOffset(+10).format('YYYY-MM-DD H:mm:ss')}
      </p>
      <p className="text-sm my-2">
        <span>ID：{appmessage.messageId}</span>
      </p>
      <div className="flex gap-2 justify-end">
        <Button variant="contained" disabled>
          編集
        </Button>
        {
          <Button variant="contained" color="secondary" disabled>
            削除
          </Button>
        }
      </div>
    </div>
  );
};

const checkAppMessageValidity = (appmessageData: AppMessage): string => {
  const errorMessage = '';
  return errorMessage;
};

const SettingAppMessageDialog = ({
  isOpen,
  appMessage,
  closeCallback,
}: {
  isOpen: boolean;
  appMessage?: AppMessage;
  closeCallback: () => void;
}) => {
  const [openSettingErrorDialogMessage, setSettingErrorDialogMessage] = useState('');
  const [openSettingErrorDialog, setSettingErrorDialog] = useState(false);
  const [isExecution, setIsExecution] = useState(false);
  const defaultAppMessage = {
    messageId: 'USERAPP_TOP',
    message: '',
  };
  const [appmessageData, setappmessageData] = useState<AppMessage>(
    appMessage
      ? {
          messageId: appMessage.messageId,
          message: appMessage.message,
          TTL: appMessage.TTL,
          removedBy: appMessage.removedBy,
        }
      : defaultAppMessage
  );
  const appMessageUpsert = dataAppMessages.useAppMessageUpsert();
  const buttonClickCallback = async () => {
    const errorMessage = checkAppMessageValidity(appmessageData);
    if (errorMessage.length !== 0) {
      setSettingErrorDialogMessage(errorMessage);
      setSettingErrorDialog(true);
      return;
    }
    setIsExecution(true);
    await appMessageUpsert(appmessageData);
    closeCallback();
    setIsExecution(false);
  };
  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>ユーザアプリケーションメッセージ設定</DialogTitle>
      <DialogContent>
        <div className="mb-4 ml-4">
          メッセージの設定を編集できます。
          <br />
          情報を編集し、{appmessageData.messageId ? '更新' : '新規作成'}
          ボタンをクリックしてください。
        </div>
        <div className="overflow-y-auto pt-4 flex flex-col gap-4">
          <select
            style={{
              width: '100%',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              fontSize: '16px',
              lineHeight: '1.5',
              backgroundColor: 'white',
              cursor: 'pointer',
            }}
            className="w-full"
            value={appmessageData.messageId}
            onChange={(e) => setappmessageData({ ...appmessageData, messageId: e.target.value })}
          >
            {Object.entries(MessageIds).map(([messageId, messageDescription]) => (
              <option key={messageId} value={messageId}>
                {messageDescription}
              </option>
            ))}
          </select>
          <label htmlFor="messageTextarea">メッセージを入力してください</label>
          <div>
            <textarea
              id="messageTextarea"
              className="w-full"
              style={{
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                height: '200px',
                width: '100%',
              }}
              value={appmessageData.message}
              onChange={(e) => {
                setappmessageData({ ...appmessageData, message: e.target.value });
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="mt-auto"
          disabled={isExecution}
          onClick={buttonClickCallback}
        >
          {appmessageData.messageId ? '更新' : '新規作成'}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 1 }}
          disabled={isExecution}
          onClick={() => {
            closeCallback();
            // MEMO: キャンセル時に記述をクリアする
            if (appMessage) {
              setappmessageData(appMessage);
              return;
            }
            setappmessageData(defaultAppMessage);
          }}
        >
          キャンセル
        </Button>
      </DialogActions>
      <DialogOk
        open={openSettingErrorDialog}
        title={'エラー 正しく設定されていない項目があります。'}
        message={openSettingErrorDialogMessage}
        callbackOk={async () => {
          setSettingErrorDialog(false);
        }}
      />
    </Dialog>
  );
};
