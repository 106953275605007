import { Button, TextField } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { dataUser } from '../data/User';
import { DialogOkCancel } from '@common/components/Dialog';
import { ApiUser } from '../data/Api';

export const ManagementUsers = () => {
  const [keyword, setKeyword] = useState('');

  return (
    <div className="pt-8 flex flex-col items-center gap-2">
      <div className="mb-2">
        ユーザの停止、削除が行えます。
        <br />
        対象ユーザを検索し、行いたい操作を選択してください。
      </div>
      <div className="w-[800px] mb-4 p-2 border">
        <div className="pl-4 text-md">絞り込み検索</div>
        <div className="px-2 mt-2">
          <TextField
            label="キーワード検索"
            className="w-full"
            onChange={_.debounce((e) => setKeyword(e.target.value), 200)}
          />
        </div>
      </div>
      <Users keyword={keyword} />
    </div>
  );
};

const Users = ({ keyword }: { keyword: string }) => {
  // cognitoから先にデータを取得しておく
  dataUser.useUsers();
  const users = dataUser.useUsersFiltered(keyword);
  const [openEnableConfirmDialog, setOpenEnableConfirmDialog] = useState(false);
  const [openDisableConfirmDialog, setOpenDisableConfirmDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const enableUser = dataUser.useEnableUser();
  const disableUser = dataUser.useDisableUser();
  const deleteUser = dataUser.useDeleteUser();
  const [selectedUser, setSelectedUser] = useState<{ username: string; mailAddress: string }>({
    username: '',
    mailAddress: '',
  });
  const [isExecution, setIsExecution] = useState(false);

  const getMailAddress = (user: ApiUser) => {
    return (
      user.Attributes.find((attribute): attribute is { Name: string; Value: string } => {
        return attribute.Name === 'email';
      })?.Value ?? ''
    );
  };

  return (
    <div className="grid grid-cols-2 gap-2">
      <DialogOkCancel
        open={openEnableConfirmDialog}
        title={''}
        message={selectedUser.mailAddress + 'を有効化しますか？'}
        callbackOk={async () => {
          setIsExecution(true);
          await enableUser(selectedUser.username);
          setIsExecution(false);
          setOpenEnableConfirmDialog(false);
        }}
        callbackCancel={() => setOpenEnableConfirmDialog(false)}
      />
      <DialogOkCancel
        open={openDisableConfirmDialog}
        title={''}
        message={selectedUser.mailAddress + 'を無効化しますか？'}
        callbackOk={async () => {
          setIsExecution(true);
          await disableUser(selectedUser.username);
          setIsExecution(false);
          setOpenDisableConfirmDialog(false);
        }}
        callbackCancel={() => setOpenDisableConfirmDialog(false)}
      />
      <DialogOkCancel
        open={openDeleteConfirmDialog}
        title={''}
        message={selectedUser.mailAddress + 'を削除しますか？'}
        callbackOk={async () => {
          setIsExecution(true);
          await deleteUser(selectedUser.username);
          setIsExecution(false);
          setOpenDeleteConfirmDialog(false);
        }}
        callbackCancel={() => setOpenDeleteConfirmDialog(false)}
      />
      {users.map((user) => (
        <div key={user.Username} className="p-2 border">
          <div className="flex flex-col gap-2">
            <p>ユーザID: {user.Username}</p>
            <p>メールアドレス: {getMailAddress(user)}</p>
            <p>ステータス: {user.Enabled ? '有効' : '無効'}</p>
            <div className="flex gap-2">
              {user.Enabled ? (
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isExecution}
                  onClick={async () => {
                    setSelectedUser({
                      username: user.Username,
                      mailAddress: getMailAddress(user),
                    });
                    setOpenDisableConfirmDialog(true);
                  }}
                >
                  無効化
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    setSelectedUser({
                      username: user.Username,
                      mailAddress: getMailAddress(user),
                    });
                    setOpenEnableConfirmDialog(true);
                  }}
                >
                  有効化
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSelectedUser({
                    username: user.Username,
                    mailAddress: getMailAddress(user),
                  });
                  setOpenDeleteConfirmDialog(true);
                }}
              >
                削除
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
