import {
  atom,
  selector,
  selectorFamily,
  useRecoilValue,
  useRecoilCallback,
  useRecoilState,
} from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import _ from 'lodash';
import { DeliveryTime } from './OrderDeliveryTime';
import { CONST } from '@common/global';

import { ApiStaff, api } from './Api';
import moment from 'moment';
// import { shopItemsMasterState } from './ShopItemMaster';
import { shopSelected } from './Shop';
import { staffsState } from './Staff';
import { cognitoUserState } from './CognitoUser';

// export type OrderState = "placed" | "accepted" | "onDelivery" | "posted" | "completed" | "cancelled";
type OrderState =
  | 'paying'
  | 'credited'
  | 'assigned'
  | 'accepted'
  | 'onDelivery'
  | 'arrived'
  | 'posted'
  | 'completed'
  | 'payingTimeout'
  | 'failed'
  | 'cancelled'
  | 'waitingCustomerByStockOut'
  | 'deleted';

export type OrderItem = {
  itemId: string;
  count: number;
  price: number;
  name: string;
  maker: string;
  image: string;
};

export type UserInfo = {
  givenName?: string;
  surName?: string;
  phone?: string;
  email?: string;
  isVendingMachine: boolean;
};

export type OrderItemState = {
  orderId: string;
  state: OrderState;

  items: OrderItem[];
  deliveryFee: number;
  discount: number;
  totalPrice: number;
  totalCount: number;

  deliveryTime?: DeliveryTime;
  deliveryTo: string;

  timePaying: Date;
  timeCredited?: Date;
  timeAccepted?: Date;
  timeOnDelivery?: Date;
  timeArrived?: Date;
  timePosted?: Date;
  timeCompleted?: Date;
  timePayingTimeout?: Date;
  timeFailed?: Date;
  timeCancelled?: Date;

  boxId?: string;
  userInfo: UserInfo;
  shopUserInfo: { userId: string; email: string; staffName: string };
  cancelCause?: string;

  receiptNumber?: string;
  boxKey?: string;
  lastModified: number;

  cancellationReason?: string;
  stockOutItems?: OrderItem[];
  phoneNumber?: string;
  shopId: string;
  deliverySpotId: string;
  paymentError?: string;
  assignedBy?: string;
  trackShipments: { timestamp: number; info: string }[];
};

//古い情報を何度も繰り返し再取得しないようにする。
let lastModified = 0;

const getOrders = async (shopId: string, staffs: ApiStaff[]) => {
  const apiOrders = await api.ListOrderShop(shopId, lastModified);
  //取りこぼしを防ぐためのepoch秒margin
  const margin = 30;
  lastModified =
    apiOrders
      .map((a) => a.lastModified)
      .concat([moment().add(-1, 'hours').unix()])
      .reduce((a, b) => (a > b ? a : b)) - margin;
  console.log(`lastModified : ${lastModified} / apiOrders : ${apiOrders.length}`);
  const convDeliveryTime = (begin: number, end: number): DeliveryTime | undefined => {
    if (begin === 0) {
      return undefined;
    }
    const beginM = moment.unix(begin).utcOffset(+9);
    const endM = moment(moment.unix(end).utcOffset(+9));
    return new DeliveryTime(
      begin,
      beginM.year(),
      beginM.month(),
      beginM.date(),
      beginM.hour(),
      beginM.minute(),
      endM.hour(),
      endM.minute()
    );
  };
  const convTimestamp = (tp: number | undefined): Date | undefined =>
    tp ? moment.unix(tp).utcOffset(+9).toDate() : undefined;

  // eslint-disable-next-line complexity
  return apiOrders.map((apiOrder) => {
    const shopUserId = apiOrder.shopUserInfo?.find((s) => s.Name === 'sub')?.Value ?? '';
    const order: OrderItemState = {
      orderId: apiOrder.orderId,
      timePaying: convTimestamp(apiOrder.timePaying) as Date,
      timeCredited: convTimestamp(apiOrder.timeCredited),
      timeAccepted: convTimestamp(apiOrder.timeAccepted),
      timeOnDelivery: convTimestamp(apiOrder.timeOnDelivery),
      timePosted: convTimestamp(apiOrder.timePosted),
      timeArrived: convTimestamp(apiOrder.timeArrived),
      timeCompleted: convTimestamp(apiOrder.timeCompleted),
      timePayingTimeout: convTimestamp(apiOrder.timePayingTimeout),
      timeFailed: convTimestamp(apiOrder.timeFailed),
      timeCancelled: convTimestamp(apiOrder.timeCancelled),
      boxId: apiOrder.boxId,
      userInfo: {
        givenName: apiOrder.userInfo.givenName,
        surName: apiOrder.userInfo.surName,
        phone: apiOrder.userInfo.phone,
        email: apiOrder.userInfo.email,
        isVendingMachine: apiOrder.userInfo.isVendingMachine,
      },
      shopUserInfo: {
        userId: shopUserId,
        email: apiOrder.shopUserInfo?.find((s) => s.Name === 'email')?.Value ?? '',
        staffName: staffs.find((s) => s.staffId === shopUserId)?.staffName ?? '',
      },
      cancelCause: apiOrder.cancelCause,
      state: apiOrder.state as OrderState,
      items: apiOrder.items.map((i) => {
        return {
          itemId: i.itemId,
          count: i.count,
          price: i.price,
          name: i.name,
          maker: i.maker,
          image: i.image,
        };
      }),
      deliveryFee: apiOrder.deliveryFee,
      discount: apiOrder.discount ?? 0,
      totalPrice: apiOrder.price,
      totalCount: apiOrder.items.reduce((sum, i) => sum + i.count, 0),
      deliveryTime: convDeliveryTime(apiOrder.deliveryTimeBegin, apiOrder.deliveryTimeEnd),
      deliveryTo: apiOrder.deliveryTo,
      receiptNumber: apiOrder.receiptNumber ?? '',
      boxKey: apiOrder.boxKey ?? '',
      lastModified: apiOrder.lastModified,
      cancellationReason: apiOrder.cancellationReason ?? '',
      stockOutItems: apiOrder.stockOutItems,
      phoneNumber: apiOrder.phoneNumber ?? '',
      shopId: apiOrder.shopId ?? '',
      deliverySpotId: apiOrder.deliverySpotId ?? '',
      paymentError: apiOrder.paymentError ?? '',
      assignedBy: apiOrder.assignedBy ?? '',
      trackShipments:
        apiOrder.trackShipments?.map((trackShipment) => JSON.parse(trackShipment)) ?? [],
    };
    return order;
  });
};

export const orderItemState = atom<OrderItemState[]>({
  key: RecoilKeys.ORDER_STATE,
  effects: [
    ({ setSelf, onSet, trigger, getPromise }) => {
      // console.log("orderState effect");
      let updateTime = Date.now();
      let previousOrders: OrderItemState[] = [];

      if (trigger === 'get') {
        // switch文のためにeslintを無効化
        // eslint-disable-next-line complexity
        const orderStatePriority = (state: string) => {
          switch (state) {
            case 'paying':
              return 1;
            case 'credited':
              return 2;
            case 'assigned':
              return 3;
            case 'accepted':
              return 4;
            case 'onDelivery':
              return 5;
            case 'arrived':
              return 6;
            case 'posted':
              return 7;
            case 'completed':
              return 8;
            case 'payingTimeout':
              return 9;
            case 'failed':
              return 10;
            case 'cancelled':
              return 11;
            default:
              return 0;
          }
        };

        const initialize = async () => {
          const selectedShop = await getPromise(shopSelected);
          const staffs = await getPromise(staffsState);
          getOrders(selectedShop.shopId, staffs).then((orders) => {
            // 店舗端末の複数化対応
            // 	端末は、order listのget reqにlast modify項目を追加(いつ時点以降に追加更新されたレコードよこしなさいリクエスト)
            // 	Lambdaは、DBの更新日見て(index貼る)、それ以降のレコードと最大の更新日を返す。
            // 	端末は、order listのresが、前回のlast modifyより進んでいたら更新処理をする。
            // 	・新しく増えた項目(そのまま受け入れる)
            // 	・更新された項目（自身の持つstateより先に進んでいるものだけを受け入れる） ← last modify云々抜きにもこれだけあれば、たぶん競合が起きても安全

            //古い情報を保管して、新しい情報だけ取り込むようにする
            const newOrders = orders.filter(
              (o) => !previousOrders.some((p) => p.orderId === o.orderId)
            );
            // orderを更新する条件はstateの変更時と配達時間の変更時
            const updatedOrders = previousOrders
              .map((p) => {
                const order = orders.find((o) => o.orderId === p.orderId);
                return { prev: p, next: order };
              })
              .filter(
                (pn) =>
                  pn.next &&
                  (orderStatePriority(pn.prev.state) < orderStatePriority(pn.next.state) ||
                    pn.prev.deliveryTime?.beginUnixTime !== pn.next.deliveryTime?.beginUnixTime)
              )
              .map((pn) => pn.next as OrderItemState);
            if (newOrders.length !== 0 || updatedOrders.length !== 0) {
              console.log(`incomming update : ${updatedOrders.length}`);
              updatedOrders.forEach((up) => {
                const index = previousOrders.findIndex((p) => p.orderId === up.orderId);
                console.log(
                  `updated : ${up.orderId} - ${previousOrders[index].state} -> ${up.state}`
                );
                previousOrders[index] = up;
              });

              console.log(`incomming new : ${newOrders.length}`);
              // console.log(newOrders);
              previousOrders = previousOrders.concat(newOrders);
            }
            setSelf(_.cloneDeep(previousOrders));
          });
        };
        initialize();
        setInterval(initialize, CONST.TIME_LIMITATION_CHECK_INTERVAL);
      }

      onSet(async (newValue, oldValue) => {
        if (oldValue) {
          newValue
            .filter((n) => {
              const old = (oldValue as OrderItemState[]).find((o) => o.orderId === n.orderId);
              if (old) {
                return (
                  old.state !== n.state ||
                  old.shopUserInfo.staffName !== n.shopUserInfo.staffName ||
                  old.items.length !== n.items.length ||
                  n.items.some((i) => {
                    const oldItem = old.items.find((oi) => oi.itemId === i.itemId);
                    if (oldItem) {
                      // 個数に変化があった場合
                      return oldItem.count !== i.count;
                    } else {
                      // 商品情報に変更があった場合
                      return true;
                    }
                  })
                );
              } else {
                return false;
              }
            })
            .forEach(async (n) => {
              console.log(`detect update : ${n.orderId} - ${n.state}`);
              updateTime = Date.now();
              previousOrders = previousOrders.map((o) => (o.orderId === n.orderId ? n : o));
              await api.PutOrderState(n.orderId, n.state, n.cancellationReason, n.stockOutItems);
              console.log(
                `PutOrderState : ${n.orderId} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`
              );
              updateTime = Date.now();
            });
        }
      });
    },
  ],
});

const orderItemSelector = selectorFamily({
  key: RecoilKeys.ORDER_ITEM,
  get:
    (orderId) =>
    ({ get }) => {
      const items = get(orderItemsFilteredByLastModified);
      return items.find((item) => item.orderId === orderId);
    },
});

type SortKey = 'DeliveryTime';
type SortOrder = 'asc' | 'desc';

const sortOrders = (
  orders: OrderItemState[],
  sortOrder: SortOrder = 'asc',
  sortKey: SortKey = 'DeliveryTime'
) => {
  if (sortKey === 'DeliveryTime') {
    // 配達時間、orderIdでソート
    if (sortOrder === 'asc') {
      return orders.sort((a, b) =>
        (a.deliveryTime?.beginUnixTime ?? 0) !== (b.deliveryTime?.beginUnixTime ?? 0)
          ? (a.deliveryTime?.beginUnixTime ?? 0) - (b.deliveryTime?.beginUnixTime ?? 0)
          : a.orderId > b.orderId
          ? 1
          : -1
      );
    } else {
      return orders.sort((a, b) =>
        (a.deliveryTime?.beginUnixTime ?? 0) !== (b.deliveryTime?.beginUnixTime ?? 0)
          ? (b.deliveryTime?.beginUnixTime ?? 0) - (a.deliveryTime?.beginUnixTime ?? 0)
          : a.orderId < b.orderId
          ? 1
          : -1
      );
    }
  }
  return orders;
};

// 期間内の注文を取得するセレクタ
const OrderItemsInPeriod = selectorFamily<OrderItemState[], { begin?: Date; end?: Date }>({
  key: RecoilKeys.ORDER_ITEMS_IN_PERIOD,
  get:
    ({ begin, end }) =>
    ({ get }) => {
      const orderItems = get(orderItemState);
      const filteredOrderItems = orderItems.filter((orderItem) => {
        const deliveryTime = orderItem.deliveryTime?.date.getTime() ?? new Date().getTime();
        const beginTime = begin?.getTime() ?? 0;
        const endTime = end?.getTime() ?? 0;

        return deliveryTime >= beginTime && deliveryTime <= endTime;
      });
      return sortOrders(filteredOrderItems, 'desc', 'DeliveryTime');
    },
});

// 更新日によるフィルタリングを行うか
const isFilteredOrderByLastModified = atom<boolean>({
  key: RecoilKeys.ORDER_ITEMS_ARE_FILTERED_BY_LAST_MODIFIED,
  default: true,
});

// フィルタリングを行うか判別し、注文を返却するセレクタ
const orderItemsFilteredByLastModified = selector<OrderItemState[]>({
  key: RecoilKeys.ORDER_ITEMS_FILTERED_BY_LAST_MODIFIED,
  get: ({ get }) => {
    const orderItems = get(orderItemState);
    if (get(isFilteredOrderByLastModified)) {
      return orderItems.filter(
        (orderItem) => orderItem.lastModified > moment().add(-5, 'days').unix()
      );
    }
    return orderItems;
  },
});

const orderItemsPlaced = selector({
  key: RecoilKeys.ORDER_ITEMS_PLACED,
  get: ({ get }) =>
    sortOrders(get(orderItemsFilteredByLastModified).filter((order) => order.state === 'credited')),
});

const orderItemsAssigned = selector({
  key: RecoilKeys.ORDER_ITEMS_ASSIGNED,
  get: ({ get }) =>
    sortOrders(get(orderItemsFilteredByLastModified).filter((order) => order.state === 'assigned')),
});

const orderItemsAccepted = selector({
  key: RecoilKeys.ORDER_ITEMS_ACCEPTED,
  get: ({ get }) =>
    sortOrders(get(orderItemsFilteredByLastModified).filter((order) => order.state === 'accepted')),
});

const orderItemsOnDelivery = selector({
  key: RecoilKeys.ORDER_ITEMS_ON_DELIVERY,
  get: ({ get }) =>
    sortOrders(
      get(orderItemsFilteredByLastModified).filter((order) => order.state === 'onDelivery')
    ),
});

const orderItemsFailed = selector({
  key: RecoilKeys.ORDER_ITEMS_FAILED,
  get: ({ get }) =>
    sortOrders(get(orderItemsFilteredByLastModified).filter((order) => order.state === 'failed')),
});

const orderItemsArrived = selector({
  key: RecoilKeys.ORDER_ITEMS_ARRIVED,
  get: ({ get }) =>
    sortOrders(
      get(orderItemsFilteredByLastModified).filter((order) => order.state === 'arrived'),
      'desc'
    ),
});

const orderItemsPosted = selector({
  key: RecoilKeys.ORDER_ITEMS_POSTED,
  get: ({ get }) =>
    sortOrders(
      get(orderItemsFilteredByLastModified).filter((order) => order.state === 'posted'),
      'desc'
    ),
});

// キャンセルされた注文
const orderItemsCanceled = selector({
  key: RecoilKeys.ORDER_ITEMS_CANCELED,
  get: ({ get }) => {
    return sortOrders(
      get(orderItemsFilteredByLastModified).filter((order) => order.state === 'cancelled'),
      'desc'
    );
  },
});

const myOrderItems = selector({
  key: RecoilKeys.MY_ORDER_ITEMS,
  get: ({ get }) => {
    const cognitoUser = get(cognitoUserState);

    return sortOrders(
      get(orderItemsFilteredByLastModified).filter(
        (order) => order.shopUserInfo.email === cognitoUser.email
      ),
      'desc'
    );
  },
});

const orderItemsWaitingCustomerByStockOut = selector({
  key: RecoilKeys.ORDER_ITEMS_WAITING_CUSTOMER_BY_STOCK_OUT,
  get: ({ get }) =>
    sortOrders(
      get(orderItemsFilteredByLastModified).filter(
        (order) => order.state === 'waitingCustomerByStockOut'
      ),
      'desc'
    ),
});

// state 変更
const useSetOrderState = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        orderId: string,
        newState: OrderState,
        cancellationReason?: string,
        stockOutItems?: OrderItem[]
      ) => {
        const cognitoUser = await snapshot.getPromise(cognitoUserState);
        const staffs = await snapshot.getPromise(staffsState);

        set(orderItemState, (prev) => {
          return prev.map((order) =>
            order.orderId === orderId
              ? {
                  ...order,
                  state: newState,
                  cancellationReason: cancellationReason,
                  stockOutItems: stockOutItems,
                  shopUserInfo:
                    newState === 'assigned'
                      ? {
                          userId: cognitoUser.userName,
                          email: cognitoUser.email,
                          staffName:
                            staffs.find((s) => s.staffId === cognitoUser.userName)?.staffName ?? '',
                        }
                      : order.shopUserInfo,
                }
              : order
          );
        });
      },
    []
  );

const ReadyForPosting = async (order: OrderItemState) => {
  await api.ReadyForPosting(order.deliverySpotId, order.orderId);
};

const PostingCompleted = async (order: OrderItemState) => {
  await api.PostingCompleted(order.deliverySpotId);
};

const ReceivedPackage = async (order: OrderItemState, boxId: string, boxKey: string) => {
  await api.ReceivedPackage(order.orderId, boxId, boxKey);
};

const useUpdateOrderItems = () =>
  useRecoilCallback(({ set }) => async (orderId: string, orderItems: OrderItem[]) => {
    await api.UpdateOrderItems({
      orderId: orderId,
      price: orderItems.reduce((sum, i) => sum + i.price * i.count, 0),
      items: orderItems,
    });
    set(orderItemState, (prev) => {
      return prev.map((order) =>
        order.orderId === orderId
          ? {
              ...order,
              items: orderItems,
              totalPrice: orderItems.reduce((sum, i) => sum + i.price * i.count, 0),
              totalCount: orderItems.reduce((sum, i) => sum + i.count, 0),
            }
          : order
      );
    });
  });

// export
export const dataOrder = {
  useOrderHistory: () => useRecoilValue(orderItemState),
  useOrderItem: (orderId: string) => useRecoilValue(orderItemSelector(orderId)),
  useOrderItemsInPeriod: (begin?: Date, end?: Date) =>
    useRecoilValue(OrderItemsInPeriod({ begin, end })),
  useOrderItemsPlaced: () => useRecoilValue(orderItemsPlaced),
  useOrderItemsAssigned: () => useRecoilValue(orderItemsAssigned),
  useOrderItemsAccepted: () => useRecoilValue(orderItemsAccepted),
  useOrderItemsOnDelivery: () => useRecoilValue(orderItemsOnDelivery),
  useOrderItemsArrived: () => useRecoilValue(orderItemsArrived),
  useOrderItemsPosted: () => useRecoilValue(orderItemsPosted),
  useOrderItemsFailed: () => useRecoilValue(orderItemsFailed),
  useOrderItemsCanceled: () => useRecoilValue(orderItemsCanceled),
  useMyOrderItems: () => useRecoilValue(myOrderItems),
  useOrderItemsWaitingCustomerByStockOut: () => useRecoilValue(orderItemsWaitingCustomerByStockOut),
  useIsFilteredOrderByLastModified: () => useRecoilState(isFilteredOrderByLastModified),
  useOrderItemsFilteredByLastModified: () => useRecoilValue(orderItemsFilteredByLastModified),
  useSetOrderState,
  ReadyForPosting,
  PostingCompleted,
  ReceivedPackage,
  useUpdateOrderItems,
};
